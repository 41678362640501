/* @flow */

import * as React from 'react';
import { ISO8601_DURATION_ZERO, formatIso8601Duration, getIso8601DateInSeconds, getIso8601DurationInSeconds } from '../../dateTime/Format';
import { LANGUAGE, LANGUAGE_VARIANTS, PROGRAM_INFO } from './Types';
import { METADATA_KIND_PROGRAM, type NETGEM_API_V8_METADATA, type NETGEM_API_V8_METADATA_PROGRAM, type NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { type NETGEM_API_V8_FEED_ITEM, NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP, NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { type NETGEM_API_V8_LANG, SynopsisType } from '../../../libs/netgemLibrary/v8/types/Lang';
import type {
  NETGEM_API_V8_METADATA_LOCATION_VIDEO_STREAM_PARAM,
  NETGEM_API_V8_METADATA_SCHEDULE,
  NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
} from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { PictoHearingImpaired, PictoVisuallyImpaired } from '@ntg/components/dist/pictos/Element';
import type { BO_API_AUDIO_AND_SUBS_TYPE } from '../../../libs/netgemLibrary/videofutur/types/Language';
import { Localizer } from '@ntg/utils/dist/localization';
import clsx from 'clsx';
import { getLocationType } from '../../../libs/netgemLibrary/v8/helpers/Item';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';

const addIfNotNull =
  <T>(elements: Array<T>): ((element: T | null) => void) =>
  (element: T | null) => {
    if (element !== null) {
      elements.push(element);
    }
  };

const buildTitle: (language: string, programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null, seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null, alwaysDisplaySeriesTitle: boolean) => string = (
  language,
  programMetadata,
  seriesMetadata,
  alwaysDisplaySeriesTitle,
) => {
  const titleArray: Array<string> = [];
  const addToTitleArrayIfNotNull = addIfNotNull<string>(titleArray);

  let seriesTitle: string | null = null;
  if (seriesMetadata) {
    const { titles } = seriesMetadata;
    if (titles.length > 0) {
      seriesTitle = getTranslatedText(titles, language);
    }
  }

  if (programMetadata) {
    const { episodeIndex, episodeTitle } = getEpisodeIndexAndTitle(programMetadata, seriesTitle, language);
    addToTitleArrayIfNotNull(episodeIndex);
    addToTitleArrayIfNotNull(episodeTitle);
  }

  if ((alwaysDisplaySeriesTitle || titleArray.length === 0) && seriesTitle) {
    titleArray.unshift(seriesTitle);
  }

  return titleArray.length > 0 ? titleArray.join(' - ') : Localizer.localize('common.metadata.unknown_title');
};

const renderProgramLanguage: (locationMetadata: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION | null) => React.Node = (locationMetadata) => {
  const languageElements = formatAudioAndSubtitles(locationMetadata);

  if (!languageElements) {
    return null;
  }

  return (
    <div className='languages' key='languages'>
      {languageElements}
    </div>
  );
};

const renderProgramParentalGuidance: (data: NETGEM_API_V8_FEED_ITEM, programMetadata: NETGEM_API_V8_METADATA_PROGRAM) => React.Node = (data, programMetadata) => {
  const { parentalGuidance: dataPG } = data;
  const { parentalGuidance: metadataPG } = programMetadata;

  const parentalGuidance = dataPG || metadataPG;

  let pgText = Localizer.localize('common.metadata.no_age_restriction');

  if (parentalGuidance) {
    const { minimumAge } = parentalGuidance;
    const pg = Number(minimumAge);
    if (pg > 0) {
      pgText = `-${pg}`;
    }
  }

  return (
    <div className={clsx('parentalGuidance', !isNaN(pgText) && 'pictoInfo')} key='parentalGuidance'>
      {pgText}
    </div>
  );
};

const renderProgramDuration: (item: NETGEM_API_V8_FEED_ITEM, metadata: NETGEM_API_V8_METADATA_PROGRAM) => React.Node = (item, metadata) => {
  const {
    locType,
    selectedLocation: { duration: locationDuration, quotaUsed, scheduledEventDuration },
  } = item;
  const { duration: programDuration } = metadata;
  const localDuration = scheduledEventDuration ?? locationDuration ?? programDuration;

  if (localDuration && localDuration !== ISO8601_DURATION_ZERO) {
    const formattedDuration = formatIso8601Duration(localDuration);
    if (formattedDuration !== '') {
      const wasNotFullyRecorded =
        locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING && typeof quotaUsed === 'string' && getIso8601DurationInSeconds(quotaUsed) < getIso8601DurationInSeconds(localDuration);

      const recordedDuration = wasNotFullyRecorded ? (
        <>
          <span className='real'>{formatIso8601Duration(quotaUsed)}</span>
          <span className='separator'>/</span>
        </>
      ) : null;

      return (
        <div className='duration' key='duration'>
          {recordedDuration}
          {formattedDuration}
        </div>
      );
    }
  }

  return null;
};

const renderProgramDetails: (item: NETGEM_API_V8_FEED_ITEM, metadata: ?NETGEM_API_V8_METADATA, vodLocations: ?Array<NETGEM_API_V8_METADATA_SCHEDULE>, info: number) => Array<React.Node> | null = (
  item,
  metadata,
  vodLocations,
  info,
) => {
  if (!metadata) {
    return null;
  }

  const { genres, productionCountry, releaseDate } = metadata;
  const genreText = genres && genres.values && genres.values.length > 0 ? genres.values[0] : null;
  const details: Array<React.Node> = [];
  const addToDetailsIfNotNull = addIfNotNull<React.Node>(details);

  // eslint-disable-next-line no-bitwise
  if (info & PROGRAM_INFO.Duration && metadata.type === METADATA_KIND_PROGRAM) {
    addToDetailsIfNotNull(renderProgramDuration(item, (metadata: NETGEM_API_V8_METADATA_PROGRAM)));
  }

  // eslint-disable-next-line no-bitwise
  if (info & PROGRAM_INFO.Genre && genreText && genreText !== 'null') {
    details.push(
      <div className='genre' key='genre'>
        {genreText}
      </div>,
    );
  }

  // eslint-disable-next-line no-bitwise
  if (info & PROGRAM_INFO.ProductionCountry && productionCountry) {
    details.push(
      <div className='country' key='country'>
        {productionCountry}
      </div>,
    );
  }

  // eslint-disable-next-line no-bitwise
  if (info & PROGRAM_INFO.ReleaseDate && releaseDate) {
    details.push(
      <div className='date release' key='releaseDate'>
        {releaseDate}
      </div>,
    );
  }

  // eslint-disable-next-line no-bitwise
  if (info & PROGRAM_INFO.Language && vodLocations && vodLocations.length > 0) {
    addToDetailsIfNotNull(renderProgramLanguage(vodLocations[0].location));
  }

  // eslint-disable-next-line no-bitwise
  if (info & PROGRAM_INFO.ParentalGuidance && metadata.type === METADATA_KIND_PROGRAM) {
    addToDetailsIfNotNull(renderProgramParentalGuidance(item, metadata));
  }

  if (details.length === 0) {
    // Nothing to render
    return null;
  }

  // React transforms the array into elements
  return details;
};

/*
 * Format Season Episode number  in SXX EXX
 * ex: => "S0 E1"
 * ex: => "S1 E10"
 * ex: => "S1"
 * ex: => "E2"
 *
 * @public
 * @param  {NETGEM_API_V8_METADATA_PROGRAM} metadata: item metadata
 * @return {string}                                 : formatted result
 */
const formatSeasonEpisodeNbr: (programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null) => string | null = (programMetadata) => {
  if (!programMetadata) {
    return null;
  }

  let textResult: string | null = null;

  const { episodeOf: { episodeNumber = 0, seasonNumber = 0 } = {} } = programMetadata;
  if (seasonNumber > 0) {
    textResult = `S${seasonNumber}`;
  }
  if (episodeNumber > 0) {
    textResult = `${textResult ? `${textResult} E` : ''}${episodeNumber}`;
  }

  return textResult;
};

const isFrenchLanguage: (language: string) => boolean = (language) => LANGUAGE_VARIANTS.FR.includes(language.toUpperCase());
const isEnglishLanguage: (language: string) => boolean = (language) => LANGUAGE_VARIANTS.EN.includes(language.toUpperCase());
const isGermanLanguage: (language: string) => boolean = (language) => LANGUAGE_VARIANTS.DE.includes(language.toUpperCase());
const isUndeterminedLanguage: (language: string) => boolean = (language) => LANGUAGE_VARIANTS.Undetermined.includes(language.toUpperCase());

const hasLanguage: (languages: BO_API_AUDIO_AND_SUBS_TYPE, language: string) => boolean = (languages, language) => languages.some((l) => l.language.toUpperCase() === language) ?? false;

const hasNotVFLanguage: (languages: BO_API_AUDIO_AND_SUBS_TYPE) => boolean = (languages) => languages.some((l) => l.language.toUpperCase() !== LANGUAGE.FR) ?? false;

const isVisuallyImpairedAudio = (track: {| audioDescription: boolean, language: string |}): boolean => {
  const { audioDescription, language } = track;

  if (audioDescription) {
    return true;
  }

  const ucLanguage = language.toUpperCase();
  return ucLanguage === LANGUAGE.VisuallyImpaired || ucLanguage === LANGUAGE.VisuallyImpairedAlt;
};

const formatAudioAndSubtitles: (locationMetadata: ?NETGEM_API_V8_METADATA_SCHEDULE_LOCATION) => React.Node = (locationMetadata) => {
  if (!locationMetadata) {
    return null;
  }

  const { playbackUrls } = locationMetadata;

  if (!playbackUrls || playbackUrls.length === 0) {
    return null;
  }

  const [
    {
      start: { params },
    },
  ] = playbackUrls;
  const videostreams = params?.find((i) => i.name === 'videostreams');
  const propertiesArg = videostreams?.value.args.find((i) => ((i: any): NETGEM_API_V8_METADATA_LOCATION_VIDEO_STREAM_PARAM).properties);

  if (!propertiesArg) {
    return null;
  }

  const { properties } = ((propertiesArg: any): NETGEM_API_V8_METADATA_LOCATION_VIDEO_STREAM_PARAM);
  if (!properties) {
    return null;
  }

  const { audio, subtitles } = properties;

  if (!audio && !subtitles) {
    return null;
  }

  /*
   * VOD case:
   *  - FR only                             : VF
   *  - FR + another language               : VF VOST
   *  - FR + another language + FR subtitles: VF VOST
   *  - Another language + FR subtitles     : VOST
   *  - Another language                    : VOST
   *
   * TV case:
   *  - FR only                             : VF
   *  - FR + QAA                            : VF VOST
   *  - FR + QAA + FR subtitles             : VF VOST
   *  - QAA + FR subtitles                  : VOST
   *  - QAA                                 : VOST
   */

  const audioAndSubs: Array<string> = [];
  let visuallyImpairedElt = null;
  let hearingImpairedElt = null;

  if (audio) {
    if (hasLanguage(audio, LANGUAGE.FR)) {
      audioAndSubs.push(Localizer.localize('common.metadata.language.french'));
    }

    if (hasNotVFLanguage(audio) || hasLanguage(audio, LANGUAGE.OriginalVersion)) {
      audioAndSubs.push(Localizer.localize('common.metadata.language.original_version_with_subtitles'));
    }

    if (audio.some((track) => isVisuallyImpairedAudio(track))) {
      visuallyImpairedElt = <PictoVisuallyImpaired className='pictoInfo impaired' hasHoverEffect={false} key='visuallyImpaired' />;
    }
  }

  if (subtitles && subtitles.some((sub) => sub.captions === true)) {
    hearingImpairedElt = <PictoHearingImpaired className='pictoInfo impaired' hasHoverEffect={false} key='hearingImpaired' />;
  }

  if (audioAndSubs.length > 0) {
    return (
      <>
        {audioAndSubs.map((lang) => (
          <div className='pictoInfo' key={lang}>
            {lang}
          </div>
        ))}
        {visuallyImpairedElt}
        {hearingImpairedElt}
      </>
    );
  }

  return null;
};

const getEpisodeIndexAndTitle: (programMetadata: ?NETGEM_API_V8_METADATA_PROGRAM, seriesTitle: ?string, language: string) => {| episodeIndex: string | null, episodeTitle: string | null |} = (
  programMetadata,
  seriesTitle,
  language,
) => {
  if (!programMetadata) {
    return {
      episodeIndex: null,
      episodeTitle: null,
    };
  }

  const { titles } = programMetadata;
  const title = getTranslatedText(titles, language);
  const episodeIndex = formatSeasonEpisodeNbr(programMetadata);
  const episodeTitle = title && (!seriesTitle || title.toLowerCase() !== seriesTitle.toLowerCase()) ? title : null;

  return {
    episodeIndex,
    episodeTitle,
  };
};

const getLocalizedString: (langArray: Array<NETGEM_API_V8_LANG>, language: string, fallbackValue: ?string, requestedType?: SynopsisType) => string | null = (
  langArray,
  language,
  fallbackValue,
  requestedType,
) => {
  const baseLang = (language.includes('-') ? language.substring(0, language.indexOf('-')) : language).toUpperCase();
  let defaultText: string | null = null;

  for (const { lang, type, value } of langArray) {
    if (lang.toUpperCase() === baseLang) {
      // For synopsis and tagline, look for correct type, but for title, first value is OK
      if (typeof requestedType === 'undefined' || type === requestedType) {
        return value;
      }

      if (requestedType !== SynopsisType.Short) {
        // For full synopsis (resp. title), another kind of synopsis (resp. title) could be used in case nothing perfect is found
        defaultText = value;
      }
    }
  }

  if (!defaultText) {
    // Find available correct synopsis kind in any language
    for (const { type, value } of langArray) {
      if (!requestedType || type === requestedType) {
        defaultText = value;
        break;
      }
    }
  }

  // At this point, defaultText is either null (nothing was found for the given language) or has a value for the wrong type but in the right language
  return defaultText ?? fallbackValue ?? null;
};

const getTitleInternal: (metadata: ?NETGEM_API_V8_METADATA, language: string, fallbackValue: ?string) => string | null = (metadata, language, fallbackValue) => {
  if (!metadata) {
    return null;
  }

  const { titles } = metadata;

  if (!titles) {
    return null;
  }

  return getLocalizedString(titles, language, fallbackValue);
};

const getSynopsisInternal: (metadata: ?NETGEM_API_V8_METADATA, language: string, fallbackValue: ?string, requestedType: SynopsisType) => string | null = (
  metadata,
  language,
  fallbackValue,
  requestedType,
) => {
  if (!metadata) {
    return null;
  }

  const { synopses } = metadata;

  if (!synopses) {
    return null;
  }

  return getLocalizedString(synopses, language, fallbackValue, requestedType);
};

const getTagline: (metadata: ?NETGEM_API_V8_METADATA, language: string, fallbackValue: ?string) => string | null = (metadata, language, fallbackValue = null) =>
  getSynopsisInternal(metadata, language, fallbackValue, SynopsisType.Short);

const getSynopsis: (metadata: ?NETGEM_API_V8_METADATA, language: string, fallbackValue: ?string) => string | null = (metadata, language, fallbackValue = null) =>
  getSynopsisInternal(metadata, language, fallbackValue, SynopsisType.Full);

const getTitle: (metadata: ?NETGEM_API_V8_METADATA, language: string, fallbackValue: ?string) => string | null = (metadata, language, fallbackValue = null) =>
  getTitleInternal(metadata, language, fallbackValue);

const getStartAndEndTimes: (item: NETGEM_API_V8_FEED_ITEM) => {| endTime: number, startTime: number |} = (item) => {
  const {
    selectedLocation: { id, scheduledEventDuration, scheduledEventStartDate },
  } = item;
  let { endTime: localEndTime, startTime: localStartTime } = item;

  const locType = getLocationType(id);

  if ((locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) && scheduledEventDuration && scheduledEventStartDate) {
    localStartTime = getIso8601DateInSeconds(scheduledEventStartDate);
    localEndTime = localStartTime + getIso8601DurationInSeconds(scheduledEventDuration);
  }

  return {
    endTime: localEndTime,
    startTime: localStartTime,
  };
};

export {
  buildTitle,
  formatAudioAndSubtitles,
  formatSeasonEpisodeNbr,
  getEpisodeIndexAndTitle,
  getLocalizedString,
  getStartAndEndTimes,
  getSynopsis,
  getTagline,
  getTitle,
  isEnglishLanguage,
  isFrenchLanguage,
  isGermanLanguage,
  isUndeterminedLanguage,
  renderProgramDetails,
  renderProgramLanguage,
  renderProgramParentalGuidance,
};
