/* @flow */

import './RegistrationView.css';
import * as React from 'react';
import type { BasicCallbackFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { PendingOperationKind, clearPendingOperation, updatePendingOperationType } from '../../helpers/rights/pendingOperations';
import { RegistrationType, type UserDeviceInfoType } from '../../redux/appRegistration/types/types';
import { ApplicationState } from './ApplicationContainer';
import ButtonFX from '../buttons/ButtonFX';
import type { CombinedReducers } from '../../redux/reducers';
import type { Dispatch } from '../../redux/types/types';
import LocalStorageManager from '../../helpers/localStorage/localStorageManager';
import { StorageKeys } from '../../helpers/localStorage/keys';
import { buildDeviceUrl } from '../../helpers/dms/helper';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { generateToken } from '../../helpers/crypto/crypto';
import { isRunningOnLocalhost } from '../../helpers/jsHelpers/environment';
import { registerApplication } from '../../redux/appRegistration/actions';

// Time allowed for login page to load (in milliseconds)
const LOAD_TIMEOUT = 60_000;

type ExtraCrmData = {|
  showPods?: boolean,
  showSignin?: boolean,
  showSignup?: boolean,
  showSubscribe?: boolean,
|};

type ReduxRegistrationReducerStateType = {|
  +applicationId: string,
  +authDeviceUrl: string,
  +authenticationToken: string | null,
  +deviceId: string,
  +deviceKey: string,
  +isRegisteredAsGuest: boolean,
  +loginUrl: string,
  +logoutUrl: string,
  +podsWebUrl: string,
  +signupUrl: string,
  +subscribeWebUrl: string,
  +subscriberId: string,
  +upgradeDeviceUrl: string,
  +utmParameters: KeyValuePair<string>,
|};

type ReduxRegistrationDispatchToPropsType = {|
  +localRegisterApplication: (userDeviceInfo: UserDeviceInfoType, asGuest: boolean) => Promise<any>,
|};

type DefaultProps = {|
  +pendingOperationType?: PendingOperationKind,
|};

type RegistrationPropType = {|
  ...DefaultProps,
  +applicationState: ApplicationState,
  +closeDebugPanelCallback: BasicCallbackFunction,
  +crmLoadTimeoutCallback: BasicCallbackFunction,
  +hideHeaderCallback: BasicCallbackFunction,
  +highlightedCommercialOffers: Array<string>,
  +isDebugPanelVisible: boolean,
  +registrationFailureCallback: BasicCallbackFunction,
|};

type CompleteRegistrationPropType = {|
  ...RegistrationPropType,
  ...ReduxRegistrationReducerStateType,
  ...ReduxRegistrationDispatchToPropsType,
|};

type RegistrationStateType = {|
  crmUrl: string | null,
  overriddenAppConf: string,
  selectedAppConf: string,
|};

const InitialState = Object.freeze({
  crmUrl: null,
  overriddenAppConf: '',
  selectedAppConf: '',
});

class RegistrationView extends React.PureComponent<CompleteRegistrationPropType, RegistrationStateType> {
  loadTimer: TimeoutID | null;

  static defaultProps: DefaultProps = {
    pendingOperationType: undefined,
  };

  constructor(props: CompleteRegistrationPropType) {
    super(props);

    this.loadTimer = null;
    this.state = { ...InitialState };
  }

  componentDidMount() {
    const { crmLoadTimeoutCallback } = this.props;

    window.addEventListener('message', this.handleMessagePosted, {
      capture: true,
      passive: true,
    });

    const overriddenAppConf = LocalStorageManager.loadString(StorageKeys.OverriddenAppConf, '');
    if (overriddenAppConf !== '') {
      this.setState({
        overriddenAppConf,
        selectedAppConf: overriddenAppConf,
      });
    }

    this.loadTimer = setTimeout(crmLoadTimeoutCallback, LOAD_TIMEOUT);
    this.buildCrmUrl();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessagePosted, {
      capture: true,
      passive: true,
    });

    this.resetLoadTimer();
  }

  resetLoadTimer = () => {
    const { loadTimer } = this;

    if (loadTimer) {
      clearTimeout(loadTimer);
      this.loadTimer = null;
    }
  };

  clearCrmData = () => {
    LocalStorageManager.delete(StorageKeys.CrmData);
  };

  // Store data in local storage for CRM pages
  storeCrmData = (extraData: ExtraCrmData) => {
    const {
      applicationId,
      authenticationToken: token,
      authDeviceUrl,
      deviceKey,
      highlightedCommercialOffers,
      isRegisteredAsGuest,
      logoutUrl,
      pendingOperationType,
      subscriberId,
      upgradeDeviceUrl,
      utmParameters,
    } = this.props;

    // $FlowFixMe: Flow does not want to spread utmParameters because it could overwrite properties but we know that's not possible
    LocalStorageManager.save(StorageKeys.CrmData, {
      ...extraData,
      ...utmParameters,
      anonymous: isRegisteredAsGuest,
      applicationId,
      authDeviceUrl,
      deviceKey,
      highlightedCommercialOffers,
      logoutUrl,
      pendingOperationType,
      subscriberId,
      token,
      upgradeDeviceUrl,
    });
  };

  buildCrmUrl = () => {
    const { applicationState, authenticationToken, deviceId, isRegisteredAsGuest, loginUrl, podsWebUrl, signupUrl, subscribeWebUrl } = this.props;

    // Display sign-in page (most used case)
    let crmUrl = loginUrl;

    this.clearCrmData();

    if (applicationState === ApplicationState.SignUpDisplayed && signupUrl) {
      // Display sign-up page
      this.storeCrmData({ showSignup: true });
      crmUrl = signupUrl;
    } else if (applicationState === ApplicationState.SubscribeDisplayed && subscribeWebUrl) {
      // Display subscribe page
      this.storeCrmData({ showSubscribe: true });

      // If not logged in, user will be prompted to sign in
      if (authenticationToken && !isRegisteredAsGuest) {
        crmUrl = subscribeWebUrl;
      } else {
        crmUrl = signupUrl;
      }
    } else if (applicationState === ApplicationState.PodsDisplayed && podsWebUrl) {
      // Display pods page
      this.storeCrmData({ showPods: true });
      crmUrl = podsWebUrl;
    } else {
      // Display sign-in page
      this.storeCrmData({ showSignin: true });
    }

    this.setState({ crmUrl: buildDeviceUrl(deviceId, crmUrl) });
  };

  getBooleanValue = (value: string): ?boolean => {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    }

    return null;
  };

  parseUrl = (url: string, defaultValues: UserDeviceInfoType): UserDeviceInfoType => {
    const parameters = new URLSearchParams(url.slice(url.indexOf('?') + 1));

    const userDeviceInfoKeys = ((Object.keys(defaultValues): any): Array<string>);
    const lcUserDeviceInfoKeys = userDeviceInfoKeys.map((value) => value.toLowerCase());
    const userDeviceInfo: UserDeviceInfoType = { ...defaultValues };

    for (const [key, value] of parameters.entries()) {
      const lcKey = key.toLowerCase();

      // For registration pages not providing the right case for keys
      const i = lcUserDeviceInfoKeys.indexOf(lcKey);
      const boolValue = this.getBooleanValue(value);

      if (i >= 0) {
        // $FlowFixMe: prop-missing error because userDeviceInfoKeys[i] is not guaranteed to be a correct key
        userDeviceInfo[userDeviceInfoKeys[i]] = boolValue !== null ? boolValue : value;
      } else if (lcKey === 'subscriptionskipped' && boolValue === true) {
        // User skipped subscription: no need to retry to play
        updatePendingOperationType(PendingOperationKind.OpenCard);
      }
    }

    // Remove data from local storage, so it's not reused later
    LocalStorageManager.delete(StorageKeys.CrmData);

    return userDeviceInfo;
  };

  handleMessagePosted = (e: SyntheticInputEvent<HTMLElement>) => {
    const { deviceId, hideHeaderCallback, localRegisterApplication, registrationFailureCallback } = this.props;
    const { crmUrl } = this.state;
    const { data } = e;

    if (typeof data !== 'string' || data === 'closed') {
      // Unwanted data or special mobile case
      return;
    }

    /*
     * Temporarily commented because of too many different domains for myVF
     *
     * if (!isSameDomain(process.env.REACT_APP_IFRAME_DOMAIN_ORIGIN, origin) && !(/^https?:\/\/localhost:3000$/ui).test(origin)) {
     *   // Message does not come from a trusted source
     *   return;
     * }
     */

    if (data === 'reloadtop') {
      this.resetLoadTimer();
      if (crmUrl && !isRunningOnLocalhost()) {
        window.location.replace(crmUrl);
      } else {
        hideHeaderCallback();
      }
      return;
    }

    if (data === 'cancel') {
      // Sign in was cancelled: go back to guest mode
      this.resetLoadTimer();

      // User closed sign-in page: no need to retry to play
      clearPendingOperation();

      Messenger.emit(MessengerEvents.SHOW_GUEST_MODE);
      return;
    }

    if (data === 'loaded') {
      this.resetLoadTimer();
      return;
    }

    if (data === 'accountcreated') {
      Messenger.emit(MessengerEvents.ACCOUNT_CREATED);
      return;
    }

    if (data === 'subscribed') {
      Messenger.emit(MessengerEvents.SUBSCRIBED);
      Messenger.emit(MessengerEvents.REFRESH_AUTHENTICATION_TOKEN);
      return;
    }

    const defaultUserDeviceInfo = {
      anonymous: false,
      applicationId: '',
      authDeviceUrl: '',
      deviceKey: '',
      error: null,
      logoutUrl: '',
      subscriberId: '',
      upgradeDeviceUrl: '',
    };

    const userDeviceInfo = this.parseUrl(data, defaultUserDeviceInfo);
    const { anonymous, applicationId, authDeviceUrl, deviceKey, error, subscriberId, upgradeDeviceUrl } = userDeviceInfo;

    if ((typeof error === 'string' && error !== '') || !applicationId || !authDeviceUrl || !deviceKey || !subscriberId || !upgradeDeviceUrl) {
      registrationFailureCallback();
      return;
    }

    // User successfully signed in
    generateToken(deviceId, userDeviceInfo).then((userDeviceInfoJwt) => {
      LocalStorageManager.save(StorageKeys.UserDeviceInfo, userDeviceInfoJwt);
      LocalStorageManager.save(StorageKeys.RegisteredAsGuest, anonymous);

      localRegisterApplication(userDeviceInfo, anonymous);
    });
  };

  handleCloseDebugPanelOnClick = () => {
    const { closeDebugPanelCallback } = this.props;

    closeDebugPanelCallback();
  };

  handleRevertAppConfOnClick = () => {
    // Remove overridden AppConf from local storage, then reload app
    LocalStorageManager.delete(StorageKeys.OverriddenAppConf);
    window.location.reload();
  };

  handleApplyAppConfOnClick = () => {
    const { selectedAppConf } = this.state;

    // Store overridden AppConf in local storage, then reload app
    LocalStorageManager.save(StorageKeys.OverriddenAppConf, selectedAppConf);
    window.location.reload();
  };

  handleAppConfUrlOnChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;

    this.setState({ selectedAppConf: value });
  };

  renderOverriddenAppConf = (): React.Node => {
    const { isDebugPanelVisible } = this.props;
    const { overriddenAppConf } = this.state;

    if (!isDebugPanelVisible && overriddenAppConf === '') {
      return null;
    }

    return (
      <div className='overriddenAppConf'>
        <div className='label'>AppConf URL:</div>
        <div className='value'>{overriddenAppConf || 'unchanged'}</div>
      </div>
    );
  };

  renderAppConf = (): React.Node => {
    const { isDebugPanelVisible } = this.props;
    const { selectedAppConf } = this.state;

    if (!isDebugPanelVisible) {
      return null;
    }

    return (
      <>
        <div className='separator' />
        <div className='row'>
          <div className='label'>AppConf URL:</div>
          <input onChange={this.handleAppConfUrlOnChange} placeholder='Enter an AppConf URL' type='text' value={selectedAppConf} />
        </div>
        <div className='row buttons'>
          <ButtonFX onClick={this.handleCloseDebugPanelOnClick}>Close</ButtonFX>
          <ButtonFX onClick={this.handleRevertAppConfOnClick}>Revert AppConf</ButtonFX>
          <ButtonFX onClick={this.handleApplyAppConfOnClick}>Apply AppConf</ButtonFX>
        </div>
      </>
    );
  };

  renderDebugPanel = (): React.Node => {
    const overriddenAppConfElt = this.renderOverriddenAppConf();
    const appConfElt = this.renderAppConf();

    if (!overriddenAppConfElt && !appConfElt) {
      return null;
    }

    return (
      <div className={clsx('debugPanel', overriddenAppConfElt && !appConfElt && 'reduced')}>
        {overriddenAppConfElt}
        {appConfElt}
      </div>
    );
  };

  render(): React.Node {
    const { crmUrl } = this.state;

    if (!crmUrl) {
      return null;
    }

    /* eslint-disable react/iframe-missing-sandbox */
    return (
      <div className='registrationFrameContainer'>
        {this.renderDebugPanel()}
        <iframe className='registrationFrame' src={crmUrl} title='Registration' />
      </div>
    );
    /* eslint-enable react/iframe-missing-sandbox */
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxRegistrationReducerStateType => {
  return {
    applicationId: state.appRegistration.applicationId,
    authDeviceUrl: state.appRegistration.authDeviceUrl,
    authenticationToken: state.appRegistration.authenticationToken,
    deviceId: state.appRegistration.deviceId,
    deviceKey: state.appRegistration.deviceKey,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    loginUrl: state.appConfiguration.configuration.loginUrl ?? '',
    logoutUrl: state.appRegistration.logoutUrl,
    podsWebUrl: state.appConfiguration.configuration.podsWebUrl ?? '',
    signupUrl: state.appConfiguration.configuration.signupUrl ?? '',
    subscribeWebUrl: state.appConfiguration.configuration.subscribeWebUrl ?? '',
    subscriberId: state.appRegistration.subscriberId,
    upgradeDeviceUrl: state.appRegistration.upgradeDeviceUrl,
    utmParameters: state.appConfiguration.utmParameters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxRegistrationDispatchToPropsType => {
  return {
    localRegisterApplication: (userDeviceInfo: UserDeviceInfoType, asGuest: boolean) => dispatch(registerApplication(userDeviceInfo, asGuest)),
  };
};

const Registration: React.ComponentType<RegistrationPropType> = connect(mapStateToProps, mapDispatchToProps)(RegistrationView);

export default Registration;
