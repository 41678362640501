/* @flow */

import type { ShakaOfflineContent, ShakaOfflineProgressUpdate, ShakaOfflineTrackSelection } from './shakaTypes';
import { logError, logInfo } from '../../../helpers/debug/debug';
import shaka from 'shaka-player/dist/shaka-player.compiled';

class ShakaStorage {
  // $FlowFixMe: not Flow-typed
  static storage: shaka.offline.Storage = new shaka.offline.Storage();

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'ShakaStorage';
  }

  static listContent: () => Promise<Array<ShakaOfflineContent> | null> = async () => {
    try {
      return await ShakaStorage.storage.list();
    } catch (error) {
      logError(`Error retrieving offline content: ${error.message}`);
      return null;
    }
  };

  // $FlowFixMe: not Flow-typed
  static playContent: (player: shaka.Player, offlineUri: string) => Promise<boolean> = async (player, offlineUri) => {
    try {
      await player.load(offlineUri);
      return true;
    } catch (error) {
      logError(`Error playing offline content: ${error.message}`);
      return false;
    }
  };

  static removeContent: (offlineUri: string) => Promise<boolean> = async (offlineUri) => {
    try {
      await ShakaStorage.storage.remove(offlineUri);
      return true;
    } catch (error) {
      logError(`Error removing offline content: ${error.message}`);
      return false;
    }
  };

  static removeAllContent: () => Promise<boolean> = async () => {
    try {
      const allContent = await ShakaStorage.listContent();
      if (allContent === null) {
        return false;
      }

      await Promise.all(allContent.map(async ({ offlineUri }) => await ShakaStorage.removeContent(offlineUri)));
      return true;
    } catch (error) {
      logError(`Error removing all offline content: ${error.message}`);
      return false;
    }
  };

  static downloadContent: (manifestUri: string, title: string, selectTracks: ShakaOfflineTrackSelection, updateProgress: ShakaOfflineProgressUpdate) => Promise<any> = (
    manifestUri,
    title,
    selectTracks,
    updateProgress,
  ) => {
    ShakaStorage.storage.configure({
      offline: {
        progressCallback: updateProgress,
        trackSelectionCallback: selectTracks,
      },
    });

    const metadata = {
      downloaded: new Date(),
      title,
    };

    logInfo('Starting download...');
    return ShakaStorage.storage.store(manifestUri, metadata).promise;
  };
}

export default ShakaStorage;
