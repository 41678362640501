/* @flow */

import * as React from 'react';
import { BroadcastStatus, WebAppHelpersLocationStatus } from '../../../helpers/ui/location/Format';
import {
  type ID_TYPE,
  type NETGEM_API_V8_SCHEDULED_RECORDING,
  type NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES,
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE,
  type NETGEM_RECORDINGS_MAP,
  RecordingOutcome,
} from '../../../libs/netgemLibrary/v8/types/Npvr';
import {
  ItemContent,
  ItemType,
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION_TYPE,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { PictoClock, PictoRecord } from '@ntg/components/dist/pictos/Element';
import { getIso8601DateInSeconds, getIso8601DurationInSeconds } from '../../../helpers/dateTime/Format';
import AccurateTimestamp from '../../../helpers/dateTime/AccurateTimestamp';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { Undefined } from '@ntg/utils/dist/types';
import WatchingStatus from '../../watchingStatus/WatchingStatus';

const getRecordingOutcomes = (
  npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  seriesScheduledRecording: ?NETGEM_API_V8_SCHEDULED_RECORDING,
  singleScheduledRecording: ?NETGEM_API_V8_SCHEDULED_RECORDING,
  item: NETGEM_API_V8_FEED_ITEM,
  scheduledEventId: string,
  tvLocationMetadata: NETGEM_API_V8_METADATA_SCHEDULE | null,
): Array<Undefined<RecordingOutcome>> => {
  const {
    selectedLocation: { startDate, duration },
    selectedProgramId: itemId,
  } = item;
  const outcomes: Array<Undefined<RecordingOutcome>> = [];

  let associatedRecords: ?Array<ID_TYPE> = null;
  if (singleScheduledRecording) {
    ({ records: associatedRecords } = singleScheduledRecording);
  } else if (seriesScheduledRecording) {
    ({ records: associatedRecords } = seriesScheduledRecording);
  }

  if (associatedRecords) {
    // Search in existing recordings
    const { [itemId]: recordings } = npvrRecordingsList;
    if (recordings) {
      // Check first if program is live right now
      const now = AccurateTimestamp.nowInSeconds();
      const start = getIso8601DateInSeconds(startDate);
      const end = start + getIso8601DurationInSeconds(duration);
      const startMargin = getIso8601DurationInSeconds(tvLocationMetadata?.location.startMargin);
      const isLive = start - startMargin <= now && now < end;

      associatedRecords.forEach((r) => {
        const recording = recordings.find((rec) => rec.id === r.id);
        if (recording && (!isLive || recording.scheduledEventStartDate === startDate)) {
          outcomes.push(recording.recordOutcome);
        }
      });
    }

    // Search in future recordings
    const { [itemId]: futureRecordings } = npvrRecordingsFuture;
    if (futureRecordings) {
      const recording = futureRecordings.find((rec) => rec.id === scheduledEventId);
      if (recording) {
        const { matchingScheduledRecordings } = recording;
        if (matchingScheduledRecordings) {
          matchingScheduledRecordings.forEach((sr) => outcomes.push(sr.recordOutcome));
        }
      }
    }
  }

  return outcomes;
};

const renderLiveRecordingIcon = (locType: ?NETGEM_API_V8_ITEM_LOCATION_TYPE, broadcastStatus: BroadcastStatus, isRecordOutcomeOK: boolean): React.Node => {
  if (broadcastStatus !== BroadcastStatus.Live || (locType !== (WebAppHelpersLocationStatus.Recording: string) && !isRecordOutcomeOK)) {
    return null;
  }

  return <PictoRecord className='recording live' hasBackground hasHoverEffect={false} />;
};

const renderRecordingIcon = (locType: ?NETGEM_API_V8_ITEM_LOCATION_TYPE, recordOutcome?: RecordingOutcome): React.Node => {
  if (locType !== (WebAppHelpersLocationStatus.Recording: string) || recordOutcome !== RecordingOutcome.Recorded) {
    return null;
  }

  return <PictoRecord className='recording' hasBackground hasHoverEffect={false} />;
};

const renderScheduledRecordingIcon = (broadcastStatus: BroadcastStatus, hasSeriesScheduledRecording: boolean, hasSingleScheduledRecording: boolean): React.Node => {
  if (broadcastStatus === BroadcastStatus.Live || broadcastStatus === BroadcastStatus.Past || (!hasSeriesScheduledRecording && !hasSingleScheduledRecording)) {
    return null;
  }

  return <PictoClock className='scheduledRecording' hasBackground hasHoverEffect={false} />;
};

const renderDebugInfoElement = (
  item: NETGEM_API_V8_FEED_ITEM,
  hasSingleScheduledRecording: boolean,
  hasSeriesScheduledRecording: boolean,
  recordingOutcomes: Array<Undefined<RecordingOutcome>>,
  channelNumber: number,
  isDebugModePlusEnabled: boolean,
): React.Node => {
  const {
    id,
    locType,
    score,
    selectedLocation: { matchingScheduledRecordings, recordOutcome },
    seriesId,
  } = item;

  if (!isDebugModePlusEnabled) {
    return null;
  }

  let scheduledRecordingType: ?NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND = null;
  if (hasSingleScheduledRecording) {
    scheduledRecordingType = NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE;
  } else if (hasSeriesScheduledRecording) {
    scheduledRecordingType = NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES;
  }
  const scheduledRecordingTypeElement = scheduledRecordingType ? <div className='scheduledRecordingType'>Recording kind: {scheduledRecordingType}</div> : null;

  const outcomeToString = (outcome?: RecordingOutcome): string => (typeof outcome === 'undefined' ? 'No record outcome' : (outcome: string));

  let debugContentElement = null;
  if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) {
    // Recording
    debugContentElement = <div className='recordOutcome'>Outcome: {outcomeToString(recordOutcome)}</div>;
  } else if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT) {
    // Scheduled recording
    let outcomes: Array<Undefined<RecordingOutcome>> = [];
    if (recordingOutcomes.length > 0) {
      outcomes = recordingOutcomes;
    } else if (matchingScheduledRecordings) {
      outcomes = matchingScheduledRecordings.map((sr) => sr.recordOutcome);
    }

    if (outcomes.length > 0) {
      let i = -1;
      debugContentElement = outcomes.map((outcome) => {
        i += 1;

        return (
          <div className='recordOutcome' key={i}>
            Outcome: {outcomeToString(outcome)}
          </div>
        );
      });
    }
  }

  return (
    <div className='debugInfo'>
      <div>
        <span>LCN: {channelNumber}</span>
        <span>{seriesId}</span>
        <span>{id !== seriesId ? id : null}</span>
        <span>{locType}</span>
        <span>{score ? JSON.stringify(score).slice(1, -1) : null}</span>
      </div>
      {scheduledRecordingTypeElement || debugContentElement ? (
        <div className='record'>
          {scheduledRecordingTypeElement}
          {debugContentElement}
        </div>
      ) : null}
    </div>
  );
};

const renderChannelImage = (channelImageUrl: string | null): React.Node => {
  if (!channelImageUrl) {
    return null;
  }

  return <div className='channelImage' style={{ backgroundImage: `url(${channelImageUrl})` }} />;
};

const renderWatchingStatus = (itemType: ?ItemType, contentType: ItemContent, vodLocationsMetadata: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null, progress: number | null): React.Node => {
  if (contentType === ItemContent.VODOrDeeplink && !vodLocationsMetadata && itemType !== ItemType.Series) {
    return null;
  }

  if (progress === null) {
    return null;
  }

  return <WatchingStatus progress={progress} />;
};

export { getRecordingOutcomes, renderChannelImage, renderDebugInfoElement, renderLiveRecordingIcon, renderRecordingIcon, renderScheduledRecordingIcon, renderWatchingStatus };
