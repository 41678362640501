/* @flow */

import * as React from 'react';
import { ContentType, ONE_HUNDRED, ONE_THOUSAND, type PlayerStateType, TIMESHIFT_THRESHOLD } from './constantsAndTypes';
import Graph, { Theme } from './Graph';
import type { ShakaInitData, ShakaMetrics, VideoTrack } from './implementation/shakaTypes';
import { logError, logInfo, logWarning } from '../../helpers/debug/debug';
import AccurateTimestamp from '../../helpers/dateTime/AccurateTimestamp';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';
import { KeepAliveState } from './keepAlive';
import { PictoX } from '@ntg/components/dist/pictos/Element';
import { VIDEOPLAYER_DEBUG } from './implementation/types';

const formatBitrate = (bitrate: number): string => `${Math.round(bitrate).toLocaleString()} kbps`;

// Takes a bitrate in bit/s and format a string in kpbs
const formatBpsBitrate = (bitrate: number): string => formatBitrate(bitrate / ONE_THOUSAND);

// Takes a bitrate in megabit/s and format a string in kpbs
const formatMbpsBitrate = (bitrate: number): string => formatBitrate(bitrate * ONE_THOUSAND);

const ROUNDING_DIGITS = 2;

const round = (value: number): string => value.toFixed(ROUNDING_DIGITS);

const renderDebugInfoLine = (label: string, value: string | number | boolean, noValue?: boolean): React.Node => (
  <div className='data'>
    <span className='label'>{label}:</span>
    {noValue ? null : <span className='value'>{typeof value !== 'undefined' ? value.toString() : VIDEOPLAYER_DEBUG.UnknownMetric}</span>}
  </div>
);

const renderDebugInfoLabel = (label: string): React.Node => renderDebugInfoLine(label, 0, true);

const renderGeneralDebug = (state: PlayerStateType, isBitrateLimited: boolean, maxBitrate: number, keepAliveState: KeepAliveState | null): React.Node => {
  const {
    contentType,
    currentItem,
    debugInfo: { isPlayheadPositionFallback, playerName, playerVersion, state: playerState, time, totalTime },
    isMuted,
    liveBufferLength,
    timeshift,
    volume,
  } = state;

  const displayTime =
    totalTime === Infinity ? `${time} (${new Date(time * ONE_THOUSAND).toLocaleString()})${isPlayheadPositionFallback ? ' [F]' : ''}` : `${time.toLocaleString()} s / ${totalTime.toLocaleString()} s`;

  const now = AccurateTimestamp.nowInSeconds();
  const currentTime = `${now} (${new Date(now * ONE_THOUSAND).toLocaleString()})`;

  return (
    <>
      <div className='title'>General</div>
      {renderDebugInfoLine('Player name', playerName)}
      {renderDebugInfoLine('Player version', playerVersion)}
      {currentItem ? renderDebugInfoLine('Location type', currentItem.locType ?? VIDEOPLAYER_DEBUG.UnknownMetric) : null}
      {renderDebugInfoLine('Type', (contentType: string))}
      {renderDebugInfoLine('State', playerState ?? VIDEOPLAYER_DEBUG.NoKeepAlive)}
      {renderDebugInfoLine('Keep-alive', keepAliveState ? (keepAliveState: string) : VIDEOPLAYER_DEBUG.NoKeepAlive)}
      {contentType === ContentType.Live ? renderDebugInfoLine('Current time', currentTime) : null}
      {renderDebugInfoLine('Playhead pos', displayTime)}
      {contentType === ContentType.Live ? renderDebugInfoLine('Timeshift buffer', `${Math.floor(liveBufferLength).toLocaleString()} s`) : null}
      {contentType === ContentType.Live ? renderDebugInfoLine('Timeshift', `${timeshift.toFixed(1)} s (${timeshift <= TIMESHIFT_THRESHOLD ? 'off' : 'on'})`) : null}
      {renderDebugInfoLine('Volume', `${Math.round(volume * ONE_HUNDRED)}%`)}
      {renderDebugInfoLine('Muted', isMuted)}
      {renderDebugInfoLine('Green streaming', `${isBitrateLimited ? 'on' : 'off'}${isBitrateLimited ? ` (${formatMbpsBitrate(maxBitrate)})` : ''}`)}
    </>
  );
};

const renderHtmlVideoElementDebug = (state: PlayerStateType): React.Node => {
  const {
    debugInfo: { bufferChunkCount, bufferLength, elementHeight, elementWidth, playbackRate, videoHeight, videoWidth },
  } = state;

  return (
    <>
      <div className='title'>HTML Video Element</div>
      {renderDebugInfoLine('Element size', typeof elementWidth === 'number' && typeof elementHeight === 'number' ? `${elementWidth} x ${elementHeight}` : VIDEOPLAYER_DEBUG.UnknownMetric)}
      {renderDebugInfoLine('Video size', typeof videoWidth === 'number' && typeof videoHeight === 'number' ? `${videoWidth} x ${videoHeight}` : VIDEOPLAYER_DEBUG.UnknownMetric)}
      {renderDebugInfoLine('Buffer', bufferLength && bufferChunkCount ? `${bufferLength} s (${bufferChunkCount} chunk${bufferChunkCount > 1 ? 's' : ''})` : VIDEOPLAYER_DEBUG.BufferFlushed)}
      {renderDebugInfoLine('Playback rate', playbackRate ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
    </>
  );
};

const renderShakaVideoDebug = (state: PlayerStateType): React.Node => {
  const {
    contentType,
    debugInfo: { shakaMetrics, streamType },
  } = state;

  if (!shakaMetrics) {
    return null;
  }

  const {
    bufferFullness,
    selectedTrackIds: { video: selectedVideoId },
    stats: {
      bufferingTime,
      completionPercent,
      corruptedFrames,
      decodedFrames,
      droppedFrames,
      estimatedBandwidth,
      gapsJumped,
      liveLatency,
      loadLatency,
      manifestTimeSeconds,
      maxSegmentDuration,
      pauseTime,
      playTime,
      stallsDetected,
    },
    tracks: { video: videoTracks },
  } = shakaMetrics;

  const currentLevel = videoTracks.findIndex((track) => track.videoId === selectedVideoId);
  const currentTrack = currentLevel > -1 ? videoTracks[currentLevel] : null;

  const videoBandwidth = currentTrack?.videoBandwidth ?? 0;

  return (
    <>
      {renderDebugInfoLine('Stream type', streamType ? (streamType: string).toUpperCase() : VIDEOPLAYER_DEBUG.UnknownMetric)}
      {renderDebugInfoLine('Manifest load & parse time', isNaN(manifestTimeSeconds) ? VIDEOPLAYER_DEBUG.NotApplicable : `${round(manifestTimeSeconds)} s`)}
      {renderDebugInfoLine('Max segment duration', isNaN(maxSegmentDuration) ? VIDEOPLAYER_DEBUG.NotApplicable : `${round(maxSegmentDuration)} s`)}
      {renderDebugInfoLine('Load latency', `${round(loadLatency)} s`)}
      {contentType !== ContentType.Static ? renderDebugInfoLine('Live latency', isNaN(liveLatency) ? VIDEOPLAYER_DEBUG.UnknownMetric : `${round(liveLatency)} s`) : null}
      {renderDebugInfoLine('Play time', `${round(playTime).toLocaleString()} s`)}
      {renderDebugInfoLine('Pause time', `${round(pauseTime).toLocaleString()} s`)}
      {renderDebugInfoLine('Playback stalls', stallsDetected.toLocaleString())}
      {renderDebugInfoLine('Gaps jumped', gapsJumped.toLocaleString())}
      {renderDebugInfoLine('Buffer', `${round(bufferFullness * ONE_HUNDRED)} %`)}
      {renderDebugInfoLine('Buffering time', `${round(bufferingTime).toLocaleString()} s`)}
      {renderDebugInfoLine('Decoded frames', decodedFrames.toLocaleString())}
      {renderDebugInfoLine('Dropped frames', droppedFrames.toLocaleString())}
      {renderDebugInfoLine('Corrupted frames', corruptedFrames.toLocaleString())}
      {renderDebugInfoLine('Estimated bandwidth', isNaN(estimatedBandwidth) ? VIDEOPLAYER_DEBUG.NotApplicable : formatBpsBitrate(estimatedBandwidth))}
      {contentType !== ContentType.Live ? renderDebugInfoLine('Completion percent', `${completionPercent} %`) : null}
      {renderDebugInfoLine('Available levels', videoTracks.length)}
      {videoTracks.length > 0 ? (
        <>
          {renderDebugInfoLabel('Current level')}
          <div className='subsection'>
            {renderDebugInfoLine('Level', `${currentLevel > -1 ? `${currentLevel + 1}` : VIDEOPLAYER_DEBUG.UnknownMetric} / ${videoTracks.length}`)}
            {renderDebugInfoLine('Variant Id', currentTrack?.id ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Video Id', currentTrack?.videoId ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Original video Id', currentTrack?.originalVideoId ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Label', currentTrack?.label ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Resolution', `${currentTrack?.width ?? VIDEOPLAYER_DEBUG.UnknownMetric} x ${currentTrack?.height ?? VIDEOPLAYER_DEBUG.UnknownMetric}`)}
            {renderDebugInfoLine('Language', currentTrack?.language ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Mime type', currentTrack?.videoMimeType ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Codec', currentTrack?.videoCodec ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Roles', currentTrack?.roles.join(' | ') ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Frame rate', currentTrack?.frameRate ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Required bandwidth', videoBandwidth > 0 ? formatBpsBitrate(videoBandwidth) : VIDEOPLAYER_DEBUG.UnknownMetric)}
          </div>
        </>
      ) : null}
    </>
  );
};

const renderShakaAudioDebug = (state: PlayerStateType): React.Node => {
  const {
    debugInfo: { shakaMetrics },
  } = state;

  if (!shakaMetrics) {
    return null;
  }

  const {
    selectedTrackIds: { audio: selectedAudioId },
    tracks: { audio: audioTracks },
  } = shakaMetrics || {};

  const currentIndex = audioTracks.findIndex((track) => track.audioId === selectedAudioId);
  const currentTrack = currentIndex > -1 ? audioTracks[currentIndex] : null;

  const audioBandwidth = currentTrack?.audioBandwidth ?? 0;

  return (
    <>
      {renderDebugInfoLine('Available tracks', audioTracks.length)}
      {audioTracks.length > 0 ? (
        <>
          {renderDebugInfoLabel('Current track')}
          <div className='subsection'>
            {renderDebugInfoLine('Variant Id', currentTrack?.id ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Audio Id', currentTrack?.audioId ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Original audio Id', currentTrack?.originalAudioId ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Label', currentTrack?.label ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Language', currentTrack?.language ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Mime type', currentTrack?.audioMimeType ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Codec', currentTrack?.audioCodec ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Roles', currentTrack?.roles.join(' | ') ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
            {renderDebugInfoLine('Required bandwidth', audioBandwidth > 0 ? formatBpsBitrate(audioBandwidth) : VIDEOPLAYER_DEBUG.UnknownMetric)}
          </div>
        </>
      ) : null}
    </>
  );
};

const renderLegacySubtitlesDebug = (state: PlayerStateType): React.Node => {
  const { selectedSubtitlesMediaInfo, subtitlesMediaInfo } = state;
  const subs = subtitlesMediaInfo.find((s) => s.index === selectedSubtitlesMediaInfo);
  const { id, index, lang } = subs || {};

  const areSubtitlesEnabled = subtitlesMediaInfo.length > 0 && subs;
  const currentTrackLabel = areSubtitlesEnabled ? renderDebugInfoLabel('Current track') : renderDebugInfoLine('Current track', 'None');
  const currentTrackDetails = areSubtitlesEnabled ? (
    <div className='subsection'>
      {renderDebugInfoLine('Id', id ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
      {renderDebugInfoLine('Index', index ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
      {renderDebugInfoLine('Language', lang ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
    </div>
  ) : null;

  return (
    <>
      <div className='title'>Subtitles</div>
      {renderDebugInfoLine('Available tracks', subtitlesMediaInfo.length)}
      {subtitlesMediaInfo.length > 0 ? (
        <>
          {currentTrackLabel}
          {currentTrackDetails}
        </>
      ) : null}
    </>
  );
};

const renderSubtitlesDebug = (state: PlayerStateType): React.Node => {
  const {
    debugInfo: { shakaMetrics },
  } = state;

  if (!shakaMetrics) {
    return renderLegacySubtitlesDebug(state);
  }

  const {
    selectedTrackIds: { text: selectedTextId },
    tracks: { text: textTracks },
  } = shakaMetrics;

  const currentIndex = textTracks.findIndex((track) => track.id === selectedTextId);
  const currentTrack = currentIndex > -1 ? textTracks[currentIndex] : null;

  const currentTrackIntro = currentIndex > -1 ? renderDebugInfoLabel('Current track') : renderDebugInfoLine('Current track', 'None');
  const currentTrackDetails = currentTrack ? (
    <div className='subsection'>
      {renderDebugInfoLine('Id', currentTrack.id)}
      {renderDebugInfoLine('Language', currentTrack.language)}
      {renderDebugInfoLine('Label', currentTrack.label ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
      {renderDebugInfoLine('Kind', currentTrack.kind ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
      {renderDebugInfoLine('Roles', currentTrack.roles.join(' | '))}
      {renderDebugInfoLine('Mime type', currentTrack.mimeType ?? VIDEOPLAYER_DEBUG.UnknownMetric)}
      {renderDebugInfoLine('Forced', currentTrack.forced)}
    </div>
  ) : null;

  return (
    <>
      <div className='title'>Subtitles</div>
      {renderDebugInfoLine('Available tracks', textTracks.length)}
      {textTracks.length > 0 ? (
        <>
          {currentTrackIntro}
          {currentTrackDetails}
        </>
      ) : null}
    </>
  );
};

const getInitDataType = (initData: Array<ShakaInitData>): string => {
  if (initData.length > 0) {
    return initData.map((data) => data.initDataType).join(' | ');
  }

  return VIDEOPLAYER_DEBUG.UnknownMetric;
};

const renderDrmDetails = (shakaMetrics: ShakaMetrics): React.Node => {
  const {
    drmInfo: { hasVUDrmToken, initData, keySystem },
    stats: { drmTimeSeconds, licenseTime },
  } = shakaMetrics;

  if (!keySystem) {
    return (
      <div className='data'>
        <span className='label'>No DRM</span>
      </div>
    );
  }

  return (
    <>
      {renderDebugInfoLine('Key system', keySystem)}
      {renderDebugInfoLine('Init data type', getInitDataType(initData))}
      {renderDebugInfoLine('DRM download time', `${round(drmTimeSeconds)} s`)}
      {renderDebugInfoLine('License requests time', `${round(licenseTime)} s`)}
      {renderDebugInfoLine('VU DRM token', hasVUDrmToken ? 'yes' : 'no')}
    </>
  );
};

const renderDrmDebug = (state: PlayerStateType): React.Node => {
  const {
    debugInfo: { shakaMetrics },
  } = state;

  if (!shakaMetrics) {
    return null;
  }

  return (
    <>
      <div className='title'>DRM</div>
      {renderDrmDetails(shakaMetrics)}
    </>
  );
};

const getBuffer = (state: PlayerStateType) => () => [state.debugInfo.shakaMetrics?.bufferFullness ?? 0];

const getCurrentLevelAndTrack = (shakaMetrics: ShakaMetrics): {| currentLevel: number, currentTrack: VideoTrack | null |} => {
  const {
    selectedTrackIds: { video: selectedVideoId },
    tracks: { video: videoTracks },
  } = shakaMetrics;

  const currentLevel = videoTracks.findIndex((track) => track.videoId === selectedVideoId);
  const currentTrack = currentLevel > -1 ? videoTracks[currentLevel] : null;

  return {
    currentLevel,
    currentTrack,
  };
};

const getLevel = (state: PlayerStateType) => () => {
  const {
    debugInfo: { shakaMetrics },
  } = state;

  if (!shakaMetrics) {
    return [0];
  }

  const { currentLevel } = getCurrentLevelAndTrack(shakaMetrics);

  return [currentLevel];
};

const getResolution = (state: PlayerStateType) => () => {
  const {
    debugInfo: { videoHeight },
  } = state;

  return typeof videoHeight === 'number' ? [videoHeight] : [0];
};

const getBandwidths = (state: PlayerStateType) => () => {
  const {
    debugInfo: { shakaMetrics },
  } = state;

  if (!shakaMetrics) {
    return [0, 0];
  }

  const { currentTrack } = getCurrentLevelAndTrack(shakaMetrics);
  const requiredBandwidth = currentTrack?.videoBandwidth ?? 0;

  const {
    stats: { estimatedBandwidth },
  } = shakaMetrics;

  return [estimatedBandwidth / ONE_THOUSAND, requiredBandwidth / ONE_THOUSAND];
};

const bufferYAxisCallback = (value: number): string => `${(value * ONE_HUNDRED).toFixed(0)} %`;

const resolutionAfterBuildTicksCallback = (axis: any) => {
  // eslint-disable-next-line no-magic-numbers
  axis.ticks = [144, 360, 504, 720, 936, 1080].map((v) => {
    return { value: v };
  });
};

const renderGraphs = (state: PlayerStateType) => {
  const {
    debugInfo: { shakaMetrics },
  } = state;

  if (!shakaMetrics) {
    return null;
  }

  const {
    tracks: { video: videoTracks },
  } = shakaMetrics;
  const levelGraph = videoTracks.length > 0 ? <Graph getY={getLevel(state)} maxValue={videoTracks.length - 1} theme={Theme.Blue} tickStep={1} title='Video level' /> : null;

  return (
    <>
      {levelGraph}
      <Graph afterBuildTicksCallback={resolutionAfterBuildTicksCallback} getY={getResolution(state)} maxValue={1080} theme={Theme.Yellow} title='Video resolution' />
      <Graph getY={getBuffer(state)} maxValue={1} theme={Theme.Red} tickCallback={bufferYAxisCallback} title='Buffer' />
      <Graph datasetCount={2} getY={getBandwidths(state)} theme={Theme.Green} title='Estimated & required bandwidths (kpbs)' />
    </>
  );
};

let panelContainer: HTMLElement | null = null;
let panel: HTMLElement | null = null;
let offsetX = 0;
let offsetY = 0;

const handleOnMouseDown = (event: SyntheticMouseEvent<HTMLElement>) => {
  event.preventDefault();
  event.stopPropagation();

  const { clientX, clientY, currentTarget } = event;

  const panelElt = currentTarget.closest('.debugOverlay');
  if (!panelElt || !(panelElt instanceof HTMLElement)) {
    return;
  }

  const panelContainerElt = panelElt.parentElement;
  if (!panelContainerElt || !(panelContainerElt instanceof HTMLElement)) {
    return;
  }

  // Initialize dragging
  panel = panelElt;
  panelContainer = panelContainerElt;
  offsetX = panel.offsetLeft - clientX;
  offsetY = panel.offsetTop - clientY;
};

const handleOnMouseUp = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
  event.preventDefault();
  event.stopPropagation();

  // End dragging
  panel = null;
  panelContainer = null;
};

const handleOnMouseMove = (event: SyntheticMouseEvent<HTMLElement>) => {
  event.preventDefault();
  event.stopPropagation();

  if (!panel || !panelContainer) {
    // Not dragging
    return;
  }

  const { clientX, clientY } = event;
  let newLeft = clientX + offsetX;
  let newTop = clientY + offsetY;

  if (newLeft < panelContainer.offsetLeft) {
    newLeft = panelContainer.offsetLeft;
  } else {
    const maxLeft = panelContainer.offsetWidth - panel.offsetWidth;
    if (newLeft > maxLeft) {
      newLeft = maxLeft;
    }
  }

  if (newTop < panelContainer.offsetTop) {
    newTop = panelContainer.offsetTop;
  } else {
    const maxTop = panelContainer.offsetHeight - panel.offsetHeight;
    if (newTop > maxTop) {
      newTop = maxTop;
    }
  }

  panel.style.left = `${newLeft}px`;
  panel.style.top = `${newTop}px`;
};

const copyUrl = async (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): Promise<void> => {
  event.preventDefault();
  event.stopPropagation();

  const { currentTarget } = event;
  const label = currentTarget.getAttribute('data-label') ?? 'URL';
  const url = currentTarget.getAttribute('data-url');

  if (!url) {
    logWarning(`${label} not found`);
    return;
  }

  try {
    await navigator.clipboard.writeText(url);
    logInfo(`${label} was copied to clipboard`);
  } catch {
    logError(`Error copying ${label} to clipboard`);
  }
};

const renderUrl = (label: string, url: string): React.Node => {
  if (typeof url === 'undefined' || url === '') {
    return null;
  }

  return (
    <div className='wholeRow'>
      <div className='title'>{label}</div>
      <div className='data'>
        <div className='label button' data-label={label} data-url={url} onClick={copyUrl}>
          COPY
        </div>
        <span className='value'>{url}</span>
      </div>
    </div>
  );
};

const renderDebugOverlay = (state: PlayerStateType, isBitrateLimited: boolean, maxBitrate: number, keepAliveState: KeepAliveState | null, onCloseHandler: BasicCallbackFunction): React.Node => {
  const {
    debugInfo: { laUrl, streamUrl },
    isDebugOverlayVisible,
  } = state;

  if (!isDebugOverlayVisible) {
    return null;
  }

  return (
    <div className='debugOverlay' onMouseDown={handleOnMouseDown} onMouseMove={handleOnMouseMove} onMouseUp={handleOnMouseUp}>
      <PictoX onClick={onCloseHandler} />
      {renderUrl('Stream URL', streamUrl)}
      {renderUrl('LA URL', laUrl)}
      <div className='column1'>
        {renderGeneralDebug(state, isBitrateLimited, maxBitrate, keepAliveState)}
        {renderHtmlVideoElementDebug(state)}
        <div className='title'>Audio</div>
        {renderShakaAudioDebug(state)}
        {renderSubtitlesDebug(state)}
      </div>
      <div className='column2'>
        <div className='title'>Video</div>
        {renderShakaVideoDebug(state)}
        {renderDrmDebug(state)}
      </div>
      <div className='column3'>{renderGraphs(state)}</div>
    </div>
  );
};

export { renderDebugOverlay };
