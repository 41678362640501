/* @flow */

import type { ChannelMap, NETGEM_API_CHANNEL } from '../../libs/netgemLibrary/v8/types/Channel';
import type { LOCATION_METADATA_METRICS, NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { MetadataKind, NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import type { NETGEM_API_VIEWINGHISTORY, NETGEM_API_VIEWINGHISTORY_ITEM } from '../../libs/netgemLibrary/v8/types/ViewingHistory';
import { Setting, type Settings } from '../../helpers/settings/types';
import type { VideoPlayerDebugInfo, VideoPlayerExternalSubtitles, VideoPlayerInitData, VideoPlayerMediaInfo } from './implementation/types';
import type { BO_PURCHASE_LIST_TYPE } from '../../redux/netgemApi/actions/videofutur/types/purchase';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';
import type { CONFIRMATION_DATA_MODAL_TYPE } from '../modal/confirmationModal/ConfirmationModal';
import type { CombinedReducers } from '../../redux/reducers';
import { ControlLevel } from '../../helpers/ui/player';
import type { DATA_COLLECTION_INTERNAL_MESSAGE } from '../../libs/netgemLibrary/v8/types/DataCollection';
import type { DmsSettingMap } from '../../libs/netgemLibrary/dms/types/DeviceInfoSettings';
import type { EXTENDED_ITEM } from '../../helpers/ui/item/types';
import { LoadableStatus } from '../../helpers/loadable/loadable';
import type { LuminosityType } from '@ntg/ui/dist/theme';
import type { NETGEM_API_V8_FEED_ITEM } from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_RECORDINGS_MAP } from '../../libs/netgemLibrary/v8/types/Npvr';
import type { STREAM_PRIORITIES_TYPE } from '../../helpers/ui/metadata/Types';
import type { SettingValueType } from '../settings/SettingsConstsAndTypes';
import type { ShakaOfflineContent } from './implementation/shakaTypes';
import { WebAppHelpersLocationStatus } from '../../helpers/ui/location/Format';

export enum StopStreamKind {
  FinalStop,
  Initialization,
  Pause,
}

export enum SkippingKind {
  BackwardLevel0,
  BackwardLevel1,
  BackwardLevel2,
  ForwardLevel0,
  ForwardLevel1,
  ForwardLevel2,
  None,
}

export type NTGENTITLEMENT_PARAMS = {|
  channelId: ?string,
  customData: string,
  service: string,
  url: string,
|};

/*
 * Steps:
 *   NotStarted > LoadingProgramMetadata > Loaded | ItemNotFound
 * or:
 *   NotStarted > LoadingProgramMetadata > LoadingSeriesMetadata > Loaded | ItemNotFound
 */
export enum ProgramInfoLoadStatus {
  NotStarted,
  LoadingProgramMetadata,
  LoadingSeriesMetadata,
  Loaded,
  ItemNotFound,
}

/*
 * Resume mechanism can be in 4 states:
 *  - NotChecked: we've not checked yet if a resume position exists
 *  - NoResumePosition: no resume position has been found -> nothing to do
 *  - PromptUserIfNeeded: a resume positions exists, user should be prompted to use it or not (skipped if "auto resume" setting is enabled)
 *  - Seeking: user chose to restart or resume (could take a little time since we have to wait that the video actually starts playing)
 *  - Done: user made a choice (resume or restart) and video seeked to the right position
 */
export enum ResumeState {
  NotChecked,
  NoResumePosition,
  PromptUserIfNeeded,
  Seeking,
  Done,
}

export enum ContentType {
  // Live TV program
  Live,
  // Live TV program being recorded (including end margin)
  LiveRecording,
  // Catchup, finished recording, VOD
  Static,
}

// If resume position is less than 5s from the end, program is considered entirely watched and resume position is reset (in seconds)
export const RESUME_REWIND_DURATION = 5;

export const DOUBLE_CLICK = Object.freeze({
  // In percent
  BackwardSkipAreaThreshold: 0.2,
  // In percent
  ForwardSkipAreaThreshold: 0.8,
  // In milliseconds
  MaxDuration: 300,
});

// Display duration of skip and play/pause pictos (in milliseconds)
export const BLINKING_PICTO_DURATION = 500;

// Display duration of controls (in milliseconds)
export const OVERLAY_TIMEOUT = 3_000;

// Time between two updates of the player debug info metrics (in milliseconds)
export const DEBUG_INFO_METRICS_TIMEOUT = 100;

// In seconds
export const PLAYHEAD_POSITION_CHANGE_THRESHOLD = 5;

// Increment/decrement when changing volume through keyboard shortcut
export const VOLUME_STEP = 0.1;

// Playback rate is capped at 16 in most browsers
export const PLAYBACK_RATES: Array<number> = Object.freeze([0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 5, 10, 16]); // eslint-disable-line no-magic-numbers
export const NORMAL_PLAYBACK_INDEX: number = PLAYBACK_RATES.findIndex((r) => r === 1);

export const ONE_HUNDRED = 100;
export const ONE_THOUSAND = 1_000;

// Playback duration greater than twice the program duration is considered an error
export const PLAYBACK_DURATION_ERROR_THRESHOLD = 2;

// If position is between <now - TIMESHIFT_THRESHOLD> and <now>, we consider the stream is played live, otherwise, we're timeshifting (in seconds)
export const TIMESHIFT_THRESHOLD = 5;

// Image dimension for Global Media Controls
export const GMC_IMAGE_WIDTH = 320;
export const GMC_IMAGE_HEIGHT = 184;

// Image dimension for cover in controller
export const COVER_IMAGE_WIDTH = 108;
export const COVER_IMAGE_HEIGHT = 160;

// Video resolution
export const RESOLUTION = Object.freeze({
  FourK: {
    Height: 2160,
    Width: 3840,
  },
  HD: {
    Height: 720,
    Width: 1280,
  },
});

export type ViewingHistoryIds = {|
  isVod: boolean,
  locationId: string | null,
  programId: string,
  seriesId: string | null,
|};

export type ResumeData = {|
  resumePosition: number,
  resumeState: ResumeState,
|};

export type CreateVodStreamFunction = (distributorId: string, vtiId: number, signal?: AbortSignal) => Promise<any>;

export type ReduxPlayerDispatchToPropsType = {|
  +closeConfirmation: BasicCallbackFunction,
  +localGetImageUrl: (assetId: string, width: number, height: number, luminosity?: LuminosityType, signal?: AbortSignal) => Promise<any>,
  +localCreateSvodStreamFromId: CreateVodStreamFunction,
  +localCreateVodStreamFromId: CreateVodStreamFunction,
  +localSendNtgEntitlementGetRequest: (customData: string, service: string, url: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +localSendNtgEntitlementReleaseRequest: (customData: string, service: string, url: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +localSendV8LocationEpgRequest: (startTime: number, range: number, channelIds: Array<string>, signal?: AbortSignal) => Promise<any>,
  +localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal) => Promise<any>,
  +localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal) => Promise<any>,
  +localSendV8RecordingsMetadataRequest: (recordId: string, signal?: AbortSignal) => Promise<any>,
  +localSendVideofuturStreamCreateRequest: (distributorId: string, vtiId: number, licence: boolean, signal?: AbortSignal) => Promise<any>,
  +localSendVideofuturStreamStartRequest: (distributorId: string, streamId: number, signal?: AbortSignal) => Promise<any>,
  +localSendVideofuturStreamStopRequest: (distributorId: string, streamId: number, bookmark?: string, signal?: AbortSignal, keepAlive?: boolean) => Promise<any>,
  +localShowConfirmation: (data: CONFIRMATION_DATA_MODAL_TYPE) => void,
  +localStopOpenStreams: (distributorId: string, signal?: AbortSignal) => Promise<any>,
  +localUpdateSetting: (setting?: Setting, value: SettingValueType) => Promise<any>,
  +localUpdateViewingHistory: (item: NETGEM_API_VIEWINGHISTORY_ITEM, signal?: AbortSignal) => Promise<any>,
|};

export type ReduxPlayerReducerStateType = {|
  +applicationName: string,
  +authenticationToken: string | null,
  +channels: ChannelMap,
  +deviceSettings: DmsSettingMap,
  +isDebugModeEnabled: boolean,
  +isMaxBitrateAllowed: boolean,
  +maxBitrate: number,
  +npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  +purchaseList: BO_PURCHASE_LIST_TYPE,
  +settings: Settings,
  +state: CombinedReducers,
  +streamPriorities: STREAM_PRIORITIES_TYPE | null,
  +useBOV2Api: boolean,
  +viewingHistory: NETGEM_API_VIEWINGHISTORY,
  +viewingHistoryStatus: LoadableStatus,
|};

export type PlayerPropType = {|
  +closeCallback: (retry: boolean) => void,
  +playerOfflineContent: ShakaOfflineContent | null,
  +playerItem: EXTENDED_ITEM | null,
|};

export type CompletePlayerPropType = {|
  ...PlayerPropType,
  ...ReduxPlayerDispatchToPropsType,
  ...ReduxPlayerReducerStateType,
|};

export type PlayerStateInitializationType = {|
  endMargin: number,
  externalSubtitles: Array<VideoPlayerExternalSubtitles>,
  isBuffering: boolean,
  isVideofuturAsset: boolean,
  locationId: string | null,
  programInfoLoadStatus: ProgramInfoLoadStatus,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  realEnd: number,
  realStart: number,
  seriesEpisodeText: string | null,
  seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES,
  startMargin: number,
  title: string | null,
  videoStreamData: ?VideoPlayerInitData,
  viewingHistoryId: string | null,
  vtiId: number | null,
|};

export type PlayerStateType = {|
  ...PlayerStateInitializationType,
  audioMediaInfo: Array<VideoPlayerMediaInfo>,
  bufferedTimeRanges: TimeRanges | null,
  channel: NETGEM_API_CHANNEL | null,
  channelImageUrl: string,
  contentType: ContentType,
  controlLevel: ControlLevel,
  currentItem: NETGEM_API_V8_FEED_ITEM | null,
  // Last time playbackDuration was updated (0 when paused) (in seconds)
  dataCollectionLastPlaybackTime: number,
  // Current data collection player state
  dataCollectionMessage: DATA_COLLECTION_INTERNAL_MESSAGE | null,
  // Time of the cdata collection session start
  dataCollectionStartTime: number,
  debugInfo: VideoPlayerDebugInfo,
  // Initialized first with the duration from the program's metadata, then adjusted by the player
  duration: number,
  // Used to restart session without losing playhead position (Broadpeak keep-alive workaround)
  forcedResumePosition: number | null,
  imageUrl: string | null,
  isBOStreamRequestPending: boolean,
  isControllerEnabled: boolean,
  isCurrentItemBlackedOut: boolean,
  isDebugOverlayVisible: boolean,
  isInFullscreen: boolean,
  // Following property is used to display a recording picto while playing a live stream (when contentType === ContentType.Live)
  isLiveRecording: boolean,
  isMuted: boolean,
  isOverlayVisible: boolean,
  isPlaying: boolean,
  isTimeshiftEnabled: boolean,
  isTimeshiftSwitching: boolean,
  isTrailer: boolean,
  isVideoQualityAuto: boolean,
  liveBufferLength: number,
  location: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION | null,
  locationMetrics: ?Array<LOCATION_METADATA_METRICS>,
  locationStatus: WebAppHelpersLocationStatus | null,
  playheadPosition: number,
  resumePosition: number,
  resumeState: ResumeState,
  selectedAudioMediaInfo: number,
  selectedSubtitlesMediaInfo: number,
  selectedVideoQuality: number,
  skipping: SkippingKind,
  subtitlesMediaInfo: Array<VideoPlayerMediaInfo>,
  timeshift: number,
  // Duration from location metadata
  totalDuration: number,
  userViewEndOffset: number,
  userViewStartOffset: number,
  volume: number,
|};
