/* @flow */

import './MainLayout.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../ku.js';
import '../../ku.css';
import * as React from 'react';
import { type AllSettledPromises, SettledPromiseFulfilled } from '../../helpers/jsHelpers/promise';
import { AvenueType, type FOCUSED_AVENUE_TYPE } from '../../helpers/ui/avenue/types';
import { type EXTENDED_ITEM, ExtendedItemType } from '../../helpers/ui/item/types';
import {
  ItemType,
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION_TYPE,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD,
} from '../../libs/netgemLibrary/v8/types/FeedItem';
import { METADATA_KIND_PROGRAM, METADATA_KIND_SERIES, type MetadataKind } from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import {
  NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_CARD,
  NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_HUB,
  NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_PLAYER,
  NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_SWITCH,
  type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE1,
  type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE2,
} from '../../libs/netgemLibrary/v8/types/WidgetConfig';
import { RegistrationType, type UserInfo } from '../../redux/appRegistration/types/types';
import { enterTheaterMode, exitTheaterMode, resetGridSectionId, resetSectionPageIndices, updateDisplayPaywallSubscription, updateFocusedAvenue, updateSetting } from '../../redux/ui/actions';
import { getValidHub, sendV8HubRequest } from '../../redux/netgemApi/actions/v8/hub';
import { hideModal, reopenModalCard, showAvenueModal } from '../../redux/modal/actions';
import { logDebug, logError, logInfo, logWarning } from '../../helpers/debug/debug';
import { APPLICATION_ID } from '../../helpers/applicationCustomization/types';
import type { AVENUE_DATA_MODAL_TYPE } from '../modal/avenueModal/AvenueModal';
import AccurateTimestamp from '../../helpers/dateTime/AccurateTimestamp';
import AvenueView from '../avenue/Avenue';
import { type BasicCallbackFunction } from '@ntg/utils/dist/types';
import type { CARD_DATA_MODAL_TYPE } from '../modal/cardModal/CardModalConstsAndTypes';
import CircleLoader from '../loader/circleLoader';
import type { CombinedReducers } from '../../redux/reducers';
import type { Dispatch } from '../../redux/types/types';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import { FEATURE_NPVR } from '../../redux/appConf/constants';
import type { FeedRequestFunction } from '../../libs/netgemLibrary/v8/types/Feed';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import HotKeys from '../../helpers/hotKeys/hotKeys';
import ImageCarousel from '../carousel/ImageCarousel';
import LocalStorageManager from '../../helpers/localStorage/localStorageManager';
import { MILLISECONDS_PER_WEEK } from '../../helpers/dateTime/Format';
import type { ModalState } from '../../redux/modal/reducers';
import type { NETGEM_API_V8_HUB } from '../../libs/netgemLibrary/v8/types/Hub';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../libs/netgemLibrary/v8/types/RequestResponse';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { NO_AVENUE_INDEX } from '../navigationMenu/NavigationMenu';
import { ONE_THOUSAND } from '../player/constantsAndTypes';
import Player from '../player/Player';
import { Setting } from '../../helpers/settings/types';
import { type SettingValueType } from '../settings/SettingsConstsAndTypes';
import type { ShakaOfflineContent } from '../player/implementation/shakaTypes';
import { StorageKeys } from '../../helpers/localStorage/keys';
import VideoCarousel from '../carousel/VideoCarousel';
import { buildFeedItem } from '../../libs/netgemLibrary/v8/helpers/Item';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { deleteWishlist } from '../../redux/netgemApi/actions/personalData/wishlist';
import { getAvenueFromId } from '../../helpers/ui/avenue/helpers';
import { getIntegerPercentage } from '../../helpers/maths/maths';
import { getMainAvenue } from '../../redux/ui/reducers/helpers';
import { hasPendingOperation } from '../../helpers/rights/pendingOperations';
import { hasPods } from '../../helpers/rights/userInfo';
import i18next from 'i18next';
import { ignoreIfAborted } from '../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isTabHidden } from '../../helpers/ui/browser';
import log from 'loglevel';
import { openFooterPage } from '@ntg/utils/dist/footer';
import { saveSearchString } from '../../helpers/search/search';
import search from '../../redux/netgemApi/actions/v8/search';
import sendV8AssetIdForDeeplinkRequest from '../../redux/netgemApi/actions/v8/deeplink';
import sendV8CustomStrategyRequest from '../../redux/netgemApi/actions/v8/customStrategyRequest';
import sendV8LocationCatchupForAssetRequest from '../../redux/netgemApi/actions/v8/catchupForAsset';
import sendV8LocationEpgForAssetRequest from '../../redux/netgemApi/actions/v8/epgForAsset';
import sendV8LocationVodForAssetRequest from '../../redux/netgemApi/actions/v8/vodForAsset';
import sendV8MetadataRequest from '../../redux/netgemApi/actions/v8/metadata';
import { showDebug as showChargebeeDebug } from '../../helpers/applicationCustomization/externalPaymentSystem';
import { showEE } from '../../helpers/debug/ee';
import { togglePackPurchase } from '../../redux/appConf/actions';

/* eslint-disable no-inline-comments, capitalized-comments */
const NpvrManagement = React.lazy(() => import(/* webpackPreload: true */ '../npvrManagement/NpvrManagement'));
const Settings = React.lazy(() => import(/* webpackPreload: true */ '../settings/Settings'));
/* eslint-enable no-inline-comments, capitalized-comments */

// Delay before actually restoring vertical scroll position (because we have to wait a little before everything is displayed)
const VERTICAL_POSITION_RESTORE_DELAY = 200;

// Vertical scroll position from which the header becomes opaque
const SCROLL_THRESHOLD = 400;

// Safari VOD error special workaround: initial backoff time (in s)
const SAFARI_VOD_WORKAROUND_MINIMUM_BACKOFF_TIME = 12;

// Safari VOD error special workaround: backoff increment between retries (in s)
const SAFARI_VOD_WORKAROUND_BACKOFF_TIME_STEP = 3;

// Safari VOD error special workaround: maximum backoff time before giving up (in s)
const SAFARI_VOD_WORKAROUND_MAXIMUM_BACKOFF_TIME = 32;

type ReduxMainLayoutDispatchToPropsType = {|
  +clearDisplayPaywallSubscription: BasicCallbackFunction,
  +localDeleteWishlist: () => Promise<any>,
  +localEnterTheaterMode: BasicCallbackFunction,
  +localExitTheaterMode: BasicCallbackFunction,
  +localHideModal: (shouldBeReopenedLater?: boolean) => void,
  +localReopenModalCard: BasicCallbackFunction,
  +localResetGridSectionId: BasicCallbackFunction,
  +localResetSectionPageIndices: () => void,
  +localSearch: (signal?: AbortSignal) => Promise<any>,
  +localSendV8AssetIdForDeeplinkRequest: (type: ItemType, deeplinkParameter: string, signal?: AbortSignal) => Promise<any>,
  +localSendV8CustomStrategyRequest: (urlDefinition: NETGEM_API_V8_URL_DEFINITION, item: NETGEM_API_V8_FEED_ITEM, signal?: AbortSignal) => Promise<any>,
  +localSendV8HubRequest: (hubKey: string, signal?: AbortSignal) => Promise<any>,
  +localSendV8LocationCatchupForAssetRequest: FeedRequestFunction,
  +localSendV8LocationEpgForAssetRequest: FeedRequestFunction,
  +localSendV8LocationVodForAssetRequest: FeedRequestFunction,
  +localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal) => Promise<any>,
  +localShowAvenueModal: (avenueData: AVENUE_DATA_MODAL_TYPE) => void,
  +localTogglePackPurchase: () => void,
  +localUpdateFocusedAvenue: (index: number, type: AvenueType, hubItem?: NETGEM_API_V8_FEED_ITEM, hub?: NETGEM_API_V8_HUB, searchString?: string) => void,
  +localUpdateSetting: (setting?: Setting, value: SettingValueType) => Promise<any>,
|};

type ReduxMainLayoutReducerStateType = {|
  +avenueList: NETGEM_API_V8_HUB | null,
  +deeplink: ?string,
  +defaultOnItemClick: ?NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  +displaySubscriptionPaywall: boolean,
  +focusedAvenue: FOCUSED_AVENUE_TYPE | null,
  +guruWebsiteUrl: string,
  +isDebugModeEnabled: boolean,
  +isInTheaterMode: boolean,
  +isNpvrFeatureEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +openModals: Array<ModalState>,
  +userInfo: ?UserInfo,
|};

type MainLayoutPropType = {||};

type CompleteMainLayoutPropType = {|
  ...MainLayoutPropType,
  ...ReduxMainLayoutDispatchToPropsType,
  ...ReduxMainLayoutReducerStateType,
|};

type MainLayoutStateType = {|
  child: Array<React.Node>,
  isHeaderLoaded: boolean,
  isScrolling: boolean,
  playerItem: EXTENDED_ITEM | null,
  playerOfflineContent: ShakaOfflineContent | null,
  previousVerticalPosition: {| key: string, scroll: number |} | null,
  safariWorkaroundBackoffTime: number,
  safariWorkaroundItem: EXTENDED_ITEM | null,
  safariWorkaroundProgress: number,
|};

const InitialState: MainLayoutStateType = Object.freeze({
  child: [],
  isHeaderLoaded: false,
  isScrolling: false,
  playerItem: null,
  playerOfflineContent: null,
  previousVerticalPosition: null,
  safariWorkaroundBackoffTime: SAFARI_VOD_WORKAROUND_MINIMUM_BACKOFF_TIME,
  safariWorkaroundItem: null,
  safariWorkaroundProgress: -1,
});

const LoadedHeaderState: MainLayoutStateType = {
  ...InitialState,
  isHeaderLoaded: true,
};

class MainLayoutView extends React.PureComponent<CompleteMainLayoutPropType, MainLayoutStateType> {
  abortController: AbortController;

  exploreAvenueIndex: number | null;

  isDeeplinkHandled: boolean;

  isSectionSliding: boolean;

  mainLayout: React.ElementRef<any>;

  safariWorkaroundTimer: TimeoutID | null;

  visibilityChangeEventName: ?string;

  constructor(props: CompleteMainLayoutPropType) {
    super(props);

    this.abortController = new AbortController();
    this.exploreAvenueIndex = null;
    this.isDeeplinkHandled = false;
    this.isSectionSliding = false;
    this.safariWorkaroundTimer = null;
    this.visibilityChangeEventName = null;

    this.state = { ...InitialState };
  }

  componentDidMount() {
    const { avenueList, focusedAvenue, localUpdateFocusedAvenue } = this.props;

    // Tell application container that app is loaded (used to prevent from showing loading animation again)
    Messenger.emit(MessengerEvents.APPLICATION_FULLY_LOADED);

    Messenger.on(MessengerEvents.AVENUE_RESET, this.moveToTop);
    Messenger.on(MessengerEvents.ITEM_CLICKED, this.executeItemClick);
    Messenger.on(MessengerEvents.MANAGE_SUPER_STREAM, this.manageSuperStream);
    Messenger.on(MessengerEvents.MOVE_TO_TOP, this.moveToTop);
    Messenger.on(MessengerEvents.OPEN_EXPLORE_MODAL, this.openExploreModal);
    Messenger.on(MessengerEvents.OPEN_GLOBAL_SETTINGS, this.openGlobalSettingsScreen);
    Messenger.on(MessengerEvents.OPEN_NPVR_MANAGEMENT_SCREEN, this.openNpvrManagementScreen);
    Messenger.on(MessengerEvents.OPEN_PLAYER, this.openPlayer);
    Messenger.on(MessengerEvents.OPEN_TILE_TYPES_AVENUE, this.openTileTypesAvenue);
    Messenger.on(MessengerEvents.OPEN_GRID_AVENUE, this.openGridAvenue);
    Messenger.on(MessengerEvents.RESTORE_POSITION, this.restorePosition);
    Messenger.on(MessengerEvents.SECTION_SLIDING_UPDATE, this.updateSectionSliding);

    if (typeof document.hidden !== 'undefined') {
      this.visibilityChangeEventName = 'visibilitychange';
      // $FlowFixMe
    } else if (typeof document.mozHidden !== 'undefined') {
      this.visibilityChangeEventName = 'mozvisibilitychange';
      // $FlowFixMe
    } else if (typeof document.msHidden !== 'undefined') {
      this.visibilityChangeEventName = 'msvisibilitychange';
      // $FlowFixMe
    } else if (typeof document.webkitHidden !== 'undefined') {
      this.visibilityChangeEventName = 'webkitvisibilitychange';
    }
    if (this.visibilityChangeEventName) {
      document.addEventListener(this.visibilityChangeEventName, this.checkVisibility, { passive: true });
    }

    this.setDebugFunctions();

    // Load focused avenue at startup (either main avenue if specified or first avenue otherwise)
    if (focusedAvenue) {
      const { index, searchString, type } = focusedAvenue;

      if (type === AvenueType.Search) {
        // Case when user signs in while search avenue is open
        if (searchString) {
          // Valid search string
          this.handleOnSearch(searchString);
        } else {
          // Display main avenue as a fallback
          const mainAvenue = getMainAvenue(avenueList);
          localUpdateFocusedAvenue(mainAvenue?.index ?? 0, mainAvenue?.type ?? AvenueType.Regular);
        }
      } else {
        // All other cases
        this.loadAvenue(index, type);
      }
    }
  }

  componentDidUpdate(prevProps: CompleteMainLayoutPropType) {
    const { focusedAvenue, isDebugModeEnabled, isInTheaterMode, localResetSectionPageIndices } = this.props;
    const { focusedAvenue: prevFocusedAvenue, isDebugModeEnabled: prevIsDebugModeEnabled, isInTheaterMode: prevIsInTheaterMode } = prevProps;

    if (isDebugModeEnabled !== prevIsDebugModeEnabled) {
      this.setDebugFunctions();
    }

    if (isInTheaterMode !== prevIsInTheaterMode) {
      if (!isInTheaterMode) {
        this.handleTheaterModeExited();
      }
    }

    if (focusedAvenue && prevFocusedAvenue && focusedAvenue !== prevFocusedAvenue) {
      const { hub, hubItem, index, type } = focusedAvenue;
      const { hub: prevHub, hubItem: prevHubItem, index: prevIndex } = prevFocusedAvenue;

      if ((index !== NO_AVENUE_INDEX || type === AvenueType.Search) && (index !== prevIndex || hubItem !== prevHubItem || hub !== prevHub)) {
        // Avenue change: load new avenue

        // Reset saved positions for all sections, except when the same universe avenue is reloaded (after exiting the player or when coming back from a hidden avenue, for instance)
        if ((index !== prevIndex || type === AvenueType.Explore) && (!hubItem || hubItem?.id !== prevHubItem?.id)) {
          localResetSectionPageIndices();
        }

        this.loadAvenue(index, type, typeof hub !== 'undefined' && type !== AvenueType.Explore && type !== AvenueType.Search, hubItem, hub);
      }
    }
  }

  componentWillUnmount() {
    const { abortController, visibilityChangeEventName } = this;

    this.resetSafariWorkaroundTimer();

    abortController.abort('Component MainLayout will unmount');

    Messenger.off(MessengerEvents.AVENUE_RESET, this.moveToTop);
    Messenger.off(MessengerEvents.ITEM_CLICKED, this.executeItemClick);
    Messenger.off(MessengerEvents.MANAGE_SUPER_STREAM, this.manageSuperStream);
    Messenger.off(MessengerEvents.MOVE_TO_TOP, this.moveToTop);
    Messenger.off(MessengerEvents.OPEN_EXPLORE_MODAL, this.openExploreModal);
    Messenger.off(MessengerEvents.OPEN_GLOBAL_SETTINGS, this.openGlobalSettingsScreen);
    Messenger.off(MessengerEvents.OPEN_NPVR_MANAGEMENT_SCREEN, this.openNpvrManagementScreen);
    Messenger.off(MessengerEvents.OPEN_PLAYER, this.openPlayer);
    Messenger.off(MessengerEvents.OPEN_TILE_TYPES_AVENUE, this.openTileTypesAvenue);
    Messenger.off(MessengerEvents.RESTORE_POSITION, this.restorePosition);
    Messenger.off(MessengerEvents.SECTION_SLIDING_UPDATE, this.updateSectionSliding);

    if (visibilityChangeEventName) {
      document.removeEventListener(visibilityChangeEventName, this.checkVisibility, { passive: true });
    }
  }

  // Debug functions only accessible through browser's console
  setDebugFunctions: () => void = () => {
    const { isDebugModeEnabled, localDeleteWishlist, localSendV8MetadataRequest, localTogglePackPurchase, localUpdateSetting } = this.props;

    if (isDebugModeEnabled) {
      showEE();
      logDebug('Debug mode ON');
      window.dbg = {
        changeLanguage: (l: string, callback?: BasicCallbackFunction) => i18next.changeLanguage(l, callback),
        checkVersion: () => Messenger.emit(MessengerEvents.CHECK_VERSION, true),
        deleteWhishlistFromCloud: localDeleteWishlist,
        emit: (eventName: string, ...args: Array<any>) => Messenger.emit(eventName, ...args),
        flushDataCollector: () => Messenger.emit(MessengerEvents.FLUSH_COLLECTOR),
        getAssetMetadata: (assetId: string) =>
          localSendV8MetadataRequest(assetId, assetId.startsWith('series://') ? METADATA_KIND_SERIES : METADATA_KIND_PROGRAM).then((response) => logInfo(response)),
        getLogLevel: () => log.getLevel(),
        notifyError: (msg, options) =>
          Messenger.emit(MessengerEvents.NOTIFY_ERROR, msg, {
            autoClose: false,
            ...options,
          }),
        notifyInfo: (msg, options) =>
          Messenger.emit(MessengerEvents.NOTIFY_INFO, msg, {
            autoClose: false,
            ...options,
          }),
        notifySuccess: (msg, options) =>
          Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, msg, {
            autoClose: false,
            ...options,
          }),
        notifyWarning: (msg, options) =>
          Messenger.emit(MessengerEvents.NOTIFY_WARNING, msg, {
            autoClose: false,
            ...options,
          }),
        now: (inMs: boolean) => (inMs ? AccurateTimestamp.now() : AccurateTimestamp.nowAsIsoString()),
        openNpvr: this.openNpvrManagementScreen,
        refreshAuthToken: () => Messenger.emit(MessengerEvents.REFRESH_AUTHENTICATION_TOKEN),
        refreshHub: () => Messenger.emit(MessengerEvents.REFRESH_HUB),
        refreshNpvr: this.refreshNpvr,
        setSetting: (name: string, value: SettingValueType) => (Setting.isValid(name) ? localUpdateSetting(Setting.cast(name), value) : logError(`Unknown setting name "${name}"`)),
        showChargebeeInfo: showChargebeeDebug,
        showFinishingLivePrograms: (itemCountPerPage?: number, maxTime?: number, maxItems?: number) =>
          Messenger.emit(MessengerEvents.DEBUG_SHOW_FINISHING_LIVE_PROGRAMS, itemCountPerPage, maxTime, maxItems),
        showHotKeys: HotKeys.listHotKeys,
        togglePackPurchase: localTogglePackPurchase,
      };
    } else {
      logInfo('Debug mode OFF');
      window.dbg = null;
    }
  };

  resetSafariWorkaroundTimer: () => void = () => {
    const { safariWorkaroundTimer } = this;

    if (safariWorkaroundTimer) {
      clearTimeout(safariWorkaroundTimer);
      this.safariWorkaroundTimer = null;
    }
  };

  handleOnLogoLoaded: () => void = () => {
    this.setState({ isHeaderLoaded: true });
  };

  checkVisibility: () => void = () => {
    const isHidden = isTabHidden();

    if (isHidden === null) {
      return;
    }

    if (isHidden) {
      Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_TRAILER_HIDDEN);
    } else {
      Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_TRAILER_VISIBLE);
    }
  };

  handleOnScroll: () => void = () => {
    const { isInTheaterMode } = this.props;
    const { mainLayout } = this;

    if (isInTheaterMode) {
      return;
    }

    this.setState({ isScrolling: mainLayout.scrollTop >= SCROLL_THRESHOLD });
  };

  enterTheaterMode: (item: EXTENDED_ITEM | ShakaOfflineContent) => void = (item) => {
    const { localEnterTheaterMode } = this.props;

    Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_TRAILER_HIDDEN);

    const extendedItem: EXTENDED_ITEM | null = item.type ? (item: EXTENDED_ITEM) : null;
    const offlineContent: ShakaOfflineContent | null = item.offlineUri ? (item: ShakaOfflineContent) : null;

    // Close any open modal but save it for reopening after player exited if it's a card
    this.closeModal(true);
    this.storePosition().then(() => {
      this.setState({ playerItem: extendedItem, playerOfflineContent: offlineContent });
      localEnterTheaterMode();
    });
  };

  exitTheaterMode: () => void = () => {
    const { localExitTheaterMode, localReopenModalCard } = this.props;
    const { playerItem } = this.state;

    this.setState({ playerItem: null, playerOfflineContent: null });
    localExitTheaterMode();

    // Check if player was opened from a card and if an expanded item index has been saved
    const data = LocalStorageManager.loadObject(StorageKeys.CardExpandedItemIndex, null);
    if (data && playerItem?.openFromCard) {
      data.playerExit = true;
      LocalStorageManager.save(StorageKeys.CardExpandedItemIndex, data);
    }

    // Reopen last modal card if any
    localReopenModalCard();
  };

  handleTheaterModeExited: () => void = () => {
    this.restorePosition();
    Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_TRAILER_VISIBLE);
  };

  moveToTop: (savePosition?: boolean) => void = (savePosition = false) => {
    const { mainLayout } = this;

    const promise = savePosition ? this.storePosition() : Promise.resolve();
    promise.then(() => (mainLayout.scrollTop = 0));
  };

  buildPreviousPositionKey: () => string = () => {
    const { focusedAvenue } = this.props;

    if (!focusedAvenue) {
      return '';
    }

    return `${focusedAvenue.index}-${focusedAvenue.hubItem?.id ?? 'na'}-${focusedAvenue.searchString ?? 'na'}`;
  };

  storePosition: () => Promise<void> = () => {
    const { mainLayout } = this;

    return new Promise((resolve) => {
      this.setState({ previousVerticalPosition: { key: this.buildPreviousPositionKey(), scroll: mainLayout.scrollTop } }, resolve);
    });
  };

  restorePosition: () => void = () => {
    const { previousVerticalPosition } = this.state;
    const { mainLayout } = this;

    if (previousVerticalPosition) {
      const { key, scroll } = previousVerticalPosition;
      // Check if it's the same avenue before restoring position
      if (key === this.buildPreviousPositionKey()) {
        // Wait a little bit before actually restoring position while everything loads up (layout shift, etc.)
        setTimeout(() => {
          mainLayout.scrollTop = scroll;
          this.setState({ previousVerticalPosition: null });
        }, VERTICAL_POSITION_RESTORE_DELAY);
        return;
      }
    }

    // In all other cases, move to top
    mainLayout.scrollTop = 0;
  };

  openCard: (fullItem: EXTENDED_ITEM, urlDefinition: ?NETGEM_API_V8_URL_DEFINITION, previousCard: ?CARD_DATA_MODAL_TYPE) => void = (fullItem, urlDefinition, previousCard) => {
    const { item, programMetadata, seriesMetadata, tileType } = fullItem;

    Messenger.emit(MessengerEvents.OPEN_CARD, {
      item,
      previousCard,
      programMetadata,
      seriesMetadata,
      tileType,
      urlDefinition,
    });
  };

  loadHubFromUrlDefinition: (item: NETGEM_API_V8_FEED_ITEM, urlDefinition: NETGEM_API_V8_URL_DEFINITION) => void = (item, urlDefinition) => {
    const { focusedAvenue, localSendV8CustomStrategyRequest, localUpdateFocusedAvenue } = this.props;
    const { previousVerticalPosition } = this.state;
    const {
      abortController: { signal },
      exploreAvenueIndex,
    } = this;

    // Reset avenue, then retrieve hub from platform
    this.setState({ ...LoadedHeaderState, previousVerticalPosition }, () => {
      localSendV8CustomStrategyRequest(urlDefinition, item, signal)
        .then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
          const hub: NETGEM_API_V8_HUB = getValidHub(response.result);
          // If explore avenue index is set, use it, else use index of currently focused index (should always be defined)
          const avenueIndex = exploreAvenueIndex ?? focusedAvenue?.index ?? NO_AVENUE_INDEX;
          const avenueType = exploreAvenueIndex ? AvenueType.Explore : (focusedAvenue?.type ?? AvenueType.Regular);
          localUpdateFocusedAvenue(avenueIndex, avenueType, item, hub);
        })
        .catch((error) => ignoreIfAborted(signal, error));
    });
  };

  openPlayer: (item: EXTENDED_ITEM | ShakaOfflineContent) => void = (item) => {
    this.enterTheaterMode(item);
  };

  executeItemClick: (fullItem: EXTENDED_ITEM) => void = (fullItem) => {
    const { defaultOnItemClick } = this.props;
    const { cardData, item, onItemClick } = fullItem;
    const localOnItemClick = onItemClick || defaultOnItemClick;

    if (!item || !localOnItemClick) {
      // No item (only for trailers but this code is not used for trailers) or no action defined
      return;
    }

    const { action } = localOnItemClick;

    if (action !== NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_SWITCH) {
      this.executeAction(fullItem, localOnItemClick, cardData);
      return;
    }

    // Action: switch
    const { params } = ((localOnItemClick: any): NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE2);

    if (!params) {
      return;
    }

    const {
      id,
      selectedLocation: { id: locationId },
      seriesId,
    } = item;

    for (const switchItem of params) {
      const { action: onItemClickSwitch, prefix } = switchItem;
      if (!prefix || id.startsWith(prefix) || seriesId?.startsWith(prefix) || locationId?.startsWith(prefix)) {
        const { action: switchAction } = onItemClickSwitch;
        if (switchAction === NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_SWITCH) {
          fullItem.onItemClick = onItemClickSwitch;
          this.executeItemClick(fullItem);
        } else {
          this.executeAction(fullItem, onItemClickSwitch, cardData);
        }
        return;
      }
    }
  };

  executeAction: (fullItem: EXTENDED_ITEM, onItemClickSwitch: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, cardData: ?CARD_DATA_MODAL_TYPE) => void = (fullItem, onItemClickSwitch, cardData) => {
    const { action } = onItemClickSwitch;

    if (action === NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_CARD) {
      // Open card
      const { params } = ((onItemClickSwitch: any): NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE1);
      this.openCard(fullItem, params, cardData);
    } else if (action === NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_HUB) {
      // Open hub (channel, channel group, universe)
      const { params } = ((onItemClickSwitch: any): NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE1);
      const { item } = fullItem;
      this.storePosition().then(() => this.loadHubFromUrlDefinition(item, params));
    } else if (action === NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_PLAYER) {
      // Open player
      this.openPlayer(fullItem);
    }
  };

  openExploreModal: (exploreAvenueIndex: number) => void = (exploreAvenueIndex) => {
    this.loadAvenue(exploreAvenueIndex, AvenueType.Explore);
  };

  closeModal: (shouldBeReopenedLater?: boolean) => void = (shouldBeReopenedLater) => {
    const { localHideModal, openModals } = this.props;

    if (openModals.length === 0) {
      // No card open
      return;
    }

    localHideModal(shouldBeReopenedLater);
  };

  playerCloseCallback: (retry: boolean) => void = (retry) => {
    if (retry) {
      // Player crashed due to a Safari EME issue: wait a bit then retry
      const { safariWorkaroundBackoffTime, playerItem } = this.state;

      if (safariWorkaroundBackoffTime <= SAFARI_VOD_WORKAROUND_MAXIMUM_BACKOFF_TIME) {
        // Reset player item to close player, then schedule a retry
        this.setState(
          {
            playerItem: null,
            safariWorkaroundItem: playerItem,
            safariWorkaroundProgress: 0,
          },
          this.scheduleSafariWorkaroundProgress,
        );
        return;
      }
    } else {
      // Reset Safari workaround
      this.setState({
        safariWorkaroundBackoffTime: SAFARI_VOD_WORKAROUND_MINIMUM_BACKOFF_TIME,
        safariWorkaroundProgress: -1,
      });
    }

    // Player regularly exited
    this.exitTheaterMode();
    this.refreshNpvr();
  };

  scheduleSafariWorkaroundProgress: () => void = () => {
    const { safariWorkaroundBackoffTime, safariWorkaroundProgress } = this.state;

    if (safariWorkaroundProgress >= safariWorkaroundBackoffTime) {
      // Delay reached
      return;
    }

    // Schedule next tick
    this.safariWorkaroundTimer = setTimeout(() => {
      const { safariWorkaroundProgress: progress } = this.state;

      if (progress > -1 && progress < safariWorkaroundBackoffTime) {
        // Schedule next tick
        this.setState({ safariWorkaroundProgress: progress + 1 }, this.scheduleSafariWorkaroundProgress);
      }
    }, ONE_THOUSAND);
  };

  handleOnSafariWorkaroundCompleted: () => void = () => {
    // Wait is over: winter is here
    const { safariWorkaroundBackoffTime, safariWorkaroundItem } = this.state;

    this.resetSafariWorkaroundTimer();

    // Set player item to open player again and increment backoff time for next retry (if needed)
    this.setState({
      playerItem: safariWorkaroundItem,
      safariWorkaroundBackoffTime: safariWorkaroundBackoffTime + SAFARI_VOD_WORKAROUND_BACKOFF_TIME_STEP,
      safariWorkaroundItem: null,
      safariWorkaroundProgress: -1,
    });
  };

  refreshNpvr: () => void = () => {
    Messenger.emit(MessengerEvents.REFRESH_NPVR);
  };

  loadAvenueFromId: (avenueId: string) => boolean = (avenueId) => {
    const { avenueList, localUpdateFocusedAvenue } = this.props;

    const avenue = getAvenueFromId(avenueList, avenueId);

    if (!avenue) {
      return false;
    }

    const { index, type } = avenue;
    localUpdateFocusedAvenue(index, type);
    this.loadAvenue(index, type);

    return true;
  };

  loadAvenue: (index: number, type: AvenueType, hasBackButton?: boolean, hubItem?: NETGEM_API_V8_FEED_ITEM, hub?: NETGEM_API_V8_HUB) => void = (index, type, hasBackButton, hubItem, hub) => {
    const { clearDisplayPaywallSubscription, focusedAvenue, displaySubscriptionPaywall, isRegisteredAsGuest, localResetGridSectionId, localShowAvenueModal } = this.props;
    const { isDeeplinkHandled } = this;

    // Handle deeplink
    if (!isDeeplinkHandled && this.checkDeeplink()) {
      return;
    }

    let localHub = hub;
    let localHubItem = hubItem;

    if (type === AvenueType.Explore && !hub && focusedAvenue && hasPendingOperation()) {
      // Coming back to a hidden avenue after having stored a pending operation: let's use previous hub and hubItem
      ({ hub: localHub, hubItem: localHubItem } = focusedAvenue);
    }

    if (type === AvenueType.Explore && !localHub) {
      // Display EXPLORE avenue in popup
      this.exploreAvenueIndex = index;
      Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, () => (this.exploreAvenueIndex = null));

      localShowAvenueModal({
        hasBackButton,
        hub: localHub,
        hubItem: localHubItem,
        index,
        isInExploreModal: type === AvenueType.Explore && !localHub,
        type,
      });
    } else {
      // Display selected avenue in regular avenue container
      localResetGridSectionId();
      this.closeModal();
      this.exploreAvenueIndex = null;

      if (hasBackButton) {
        // Going to hidden avenue
        this.moveToTop();
      } else {
        // Coming back from hidden avenue
        this.restorePosition();
      }

      const view = (
        <AvenueView hasBackButton={hasBackButton} hub={localHub} hubItem={localHubItem} index={index} isInExploreModal={type === AvenueType.Explore && !localHub} key='avenue' type={type} />
      );

      this.setState({ child: [view] }, () => {
        if (displaySubscriptionPaywall) {
          // Subscription paywall should be displayed ("?subscribe" in URL)
          clearDisplayPaywallSubscription();
          Messenger.emit(MessengerEvents.SHOW_SUBSCRIBE);
        } else if (!isRegisteredAsGuest) {
          Messenger.emit(MessengerEvents.CHECK_PENDING_OPERATION);
        }
      });
    }
  };

  checkDeeplink: () => boolean = () => {
    const { deeplink } = this.props;

    this.isDeeplinkHandled = true;

    if (!deeplink) {
      return false;
    }

    const { searchParams } = new URL(deeplink);

    for (const [parameter, value] of searchParams.entries()) {
      const decodedValue = value ? decodeURIComponent(value) : null;

      switch (parameter) {
        case 'npvr':
          this.openNpvrManagementScreen();
          return true;

        case 'settings':
          this.openGlobalSettingsScreen();
          return true;

        case 'avenue':
          if (decodedValue) {
            return this.loadAvenueFromId(decodedValue);
          }
          return false;

        case 'search':
          if (decodedValue) {
            this.handleOnSearch(decodedValue);
            return true;
          }
          return false;

        case 'program':
          // VOD or TV (scheduled event or catchup)
          if (decodedValue) {
            this.openDeeplinkCard(ItemType.Program, decodedValue);
          }
          return false;

        case 'series':
          // VOD or TV (scheduled event or catchup)
          if (decodedValue) {
            this.openDeeplinkCard(ItemType.Series, decodedValue);
          }
          return false;

        default:
      }
    }

    return false;
  };

  getMatchingRequest: (kind: NETGEM_API_V8_ITEM_LOCATION_TYPE) => FeedRequestFunction = (kind) => {
    const { localSendV8LocationCatchupForAssetRequest, localSendV8LocationEpgForAssetRequest, localSendV8LocationVodForAssetRequest } = this.props;

    switch (kind) {
      case NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT:
        return localSendV8LocationEpgForAssetRequest;
      case NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP:
        return localSendV8LocationCatchupForAssetRequest;
      default:
        return localSendV8LocationVodForAssetRequest;
    }
  };

  getItem: (results: AllSettledPromises) => NETGEM_API_V8_FEED_ITEM | null = (results) => {
    for (const { status, value } of results) {
      if (status === SettledPromiseFulfilled && value) {
        const {
          result: {
            feed: [rawItem],
          },
        } = value;

        if (rawItem) {
          const item = buildFeedItem({
            ...rawItem,
            score: [0],
          });

          if (item) {
            return item;
          }
        }
      }
    }

    return null;
  };

  openDeeplinkCard: (type: ItemType, deeplinkParameter: string) => void = (type, deeplinkParameter) => {
    const { localSendV8AssetIdForDeeplinkRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    const token = signal;

    localSendV8AssetIdForDeeplinkRequest(type, deeplinkParameter, token)
      .then(({ result: { id, kind } }) => {
        const localKinds = Array.isArray(kind) && kind.length > 0 ? kind : [NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD];

        const promises = localKinds.map((k) => {
          const request = this.getMatchingRequest(k);
          return request(id, AccurateTimestamp.now(), MILLISECONDS_PER_WEEK, undefined, token);
        });

        return Promise.allSettled(promises).then((results: AllSettledPromises) => {
          const item = this.getItem(results);

          if (item) {
            // Prop "type" is mandatory, although it's not used when opening a card
            this.executeItemClick({ item, type: ExtendedItemType.VOD });
          }
        });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  openGlobalSettingsScreen: () => void = () => {
    const { localUpdateFocusedAvenue } = this.props;

    this.closeModal();
    localUpdateFocusedAvenue(NO_AVENUE_INDEX, AvenueType.Other);
    this.moveToTop();

    this.setState({
      child: [
        <ErrorBoundary componentName='Settings' isNotificationEnabled key='globalSettings'>
          <React.Suspense fallback={null}>
            <Settings key='globalSettings' />
          </React.Suspense>
        </ErrorBoundary>,
      ],
    });
  };

  openNpvrManagementScreen: (scheduledRecordingId: ?string, failedRecordingId: ?string, warningScheduledEventId: ?string) => void = (
    scheduledRecordingId,
    failedRecordingId,
    warningScheduledEventId,
  ) => {
    const { isNpvrFeatureEnabled, localUpdateFocusedAvenue } = this.props;

    if (!isNpvrFeatureEnabled) {
      return;
    }

    this.closeModal();
    localUpdateFocusedAvenue(NO_AVENUE_INDEX, AvenueType.Other);

    this.setState({
      child: [
        <ErrorBoundary componentName='Recordings' isNotificationEnabled key='npvrQuota'>
          <React.Suspense fallback={null}>
            <NpvrManagement focusedExistingRecordingId={failedRecordingId} focusedFutureRecordingId={warningScheduledEventId} focusedScheduledRecordingId={scheduledRecordingId} key='npvrQuota' />
          </React.Suspense>
        </ErrorBoundary>,
      ],
    });
  };

  openTileTypesAvenue: (avenueKey: string) => void = (avenueKey) => {
    const { localSendV8HubRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8HubRequest(avenueKey, signal).then((hub) => {
      this.loadAvenue(NO_AVENUE_INDEX, AvenueType.Other, false, undefined, hub.slice(0));
    });
  };

  openGridAvenue: (avenueKey: string) => void = (avenueKey) => {
    const { localSendV8HubRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8HubRequest(avenueKey, signal).then((hub) => {
      this.loadAvenue(NO_AVENUE_INDEX, AvenueType.Other, false, undefined, hub.slice(1));
    });
  };

  manageSuperStream: () => void = () => {
    const { guruWebsiteUrl, userInfo } = this.props;

    if (process.env.REACT_APP_ID === APPLICATION_ID.MyVideofutur || hasPods(userInfo)) {
      // Open Guru website
      if (guruWebsiteUrl) {
        openFooterPage(guruWebsiteUrl, 'wifiSuperStream');
      } else {
        logWarning('Missing Guru website URL');
      }
    } else {
      Messenger.emit(MessengerEvents.SHOW_PODS);
    }
  };

  handleOnSearch: (searchString: string) => void = (searchString) => {
    const { localSearch, localUpdateFocusedAvenue } = this.props;
    const { previousVerticalPosition } = this.state;
    const {
      abortController: { signal },
    } = this;

    // Reset avenue, then retrieve hub from platform
    this.setState({ ...LoadedHeaderState, previousVerticalPosition }, () => {
      localSearch(signal)
        .then((hub: NETGEM_API_V8_HUB) => {
          saveSearchString(searchString);
          localUpdateFocusedAvenue(NO_AVENUE_INDEX, AvenueType.Search, undefined, hub, searchString);
        })
        .catch((error) => ignoreIfAborted(signal, error));
    });
  };

  updateSectionSliding: (isSliding: boolean) => void = (isSliding) => {
    this.isSectionSliding = isSliding;
  };

  handleOnDoubleClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void = (event) => {
    const { isSectionSliding } = this;

    if (isSectionSliding) {
      return;
    }

    const { nativeEvent } = event;
    const [, elt] = nativeEvent.composedPath();

    if (!(elt instanceof HTMLElement)) {
      return;
    }

    const { classList, tagName } = elt;

    if (tagName.toUpperCase() === 'DIV' && classList.contains('sectionCarousel')) {
      // Notify video carousel to go fullscreen
      Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_TOGGLE_FULLSCREEN);
    }
  };

  renderWorkingZoneContent: () => React.Node = () => {
    const { isInTheaterMode } = this.props;
    const { child } = this.state;

    if (isInTheaterMode) {
      return null;
    }

    return (
      <>
        <ImageCarousel />
        <VideoCarousel />
        {child.length > 0 ? child : null}
      </>
    );
  };

  renderSafariWorkaroundLoader: () => React.Node = () => {
    const { safariWorkaroundBackoffTime, safariWorkaroundProgress } = this.state;

    if (safariWorkaroundProgress === -1) {
      // Nothing in progress
      return null;
    }

    // Display loader
    return <CircleLoader hidePercentage onAnimationCompleted={this.handleOnSafariWorkaroundCompleted} progress={getIntegerPercentage(safariWorkaroundProgress, 0, safariWorkaroundBackoffTime)} />;
  };

  render(): React.Node {
    const { isInTheaterMode } = this.props;
    const { isHeaderLoaded, isScrolling, playerItem, playerOfflineContent } = this.state;

    return (
      <>
        <div
          className={clsx('mainLayout', isInTheaterMode && 'theaterMode')}
          onScroll={this.handleOnScroll}
          ref={(instance) => {
            this.mainLayout = instance;
          }}
        >
          <div className={clsx('workingZone', isInTheaterMode && 'theaterMode')} onDoubleClick={this.handleOnDoubleClick}>
            {this.renderWorkingZoneContent()}
            <Footer isVisible={isHeaderLoaded} />
          </div>
          <Header isScrolling={isScrolling} onLogoLoaded={this.handleOnLogoLoaded} onSearch={this.handleOnSearch} />
          {this.renderSafariWorkaroundLoader()}
        </div>
        {playerItem || playerOfflineContent ? <Player closeCallback={this.playerCloseCallback} playerItem={playerItem} playerOfflineContent={playerOfflineContent} /> : null}
      </>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxMainLayoutReducerStateType => {
  return {
    avenueList: state.ui.avenueList,
    deeplink: state.ui.deeplink,
    defaultOnItemClick: state.ui.defaultActions ? state.ui.defaultActions.onItemClick : null,
    displaySubscriptionPaywall: state.ui.displaySubscriptionPaywall,
    focusedAvenue: state.ui.focusedAvenue,
    guruWebsiteUrl: state.appConfiguration.configuration.guruWebsiteUrl ?? '',
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isInTheaterMode: state.ui.isInTheaterMode,
    isNpvrFeatureEnabled: state.appConfiguration.features[FEATURE_NPVR],
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    openModals: state.modal.openModals,
    userInfo: state.appRegistration.userInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxMainLayoutDispatchToPropsType => {
  return {
    clearDisplayPaywallSubscription: () => dispatch(updateDisplayPaywallSubscription(false)),

    localDeleteWishlist: (): Promise<any> => dispatch(deleteWishlist()),

    localEnterTheaterMode: () => dispatch(enterTheaterMode()),

    localExitTheaterMode: () => dispatch(exitTheaterMode()),

    localHideModal: (shouldBeReopenedLater?: boolean) => dispatch(hideModal(shouldBeReopenedLater)),

    localReopenModalCard: () => dispatch(reopenModalCard()),

    localResetGridSectionId: (): void => dispatch(resetGridSectionId()),

    localResetSectionPageIndices: (): void => dispatch(resetSectionPageIndices()),

    localSearch: (signal?: AbortSignal) => dispatch(search(signal)),

    localSendV8AssetIdForDeeplinkRequest: (type: ItemType, deeplinkParameter: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8AssetIdForDeeplinkRequest(type, deeplinkParameter, signal)),

    localSendV8CustomStrategyRequest: (urlDefinition: NETGEM_API_V8_URL_DEFINITION, item: NETGEM_API_V8_FEED_ITEM, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8CustomStrategyRequest(urlDefinition, item, signal)),

    localSendV8HubRequest: (hubKey: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8HubRequest(hubKey, signal)),

    localSendV8LocationCatchupForAssetRequest: (assetId: string, startDate: number, range: number, channelIds?: Array<string>, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationCatchupForAssetRequest(assetId, startDate, range, channelIds, signal)),

    localSendV8LocationEpgForAssetRequest: (assetId: string, startDate: number, range: number, channelIds?: Array<string>, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationEpgForAssetRequest(assetId, startDate, range, channelIds, signal)),

    localSendV8LocationVodForAssetRequest: (assetId: string, startDate: number, range: number, channelIds?: Array<string>, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationVodForAssetRequest(assetId, startDate, range, channelIds, signal)),

    localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataRequest(assetId, type, signal)),

    localShowAvenueModal: (avenueData: AVENUE_DATA_MODAL_TYPE) => dispatch(showAvenueModal(avenueData)),

    localTogglePackPurchase: () => dispatch(togglePackPurchase()),

    localUpdateFocusedAvenue: (index: number, type: AvenueType, hubItem?: NETGEM_API_V8_FEED_ITEM, hub?: NETGEM_API_V8_HUB, searchString?: string) =>
      dispatch(updateFocusedAvenue(index, type, hubItem, hub, searchString)),

    localUpdateSetting: (setting?: Setting, value: SettingValueType) => dispatch(updateSetting(setting, value)),
  };
};

const MainLayout: React.ComponentType<MainLayoutPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MainLayoutView);

export default MainLayout;
