/* @flow */

import './ItemDecoration.scss';
import * as React from 'react';
import {
  ItemContent,
  ItemType,
  type NETGEM_API_V8_FEED_ITEM,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { PictoClock, PictoSeries } from '@ntg/components/dist/pictos/Element';
import {
  getRecordingOutcomes,
  renderChannelImage,
  renderDebugInfoElement,
  renderLiveRecordingIcon,
  renderRecordingIcon,
  renderScheduledRecordingIcon,
  renderWatchingStatus,
} from './ItemDecorationHelper';
import { useCallback, useMemo } from 'react';
import { BroadcastStatus } from '../../../helpers/ui/location/Format';
import type { NETGEM_API_V8_METADATA_PROGRAM } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import ProgressBar from '../../progressBar/ProgressBar';
import { RecordingOutcome } from '../../../libs/netgemLibrary/v8/types/Npvr';
import { TileOnFocus } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import clsx from 'clsx';
import { getChannelNumber } from '../../../helpers/channel/helper';
import { hasRunningSeriesScheduledRecording } from '../../../helpers/npvr/scheduledRecording';
import { useSelector } from 'react-redux';

type ItemDecorationPropType = {|
  +broadcastStatus: BroadcastStatus,
  +channelImageUrl: string | null,
  +contentType: ItemContent,
  +isDebugModePlusEnabled: boolean,
  +isInLiveSection?: boolean, // eslint-disable-line react/require-default-props
  +item: NETGEM_API_V8_FEED_ITEM,
  +now: number,
  +onFocus: TileOnFocus,
  +previewCatchupScheduledEventId: ?string,
  +programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  +tvLocationMetadata: NETGEM_API_V8_METADATA_SCHEDULE | null,
  +vodLocationsMetadata: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null,
  +watchingStatus: number | null,
|};

const ItemDecoration = ({
  broadcastStatus,
  channelImageUrl,
  contentType,
  isDebugModePlusEnabled,
  isInLiveSection = false,
  item,
  now,
  onFocus,
  previewCatchupScheduledEventId,
  programMetadata,
  tvLocationMetadata,
  vodLocationsMetadata,
  watchingStatus,
}: ItemDecorationPropType): React.Node => {
  const npvrScheduledRecordingsList = useSelector((state) => state.npvr.npvrScheduledRecordingsList);
  const npvrRecordingsFuture = useSelector((state) => state.npvr.npvrRecordingsFuture);
  const npvrRecordingsList = useSelector((state) => state.npvr.npvrRecordingsList);
  const channels = useSelector((state) => state.appConfiguration.deviceChannels);

  if (onFocus === TileOnFocus.Selection) {
    return null;
  }

  const {
    selectedLocation: { channelId },
  } = item;
  const channelNumber = useMemo(() => getChannelNumber(channels, channelId), [channels, item]);

  const renderRegularTileDecoration = useCallback((): React.Node => {
    const {
      locType,
      selectedLocation: { id: scheduledEventId, recordOutcome },
      seriesId,
      type: itemType,
    } = item;

    const localScheduledEventId = previewCatchupScheduledEventId || scheduledEventId;

    if (!localScheduledEventId) {
      return null;
    }

    const hasSeriesScheduledRecording = hasRunningSeriesScheduledRecording(seriesId ?? undefined, npvrScheduledRecordingsList);

    /*
     * In case of a live item, a single scheduled recording should override any series scheduled recording
     * Special case where user started a single recording on a live item, then started a series recording
     */
    const singleScheduledRecording = !hasSeriesScheduledRecording || broadcastStatus === BroadcastStatus.Live ? npvrScheduledRecordingsList[localScheduledEventId] : undefined;

    const hasSingleScheduledRecording =
      // Item is a recording
      (!hasSeriesScheduledRecording && locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) ||
      // Item is a scheduled event or a preview, and a scheduled recording os type Single exists
      ((locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT || broadcastStatus === BroadcastStatus.Preview) && typeof singleScheduledRecording !== 'undefined');

    const seriesScheduledRecording = seriesId ? npvrScheduledRecordingsList[seriesId] : null;
    const recordingOutcomes = getRecordingOutcomes(npvrRecordingsList, npvrRecordingsFuture, seriesScheduledRecording, singleScheduledRecording, item, localScheduledEventId, tvLocationMetadata);
    const isRecordOutcomeOK = recordingOutcomes.some((outcome) => outcome === RecordingOutcome.Recorded);

    const seriesIconElement =
      itemType === ItemType.Series && (contentType === ItemContent.NetgemSVOD || contentType === ItemContent.TV) ? <PictoSeries className='series' hasHoverEffect={false} /> : null;

    const previewIconElement = broadcastStatus === BroadcastStatus.Preview ? <PictoClock className='preview' hasBackground hasHoverEffect={false} /> : null;

    const liveRecordingIconElement = renderLiveRecordingIcon(locType, broadcastStatus, isRecordOutcomeOK);

    const recordingIconElement = liveRecordingIconElement ? null : renderRecordingIcon(locType, recordOutcome);

    const scheduledRecordingIconElement =
      recordingIconElement || liveRecordingIconElement ? null : renderScheduledRecordingIcon(broadcastStatus, hasSeriesScheduledRecording, hasSingleScheduledRecording);

    const futureIconElement = broadcastStatus === BroadcastStatus.Future && !scheduledRecordingIconElement ? <PictoClock className='future' hasBackground hasHoverEffect={false} /> : null;

    return (
      <>
        {futureIconElement}
        {previewIconElement}
        {seriesIconElement}
        {recordingIconElement}
        {liveRecordingIconElement}
        {scheduledRecordingIconElement}
        {renderChannelImage(channelImageUrl)}
        {renderWatchingStatus(itemType, contentType, vodLocationsMetadata, watchingStatus)}
        <ProgressBar broadcastStatus={broadcastStatus} item={item} now={now} />
        {renderDebugInfoElement(item, hasSingleScheduledRecording, hasSeriesScheduledRecording, recordingOutcomes, channelNumber, isDebugModePlusEnabled)}
      </>
    );
  }, [
    broadcastStatus,
    channelImageUrl,
    channelNumber,
    contentType,
    isDebugModePlusEnabled,
    item,
    now,
    npvrRecordingsFuture,
    npvrRecordingsList,
    npvrScheduledRecordingsList,
    previewCatchupScheduledEventId,
    tvLocationMetadata,
    vodLocationsMetadata,
    watchingStatus,
  ]);

  // Second case is a live program without info
  return <div className={clsx('decoration', channelImageUrl && 'tv')}>{programMetadata || !isInLiveSection ? renderRegularTileDecoration() : renderChannelImage(channelImageUrl)}</div>;
};

export default ItemDecoration;
