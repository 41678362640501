/* @flow */

import './Section.css';
import * as React from 'react';
import { type AllSettledPromises, SettledPromiseFulfilled } from '../../../helpers/jsHelpers/promise';
import { CAROUSEL_BACKGROUND_IMAGE_HEIGHT, CAROUSEL_BACKGROUND_IMAGE_WIDTH, EPG, HUB_IMAGE_HEIGHT, HUB_IMAGE_WIDTH, VOD_TILE_HEIGHT, VOD_TILE_WIDTH } from '../../../helpers/ui/constants';
import {
  CarouselSectionType,
  type CompleteSectionPropType,
  type DefaultProps,
  IMAGE_MAX_ITEMS,
  ITEM_CHANGE_TIMEOUT,
  type ReduxSectionDispatchToPropsType,
  type ReduxSectionReducerStateType,
  type SectionPropType,
  type SectionStateType,
  UI_HIDE_TIMEOUT,
  VIDEO_MAX_ITEMS,
} from './SectionConstsAndTypes';
import { IMAGE_TAG_NO_TEXT, findLandscapeImageId } from '../../../helpers/ui/metadata/image';
import { LEFT, RIGHT } from 'react-swipeable';
import {
  METADATA_KIND_PROGRAM,
  METADATA_KIND_SERIES,
  type MetadataKind,
  type NETGEM_API_V8_METADATA,
  type NETGEM_API_V8_METADATA_PROGRAM,
  type NETGEM_API_V8_METADATA_SERIES,
} from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { PictoArrowLeft, PictoArrowRight, PictoFullscreen, PictoPause, PictoPlay } from '@ntg/components/dist/pictos/Element';
import { SectionDisplayType, SectionType, type SwipeEventData } from '../../../helpers/ui/section/types';
import { TIPPY_DEFAULT_OPTIONS, type TippyOptionsType } from '@ntg/ui/dist/tooltip';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import { logDebug, showDebug } from '../../../helpers/debug/debug';
import { updateVideoCarouselMuted, updateVideoCarouselPaused, updateVideoCarouselPlaying, updateVideoCarouselUnmuted } from '../../../redux/ui/actions';
import type { CombinedReducers } from '../../../redux/reducers';
import type { Dispatch } from '../../../redux/types/types';
import EpgManager from '../../../helpers/epg/epgManager';
import Equalizer from '../../equalizer/Equalizer';
import { FAKE_EPG_LIVE_ITEM_TYPE } from '../../../helpers/ui/item/types';
import { FeedProviderKind } from '../../../libs/netgemLibrary/v8/types/Feed';
import HotKeys from '../../../helpers/hotKeys/hotKeys';
import type { ItemData } from './Types';
import ItemIndex from './ItemIndex';
import ItemSlide from './Slide';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_FEED } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../libs/netgemLibrary/v8/types/RequestResponse';
import { type NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import Swipeable from '../../swipeable/swipeable';
import { TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import Tippy from '@tippyjs/react';
import type { Undefined } from '@ntg/utils/dist/types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { dailyShuffleArray } from '../../../helpers/maths/maths';
import { getImageUrl } from '../../../redux/netgemApi/actions/v8/metadataImage';
import { getSectionChannels } from '../../../helpers/channel/helper';
import { getTileConfig } from '../../../helpers/ui/section/tile';
import { getTrailer } from '../../../helpers/videofutur/metadata';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';
import { getValuesFromQueryString } from '../section/helper';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isVodItem } from '../../../helpers/ui/item/metadata';
import { produce } from 'immer';
import sendV8MetadataRequest from '../../../redux/netgemApi/actions/v8/metadata';
import sendV8SectionFeedRequest from '../../../redux/netgemApi/actions/v8/feed';

const InitialState: SectionStateType = Object.freeze({
  currentIndex: -1,
  displayType: SectionDisplayType.Regular,
  feed: [],
  hubImageUrl: null,
  imageUrlList: [],
  isCollapsed: false,
  isUIHidden: false,
  itemDataList: [],
  selectedItemIndices: [],
  trailerUrlList: [],
});

class SectionCarouselView extends React.PureComponent<CompleteSectionPropType, SectionStateType> {
  abortController: AbortController;

  carouselTimerId: TimeoutID | null;

  isMouseOverSection: boolean;

  isVideo: boolean;

  liveFeedTimer: TimeoutID | null;

  observer: ?IntersectionObserver;

  sectionChannels: Set<string> | null;

  sectionElement: HTMLElement | null;

  sectionTitle: ?string;

  sectionType: ?CarouselSectionType;

  tileType: TileConfigTileType;

  uiHideTimerId: TimeoutID | null;

  wasTrailerPlaying: boolean;

  static defaultProps: DefaultProps = {
    onItemClick: undefined,
    onUIHiddenCallback: undefined,
    onUIShownCallback: undefined,
  };

  constructor(props: CompleteSectionPropType) {
    super(props);

    const { section } = props;
    const { type } = getTileConfig(section);

    this.abortController = new AbortController();
    this.carouselTimerId = null;
    this.isMouseOverSection = false;
    this.isVideo = false;
    this.liveFeedTimer = null;
    this.observer = null;
    this.sectionChannels = null;
    this.sectionElement = null;
    this.tileType = type;
    this.uiHideTimerId = null;
    this.wasTrailerPlaying = false;

    const { gridSectionId } = props;
    const isCollapsed = gridSectionId !== null;

    this.state = {
      ...InitialState,
      displayType: isCollapsed ? SectionDisplayType.Collapsed : SectionDisplayType.Regular,
      isCollapsed,
    };
  }

  componentDidMount() {
    const { section } = this.props;

    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_ENDED, this.handleTrailerEnded);
    Messenger.on(MessengerEvents.AVENUE_RESET, this.reset);

    this.resetBackgroundCarousel();

    this.sectionTitle = getTranslatedText(section.title, Localizer.language);
    this.loadData();
    this.loadHubImage();
  }

  componentDidUpdate(prevProps: CompleteSectionPropType, prevState: SectionStateType) {
    const {
      avenueIndex,
      dataLoadedCallback,
      gridSectionId,
      isInTheaterMode,
      section: { id },
      sectionIndex,
    } = this.props;
    const { feed, imageUrlList, isCollapsed, selectedItemIndices, trailerUrlList } = this.state;
    const { gridSectionId: prevGridSectionId, isInTheaterMode: prevIsInTheaterMode } = prevProps;
    const { feed: prevFeed, imageUrlList: prevImageUrlList, isCollapsed: prevIsCollapsed, selectedItemIndices: prevSelectedItemIndices, trailerUrlList: prevTrailerUrlList } = prevState;

    if (imageUrlList !== prevImageUrlList) {
      Messenger.emit(MessengerEvents.IMAGE_CAROUSEL_SET, imageUrlList);
      this.startCarousel();
    }

    if (trailerUrlList !== prevTrailerUrlList) {
      Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_SET, trailerUrlList);
      this.startCarousel();
    }

    if (gridSectionId !== prevGridSectionId) {
      this.updateIsCollapsed();
    }

    if (isInTheaterMode !== prevIsInTheaterMode) {
      if (isInTheaterMode) {
        this.disabledHotKeys();
      } else {
        this.enabledHotKeys();
      }
    }

    if (isCollapsed !== prevIsCollapsed) {
      if (isCollapsed) {
        this.collapse();
      } else if (feed.length > 0) {
        this.show();
      }
    }

    if (selectedItemIndices !== prevSelectedItemIndices && selectedItemIndices.length === 0) {
      // Carousel is empty
      this.collapse();
      dataLoadedCallback(id, sectionIndex, avenueIndex, 0);
    }

    if (prevFeed === feed) {
      return;
    }

    if (feed.length > 0) {
      this.initializeItemList();
      if (gridSectionId === null) {
        this.show();
      }
    } else {
      // Carousel is empty
      this.collapse();
      dataLoadedCallback(id, sectionIndex, avenueIndex, 0);
    }
  }

  componentWillUnmount() {
    const { abortController, isVideo, liveFeedTimer, observer, sectionElement } = this;

    abortController.abort('Component SectionCarousel will unmount');

    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_ENDED, this.handleTrailerEnded);
    Messenger.off(MessengerEvents.AVENUE_RESET, this.reset);

    // Clear timer
    if (liveFeedTimer) {
      clearTimeout(liveFeedTimer);
    }

    if (observer && sectionElement) {
      observer.unobserve(sectionElement);
    }

    this.resetCarouselTimer();
    this.resetBackgroundCarousel();

    if (isVideo) {
      this.disabledHotKeys();
    }
  }

  updateIsCollapsed: () => void = () => {
    const { gridSectionId } = this.props;
    const { selectedItemIndices } = this.state;

    this.setState({ isCollapsed: gridSectionId !== null || selectedItemIndices.length === 0 });
  };

  enabledHotKeys: () => void = () => {
    HotKeys.register('f', this.handleFullscreenHotKey, { name: 'VideoCarouselSection.fullscreen' });
    HotKeys.register('m', this.handleMuteHotKey, { name: 'VideoCarouselSection.mute' });
    HotKeys.register('space', this.handlePlayHotKey, { name: 'VideoCarouselSection.play' });
  };

  disabledHotKeys: () => void = () => {
    HotKeys.unregister('f', this.handleFullscreenHotKey);
    HotKeys.unregister('m', this.handleMuteHotKey);
    HotKeys.unregister('space', this.handlePlayHotKey);
  };

  handleDebugOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void = (event) => {
    if (event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();

      const { props, state } = this;

      showDebug('Carousel Section', {
        instance: this,
        instanceFields: ['abortController', 'carouselTimerId', 'isMouseOverSection', 'isVideo', 'sectionTitle', 'sectionType', 'uiHideTimerId', 'wasTrailerPlaying'],
        props,
        propsFields: ['avenueImageUri', 'avenueIndex', 'gridSectionId', 'hubItem', 'isHubItemVisible', 'section', 'sectionIndex', 'videoCarousel'],
        state,
        stateFields: ['currentIndex', 'displayType', 'feed', 'hubImageUrl', 'imageUrlList', 'isCollapsed', 'isUIHidden', 'itemDataList', 'selectedItemIndices', 'trailerUrlList'],
      });

      // Show image/video carousel as well (no way to click on it, so it starts from here)
      Messenger.emit(MessengerEvents.CAROUSEL_SHOW_DEBUG);
    }
  };

  // Toggle fullscreen
  handleFullscreenHotKey: (event: SyntheticKeyboardEvent<HTMLElement>) => void = (event) => {
    event.preventDefault();
    event.stopPropagation();

    Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_TOGGLE_FULLSCREEN);
  };

  // Mute/unmute
  handleMuteHotKey: (event: SyntheticKeyboardEvent<HTMLElement>) => void = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.toggleSound();
  };

  // Play/pause
  handlePlayHotKey: (event: SyntheticKeyboardEvent<HTMLElement>) => void = (event) => {
    const {
      videoCarousel: { isPlaying },
    } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (isPlaying) {
      this.pauseVideo();
    } else {
      this.playVideo();
    }
  };

  handleTrailerEnded: () => void = () => {
    const {
      currentIndex,
      selectedItemIndices: { length: itemCount },
    } = this.state;

    if (currentIndex < itemCount - 1) {
      // Go to next trailer
      this.displayNextItem();
    } else {
      // All trailers have been played once: go back to first one without starting it
      Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_STOP);
      this.setState({ currentIndex: 0 });
      this.resetUIHideTimer(false);
    }
  };

  refreshNpvr: () => void = () => {
    Messenger.emit(MessengerEvents.REFRESH_NPVR);
  };

  collapse: () => void = () => {
    const {
      videoCarousel: { isPlaying },
    } = this.props;

    Messenger.emit(MessengerEvents.CAROUSEL_HIDE);
    this.resetCarouselTimer();
    this.setState({ displayType: SectionDisplayType.Collapsed });

    if (isPlaying) {
      this.wasTrailerPlaying = true;
      this.pauseVideo();
    } else {
      this.wasTrailerPlaying = false;
    }
  };

  show: () => void = () => {
    const { currentIndex } = this.state;
    const { isVideo, wasTrailerPlaying } = this;

    Messenger.emit(MessengerEvents.CAROUSEL_SHOW);
    this.setState({ displayType: SectionDisplayType.Regular });

    if (currentIndex > -1 && !isVideo) {
      this.displayItemIndex(currentIndex);
    }

    if (wasTrailerPlaying) {
      this.wasTrailerPlaying = false;
      this.playVideo();
    }
  };

  resetBackgroundCarousel: () => void = () => {
    Messenger.emit(MessengerEvents.IMAGE_CAROUSEL_SET, null);
    Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_SET, null);
  };

  loadHubImage: () => void = () => {
    const { avenueImageUri, hubItem, localGetImageUrl } = this.props;
    const {
      abortController: { signal },
    } = this;

    const uri = avenueImageUri ?? hubItem?.id;

    if (!uri) {
      return;
    }

    localGetImageUrl(uri, HUB_IMAGE_WIDTH, HUB_IMAGE_HEIGHT, Theme.Dark, signal)
      .then((hubImageUrl: string) => {
        signal.throwIfAborted();

        this.setState({ hubImageUrl });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadData: () => void = () => {
    const {
      channels,
      section,
      section: {
        model: { provider, uri },
      },
      state,
    } = this.props;

    if (!uri) {
      return;
    }

    if (provider === FeedProviderKind.Local) {
      // Feed of local channels
      const { channels: channelsParam, event } = getValuesFromQueryString(uri, ['channels', 'event']);
      this.sectionChannels = getSectionChannels(section, channelsParam, channels, state);

      if (event !== null) {
        // Live feed
        this.sectionType = CarouselSectionType.Live;
        this.updateLiveSection();
      } else {
        // Channels feed (e.g. FAST channels)
        this.sectionType = CarouselSectionType.Regular;
        const feed = EpgManager.buildFakeChannelsSection(section, this.sectionChannels);
        if (feed !== null) {
          this.setState({ feed });
        }
      }
    } else {
      // Regular feed
      this.sectionType = CarouselSectionType.Regular;
      this.buildRegularSection();
    }
  };

  updateLiveSection: () => void = () => {
    const {
      avenueIndex,
      dataLoadedCallback,
      section,
      section: { id },
      sectionIndex,
    } = this.props;
    const { feed: prevFeed } = this.state;
    const { sectionChannels } = this;

    if (sectionChannels !== null && !EpgManager.isRefreshing()) {
      const newLiveFeed = EpgManager.getLiveFeed(section, sectionChannels);
      const feedItemIds = prevFeed.map((i) => i.selectedProgramId);

      let shouldNpvrBeRefreshed = false;
      if (newLiveFeed.length !== feedItemIds.length) {
        if (feedItemIds.length > 0) {
          shouldNpvrBeRefreshed = true;
        }
        this.setState({ feed: newLiveFeed });
      } else {
        for (let i = 0; i < newLiveFeed.length; i++) {
          if (newLiveFeed[i].selectedProgramId !== feedItemIds[i]) {
            // Only update feed in state if at least one program has changed (a program ended and a new one started)
            this.setState({ feed: newLiveFeed });
            break;
          }
        }
      }

      if (shouldNpvrBeRefreshed) {
        this.refreshNpvr();
      }

      // Notify avenue and tell it how many items were loaded
      dataLoadedCallback(id, sectionIndex, avenueIndex, newLiveFeed.length);

      if (newLiveFeed.length === 0) {
        // Empty live feed
        this.collapse();
      }
    }

    this.liveFeedTimer = setTimeout(this.updateLiveSection, EpgManager.isRefreshing() ? EPG.IntervalLiveFeedEpgRefreshing : EPG.IntervalLiveFeedEpgReady);
  };

  buildRegularSection: () => void = () => {
    const {
      avenueIndex,
      dataLoadedCallback,
      localSendV8SectionFeedRequest,
      section,
      section: { id, kind },
      sectionIndex,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8SectionFeedRequest(section, signal)
      .then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
        signal.throwIfAborted();

        const feed = ((response: any): NETGEM_API_V8_FEED);
        if (kind === SectionType.HeroPanel && feed.length > 1) {
          // Only keep first item for hero panel section
          feed.length = 1;
        }
        this.setState({ feed });
      })
      .catch((error) =>
        ignoreIfAborted(signal, error, () => {
          this.setState({ feed: [] });
          dataLoadedCallback(id, sectionIndex, avenueIndex, 0);
        }),
      );
  };

  renderItemSlides: () => Array<React.Node> = () => {
    const { hubItem, onItemClick } = this.props;
    const { currentIndex, feed, itemDataList, selectedItemIndices } = this.state;
    const { sectionTitle } = this;

    if (feed.length === 0) {
      return [];
    }

    return selectedItemIndices.map((feedIndex, carouselIndex) => {
      const { [feedIndex]: item } = feed;
      const { [feedIndex]: itemData } = itemDataList;

      return (
        <ItemSlide
          currentIndex={currentIndex}
          hubItem={hubItem}
          item={item}
          itemData={itemData ?? null}
          itemIndex={carouselIndex}
          key={item.id}
          onItemClick={onItemClick}
          sectionTitle={sectionTitle}
        />
      );
    });
  };

  renderIndices: () => Array<React.Node> = () => {
    const { currentIndex, itemDataList, selectedItemIndices } = this.state;

    return selectedItemIndices.map((feedIndex, carouselIndex) => (
      <ItemIndex currentIndex={currentIndex} index={carouselIndex} itemData={itemDataList[feedIndex] ?? null} key={feedIndex} onClick={this.handleItemIndexOnClick} />
    ));
  };

  updateItemData: (feedIndex: number, partialState: any) => void = (feedIndex, partialState) => {
    this.setState(
      produce((draft) => {
        draft.itemDataList[feedIndex] = {
          ...draft.itemDataList[feedIndex],
          ...partialState,
        };
      }),
    );
  };

  getProgramMetadata: (feedIndex: number, programId: string, signal: AbortSignal) => Promise<any> = (feedIndex, programId, signal) => {
    const { localSendV8MetadataRequest } = this.props;

    return localSendV8MetadataRequest(programId, METADATA_KIND_PROGRAM, signal)
      .then((metadata: NETGEM_API_V8_METADATA) => {
        signal.throwIfAborted();

        return ((metadata: any): NETGEM_API_V8_METADATA_PROGRAM);
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  getSeriesMetadata: (feedIndex: number, seriesId: string, signal: AbortSignal) => Promise<any> = (feedIndex, seriesId, signal) => {
    const { localSendV8MetadataRequest } = this.props;

    return localSendV8MetadataRequest(seriesId, METADATA_KIND_SERIES, signal)
      .then((metadata: NETGEM_API_V8_METADATA) => {
        signal.throwIfAborted();

        return ((metadata: any): NETGEM_API_V8_METADATA_SERIES);
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  getImageUrl: (programId: string, programMetadata?: NETGEM_API_V8_METADATA_PROGRAM, seriesMetadata?: NETGEM_API_V8_METADATA_SERIES, signal: AbortSignal) => Promise<Undefined<string>> = (
    programId,
    programMetadata,
    seriesMetadata,
    signal,
  ) => {
    const { localGetImageUrl } = this.props;
    const { isVideo } = this;

    const width = isVideo ? VOD_TILE_WIDTH : CAROUSEL_BACKGROUND_IMAGE_WIDTH;
    const height = isVideo ? VOD_TILE_HEIGHT : CAROUSEL_BACKGROUND_IMAGE_HEIGHT;

    let imageId: ?string = null;
    const imagesMetadata = seriesMetadata?.images ?? programMetadata?.images;

    if (imagesMetadata && !isVideo) {
      imageId = findLandscapeImageId(imagesMetadata, IMAGE_TAG_NO_TEXT);
    }

    if (!imageId) {
      imageId = programId;
    }

    return localGetImageUrl(imageId, width, height, undefined, signal)
      .then((imageUrl: string) => {
        signal.throwIfAborted();

        return imageUrl;
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  getItemData: (feedIndex: number, carouselIndex: number, signal: AbortSignal) => Promise<ItemData> = (feedIndex, carouselIndex, signal) => {
    const {
      feed: {
        [feedIndex]: { selectedProgramId, seriesId, type },
      },
    } = this.state;
    const { tileType } = this;

    const badItem = {
      carouselIndex,
      imageUrl: undefined,
      programMetadata: undefined,
      seriesMetadata: undefined,
      tileType: undefined,
    };

    if (type === FAKE_EPG_LIVE_ITEM_TYPE) {
      return Promise.resolve(badItem);
    }

    const promises = [this.getProgramMetadata(feedIndex, selectedProgramId, signal)];

    if (seriesId) {
      promises.push(this.getSeriesMetadata(feedIndex, seriesId, signal));
    }

    return Promise.allSettled(promises).then((results: AllSettledPromises) => {
      const [programMetadataPromise, seriesMetadataPromise] = results;

      if (programMetadataPromise.status === SettledPromiseFulfilled && (!seriesMetadataPromise || seriesMetadataPromise.status === SettledPromiseFulfilled)) {
        // All promises were successful
        const programMetadata = programMetadataPromise.value;
        const seriesMetadata = seriesMetadataPromise?.value;

        return this.getImageUrl(selectedProgramId, programMetadata, seriesMetadata, signal)
          .then((imageUrl) => {
            return {
              carouselIndex,
              imageUrl,
              programMetadata,
              seriesMetadata,
              tileType,
            };
          })
          .catch(() => badItem);
      }

      // At least one promise failed
      return badItem;
    });
  };

  initializeItemList: () => void = () => {
    const {
      section: { kind },
    } = this.props;
    const {
      feed,
      feed: { length: availableItemCount },
    } = this.state;

    if (availableItemCount === 0) {
      return;
    }

    this.isVideo = kind !== SectionType.HeroPanel && isVodItem(feed[0]);

    // Initialize items data (no metadata, image and trailer at first and not displayed (carouselIndex = -1)
    const itemDataList = [];
    for (let i = 0; i < availableItemCount; ++i) {
      itemDataList.push({ carouselIndex: -1 });
    }

    if (this.isVideo) {
      this.enabledHotKeys();
      this.observeVideoVisibility();
    }

    this.setState({ itemDataList }, this.selectItemIndices);
  };

  observeVideoVisibility: () => void = () => {
    const { observer, sectionElement } = this;

    if (!sectionElement || observer) {
      return;
    }

    this.observer = new IntersectionObserver(
      (entries) => {
        const {
          videoCarousel: { isPlaying },
        } = this.props;
        const { wasTrailerPlaying } = this;

        if (entries[0].isIntersecting) {
          // Trailer visible: play video
          if (wasTrailerPlaying) {
            this.wasTrailerPlaying = false;
            this.playVideo();
          }
        } else if (isPlaying) {
          // Trailer hidden: pause video
          this.wasTrailerPlaying = true;
          this.pauseVideo();
        }
      },
      { threshold: [0] },
    );

    this.observer.observe(sectionElement);
  };

  selectItemIndices: () => void = () => {
    const {
      feed: { length: availableItemCount },
    } = this.state;
    const { isVideo } = this;

    const maxItems = isVideo ? VIDEO_MAX_ITEMS : IMAGE_MAX_ITEMS;
    const displayedItemCount = this.sectionType !== CarouselSectionType.Live && availableItemCount > maxItems ? maxItems : availableItemCount;

    // Take the first maxItems indices
    const selectedItemIndices = [...Array(displayedItemCount).keys()];

    if (isVideo) {
      // For video carousel only, shuffle selected indices using a special RNG that gives the same result for a given day
      dailyShuffleArray(selectedItemIndices);
    }

    this.setState({ selectedItemIndices }, this.loadItemsData);
  };

  loadItemsData: () => void = () => {
    const { itemDataList, selectedItemIndices } = this.state;
    const {
      abortController: { signal },
    } = this;

    const promises = [];
    selectedItemIndices.forEach((feedIndex) => {
      const {
        [feedIndex]: { carouselIndex },
      } = itemDataList;

      if (carouselIndex === -1) {
        // Load data for new items in list only
        promises.push(this.getItemData(feedIndex, carouselIndex, signal));
      }
    });

    Promise.allSettled(promises).then((results: AllSettledPromises) => {
      if (signal.aborted) {
        return;
      }

      const newItemDataList: Array<ItemData> = [];

      // Get loaded data from fulfilled promises
      results.forEach((result, index) => {
        if (result.status === SettledPromiseFulfilled) {
          const { imageUrl, programMetadata, seriesMetadata, tileType } = result.value;
          const feedIndex = selectedItemIndices[index];
          newItemDataList[feedIndex] = {
            carouselIndex: index,
            imageUrl,
            programMetadata,
            seriesMetadata,
            tileType,
          };
        }
      });

      this.setState({ itemDataList: newItemDataList }, () => {
        if (this.isVideo) {
          this.checkTrailers();
        } else {
          this.startImageCarousel();
        }
      });
    });
  };

  checkTrailers: () => void = () => {
    const { streamPriorities } = this.props;
    const { itemDataList, selectedItemIndices } = this.state;

    const newSelectedItemIndices = [];
    const trailerUrlList = [];

    selectedItemIndices.forEach((selectedIndex, i) => {
      const { [selectedIndex]: itemData } = itemDataList;

      if (itemData?.programMetadata) {
        const trailer: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM | null = getTrailer(streamPriorities, itemData.programMetadata, itemData.seriesMetadata);
        const trailerUrl = trailer?.path;

        if (trailerUrl) {
          newSelectedItemIndices.push(selectedIndex);
          trailerUrlList.push(trailerUrl);
          this.updateItemData(selectedIndex, { carouselIndex: i });
        } else {
          logDebug(`No trailer URL for item at index ${selectedIndex} in feed`);
        }
      } else {
        logDebug(`No program metadata for item at index ${selectedIndex} in feed`);
      }
    });

    if (trailerUrlList.length > 0) {
      // At least 1 trailer has been found
      this.startVideoCarousel(trailerUrlList);

      if (newSelectedItemIndices.length === selectedItemIndices.length) {
        // All items have a trailer
        return;
      }
    }

    // Update selected item list with the ones having trailers
    this.setState({ selectedItemIndices: newSelectedItemIndices });
  };

  reset: () => void = () => {
    const {
      displayType,
      selectedItemIndices: { length: itemCount },
    } = this.state;

    if (displayType === SectionDisplayType.Regular) {
      this.moveToFirstPage();
    } else if (itemCount > 0) {
      this.show();
    }
  };

  moveToFirstPage: () => void = () => {
    this.resetCarouselTimer();
    this.displayItemIndex(0);
  };

  displayItemIndex: (itemIndex: number) => void = (itemIndex) => {
    const { imageUrlList, trailerUrlList } = this.state;

    if (imageUrlList.length + trailerUrlList.length === 0) {
      return;
    }

    /*
     * Following lines effect:
     *  Video carousel: launches next trailer, then a trailer starts at the end of the previous one, so no need for a timer in this component
     *  Image carousel: shows next image
     */
    this.setState({ currentIndex: itemIndex });
    Messenger.emit(MessengerEvents.CAROUSEL_SET_CURRENT_INDEX, itemIndex);

    this.startCarouselTimer();
  };

  displayNextItem: (direction?: number) => void = (direction) => {
    const {
      currentIndex,
      selectedItemIndices: { length: itemCount },
    } = this.state;

    let nextIndex = 0;
    if (typeof direction === 'number' && direction < 0) {
      // Going backward
      nextIndex = (currentIndex + itemCount - 1) % itemCount;
    } else {
      // Going forward
      nextIndex = (currentIndex + 1) % itemCount;
    }

    this.displayItemIndex(nextIndex);
  };

  areIndicesDifferent: (l1: Array<string>, l2: Array<string>) => boolean = (l1, l2) => l1.length !== l2.length || l1.some((value, index) => value !== l2[index]);

  startVideoCarousel: (newTrailerUrlList: Array<string>) => void = (newTrailerUrlList) => {
    const { trailerUrlList } = this.state;

    if (this.areIndicesDifferent(newTrailerUrlList, trailerUrlList)) {
      this.setState({ trailerUrlList: newTrailerUrlList });
    }
  };

  startImageCarousel: () => void = () => {
    const { itemDataList, selectedItemIndices } = this.state;

    if (itemDataList.length === selectedItemIndices.length) {
      this.setState({ imageUrlList: selectedItemIndices.map((index) => itemDataList[index].imageUrl ?? '') });
    }
  };

  startCarousel: () => void = () => {
    const {
      avenueIndex,
      dataLoadedCallback,
      section: { id },
      sectionIndex,
    } = this.props;
    const { feed } = this.state;

    if (this.carouselTimerId) {
      // Image carousel already started
      return;
    }

    // Notify avenue and tell it how many items were loaded
    dataLoadedCallback(id, sectionIndex, avenueIndex, feed.length);

    this.startUIHideTimer();
    this.displayNextItem();
  };

  startCarouselTimer: () => void = () => {
    const { isVideo } = this;

    if (isVideo) {
      return;
    }

    // Image carousel
    this.resetCarouselTimer();
    this.carouselTimerId = setTimeout(this.displayNextItem, ITEM_CHANGE_TIMEOUT);
  };

  resetCarouselTimer: () => void = () => {
    if (this.carouselTimerId) {
      clearTimeout(this.carouselTimerId);
      this.carouselTimerId = null;
    }

    this.resetUIHideTimer(false);
  };

  startUIHideTimer: () => void = () => {
    const {
      videoCarousel: { isPlaying },
    } = this.props;
    const { isMouseOverSection, uiHideTimerId } = this;

    if (isPlaying && isMouseOverSection && !uiHideTimerId) {
      this.uiHideTimerId = setTimeout(this.hideUI, UI_HIDE_TIMEOUT);
    }
  };

  resetUIHideTimer: (thenRestart: boolean) => void = (thenRestart) => {
    const { isCollapsed } = this.state;

    if (!isCollapsed) {
      this.showUI();
    }

    if (this.uiHideTimerId) {
      clearTimeout(this.uiHideTimerId);
      this.uiHideTimerId = null;
    }

    if (thenRestart) {
      this.startUIHideTimer();
    }
  };

  hideUI: () => void = () => {
    const { onUIHiddenCallback } = this.props;

    Messenger.emit(MessengerEvents.REDUCE_VIDEO_CAROUSEL_MASK);
    this.setState({ isUIHidden: true });

    if (onUIHiddenCallback) {
      onUIHiddenCallback();
    }
  };

  showUI: () => void = () => {
    const { onUIShownCallback } = this.props;

    Messenger.emit(MessengerEvents.ENLARGE_VIDEO_CAROUSEL_MASK);
    this.setState({ isUIHidden: false });

    if (onUIShownCallback) {
      onUIShownCallback();
    }
  };

  handleOnMouseEnter: () => void = () => {
    const { isMouseOverSection, sectionElement } = this;

    if (sectionElement && !isMouseOverSection) {
      this.isMouseOverSection = true;
      sectionElement.addEventListener('mousemove', this.handleOnMouseMove, { passive: true });
    }
  };

  handleOnMouseLeave: () => void = () => {
    const { sectionElement } = this;

    this.isMouseOverSection = false;
    this.resetUIHideTimer(false);

    sectionElement?.removeEventListener('mousemove', this.handleOnMouseMove, { passive: true });
  };

  handleOnMouseMove: () => void = () => {
    const {
      videoCarousel: { isPlaying },
    } = this.props;
    const { isMouseOverSection } = this;

    if (isMouseOverSection && isPlaying) {
      this.resetUIHideTimer(true);
    }
  };

  handleItemIndexOnClick: (index: number) => void = (index) => {
    const { currentIndex } = this.state;

    if (index !== currentIndex) {
      this.resetUIHideTimer(true);
      this.displayItemIndex(index);
    }
  };

  handleNextButtonOnClick: () => void = () => {
    this.resetUIHideTimer(true);
    this.displayNextItem();
  };

  handlePreviousButtonOnClick: () => void = () => {
    this.resetUIHideTimer(true);
    this.displayNextItem(-1);
  };

  // $FlowFixMe: flow doesn't understand some external classes/types/interfaces
  handleOnSwiped: (eventData: SwipeEventData) => void = (eventData) => {
    const { dir } = eventData;
    const {
      selectedItemIndices: { length: itemCount },
    } = this.state;

    if (itemCount <= 1) {
      return;
    }

    if (dir === LEFT) {
      this.resetUIHideTimer(true);
      this.displayNextItem();
    } else if (dir === RIGHT) {
      this.resetUIHideTimer(true);
      this.displayNextItem(-1);
    }
  };

  playVideo: () => void = () => {
    const {
      localUpdateVideoCarouselPlaying,
      videoCarousel: { isPlaying },
    } = this.props;

    this.resetUIHideTimer(true);

    if (!isPlaying) {
      localUpdateVideoCarouselPlaying();
    }
  };

  handlePlayButtonOnClick: () => void = this.playVideo;

  pauseVideo: () => void = () => {
    const { localUpdateVideoCarouselPaused } = this.props;

    this.resetUIHideTimer(false);
    localUpdateVideoCarouselPaused();
  };

  handlePauseButtonOnClick: () => void = this.pauseVideo;

  muteVideo: () => void = () => {
    const { localUpdateVideoCarouselMuted } = this.props;

    localUpdateVideoCarouselMuted();
  };

  unmuteVideo: () => void = () => {
    const { localUpdateVideoCarouselUnmuted } = this.props;

    localUpdateVideoCarouselUnmuted();
  };

  toggleSound: () => void = () => {
    const {
      videoCarousel: { isMuted },
    } = this.props;

    if (isMuted) {
      this.unmuteVideo();
    } else {
      this.muteVideo();
    }
  };

  handleEqualizerButtonOnClick: () => void = this.toggleSound;

  handleFullscreenButtonOnClick: () => void = () => {
    Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_ENTER_FULLSCREEN);
  };

  wrapPictoInTooltip: (picto: React.Node, tooltipKeyContent: string, tooltipTheme?: ThemeType, tooltipContentClass?: string) => React.Node = (
    picto,
    tooltipKeyContent,
    tooltipTheme,
    tooltipContentClass,
  ) => {
    const tooltipContent = <div className={clsx('tooltipContent', tooltipContentClass)}>{Localizer.localize(tooltipKeyContent)}</div>;
    const options: TippyOptionsType = {
      ...TIPPY_DEFAULT_OPTIONS,
      content: tooltipContent,
    };

    if (tooltipTheme) {
      options.theme = tooltipTheme.toLowerCase();
    }

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Tippy {...options}>{picto}</Tippy>
    );
  };

  renderVideoIconBar: () => ?React.Node = () => {
    const {
      videoCarousel: { isMuted, isPlaying },
    } = this.props;
    const { isVideo } = this;

    if (!isVideo) {
      return null;
    }

    // Play/pause button
    const pauseBtn = this.wrapPictoInTooltip(<PictoPause onClick={this.handlePauseButtonOnClick} />, 'carousel.tooltip.toggle_play', undefined, 'button');
    const playBtn = this.wrapPictoInTooltip(<PictoPlay onClick={this.handlePlayButtonOnClick} />, 'carousel.tooltip.toggle_play', undefined, 'button');
    const playPauseBtn = isPlaying ? pauseBtn : playBtn;

    // Fullscreen button
    const fullscreenBtn = this.wrapPictoInTooltip(<PictoFullscreen onClick={this.handleFullscreenButtonOnClick} />, 'carousel.tooltip.fullscreen', undefined, 'button');

    // Equalizer button (mute/unmute)
    const equalizerBtn = <Equalizer isMuted={isMuted || !isPlaying} onClick={this.handleEqualizerButtonOnClick} />;

    // Entering/leaving the icon bar is the opposite of entering/leaving the section
    return (
      <div className='iconBar' onMouseEnter={this.handleOnMouseLeave} onMouseLeave={this.handleOnMouseEnter}>
        {playPauseBtn}
        {equalizerBtn}
        {fullscreenBtn}
      </div>
    );
  };

  render(): React.Node {
    const { isDebugModeEnabled } = this.props;
    const {
      displayType,
      hubImageUrl,
      isUIHidden,
      selectedItemIndices: { length: itemCount },
    } = this.state;
    const { isVideo } = this;

    if (displayType !== SectionDisplayType.Regular) {
      return null;
    }

    const handleSectionMouseLeave = isVideo ? this.handleOnMouseLeave : undefined;
    const handleSectionMouseEnter = isVideo ? this.handleOnMouseEnter : undefined;

    // Entering/leaving pagination area or previous/next buttons is the opposite of entering/leaving the section

    const previousButton =
      itemCount > 1 ? (
        <div className='previousButton' onMouseEnter={handleSectionMouseLeave} onMouseLeave={handleSectionMouseEnter}>
          <PictoArrowLeft onClick={this.handlePreviousButtonOnClick} />
        </div>
      ) : null;

    const nextButton =
      itemCount > 1 ? (
        <div className='nextButton' onMouseEnter={handleSectionMouseLeave} onMouseLeave={handleSectionMouseEnter}>
          <PictoArrowRight onClick={this.handleNextButtonOnClick} />
        </div>
      ) : null;

    const pagination =
      itemCount > 1 ? (
        <div className='pagination' onMouseEnter={handleSectionMouseLeave} onMouseLeave={handleSectionMouseEnter}>
          {this.renderIndices()}
        </div>
      ) : null;

    const hubImage = hubImageUrl ? <img alt='' className='hubImage' src={hubImageUrl} /> : null;

    return (
      <div
        className={clsx('sectionCarousel', isUIHidden && 'hidden')}
        onMouseEnter={handleSectionMouseEnter}
        onMouseLeave={handleSectionMouseLeave}
        ref={(instance) => {
          this.sectionElement = instance;
        }}
      >
        {previousButton}
        <Swipeable onSwiped={this.handleOnSwiped} trackMouse>
          <div className='itemSlideContainer'>
            {hubImage}
            {this.renderItemSlides()}
          </div>
        </Swipeable>
        <div className='paginationAndActions' onClick={isDebugModeEnabled ? this.handleDebugOnClick : undefined}>
          {pagination}
          {this.renderVideoIconBar()}
        </div>
        {nextButton}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxSectionReducerStateType => {
  return {
    channels: state.appConfiguration.deviceChannels,
    gridSectionId: state.ui.gridSectionId,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isInTheaterMode: state.ui.isInTheaterMode,
    state,
    streamPriorities: state.appConfiguration.playerStreamPriorities,
    videoCarousel: state.ui.videoCarousel,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxSectionDispatchToPropsType => {
  return {
    localGetImageUrl: (assetId: string, width: number, height: number, theme?: ThemeType, signal?: AbortSignal): Promise<any> =>
      dispatch(
        getImageUrl(
          {
            assetId,
            height,
            theme,
            width,
          },
          signal,
        ),
      ),

    localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataRequest(assetId, type, signal)),

    localSendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, signal?: AbortSignal): Promise<any> => dispatch(sendV8SectionFeedRequest(section, null, signal)),

    localUpdateVideoCarouselMuted: () => dispatch(updateVideoCarouselMuted()),

    localUpdateVideoCarouselPaused: () => dispatch(updateVideoCarouselPaused()),

    localUpdateVideoCarouselPlaying: () => dispatch(updateVideoCarouselPlaying()),

    localUpdateVideoCarouselUnmuted: () => dispatch(updateVideoCarouselUnmuted()),
  };
};

const SectionCarousel: React.ComponentType<SectionPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SectionCarouselView);

export default SectionCarousel;
