/* @flow */

import type {
  AllTracks,
  AudioTrack,
  IndexedShakaTrack,
  ShakaConfiguration,
  ShakaError,
  ShakaLanguageRole,
  ShakaOfflineContent,
  ShakaPlayerSettings,
  ShakaTrack,
  ShakaTrackList,
  VideoTrack,
} from './shakaTypes';
import type { BasicCallbackFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { DRM_KEY_SYSTEM, Drm } from '../../../helpers/jsHelpers/Drm';
import { HeaderName, HeaderValue } from '../../../libs/netgemLibrary/v8/constants/Headers';
import {
  HtmlMediaError,
  VIDEOPLAYER_NAME,
  type VideoPlayerBitrateInfo,
  type VideoPlayerExternalSubtitles,
  type VideoPlayerInitData,
  type VideoPlayerLiveMetrics,
  type VideoPlayerMediaInfo,
  VideoPlayerMediaType,
} from './types';
import { SentryTagName, SentryTagValue } from '../../../helpers/debug/sentryTypes';
import { cloneAllTracks, generateUpdateDownloadProgress, selectDownloadTracks } from './shakaHelpers';
import { logError, logInfo } from '../../../helpers/debug/debug';
import { ContentType } from '../constantsAndTypes';
import { HttpStatus } from '../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import { MILLISECONDS_PER_SECOND } from '../../../helpers/dateTime/Format';
import { MediametrieState } from '../../../helpers/mediametrie/types';
import SentryWrapper from '../../../helpers/debug/sentry';
import ShakaStorage from './shakaStorage';
import { getBoundedValue } from '../../../helpers/maths/maths';
import shaka from 'shaka-player/dist/shaka-player.compiled';
/*
 * Replace previous line with following one to use local version of Shaka Player
 * import shaka from './localShakaPlayer/shaka-player.compiled[.debug]';
 */

const ONE_MILLION = 1_000_000;

// If an error occurred during media decoding on Safari (Fairplay), retry up to MAX_RETRIES with an increasing delay between attempts
const MAX_RETRIES = 3;

// Wait 3s before attempt #2, then 6s before attempt #3, then 12s, etc. (in ms)
const RETRY_DELAY = 3_000;

// Timeshift capabilities lower than 300 s (5 min) is considered as no timeshift at all
const LIVE_TIMESHIFT_BUFFER_DEPTH_THRESHOLD = 300;

// Default max attempts in case of error
const DEFAULT_MAX_ATTEMPTS = 6;

// When playing start over items, duration retrieved by player is huge and incorrect
const INFINITY_LIKE = 1e9;

class PlayerShaka {
  authenticationToken: string | null;

  bufferingCallback: () => void;

  bufferLoadedCallback: () => void;

  certificate: ArrayBuffer;

  contentType: ContentType;

  duration: number;

  endPosition: ?number;

  errorCallback: (category: number, code: number, mediaErrorCode: number, errorMsg?: string, isForbidden?: boolean) => void;

  hasVUDrmToken: boolean;

  initData: VideoPlayerInitData | null;

  isStopped: boolean;

  isVod: boolean;

  liveBufferLengthUpdatedCallback: (liveBufferLength: number) => void;

  name: string = VIDEOPLAYER_NAME;

  playbackEndedCallback: () => void;

  playbackPausedCallback: () => void;

  playbackPlayingCallback: () => void;

  playbackTimeUpdatedCallback: (time: number, timeshift?: number) => void;

  retryCount: number;

  retryTimer: ?TimeoutID;

  safariVodWorkaroundCallback: BasicCallbackFunction;

  // Id of selected audio track
  selectedAudioId: number | null;

  // Id of selected text track
  selectedTextId: number | null;

  // Id of selected video track
  selectedVideoId: number | null;

  settings: ShakaPlayerSettings;

  // $FlowFixMe: not Flow-typed
  shakaPlayer: ?shaka.Player;

  skd: string;

  streamInfoUpdatedCallback: () => void;

  streamInitializedCallback: (duration: number) => void;

  title: string;

  tracks: AllTracks;

  videoContainer: HTMLElement | null;

  videoElement: HTMLVideoElement;

  volumeChangedCallback: (volume: number) => void;

  constructor(
    videoElement: HTMLVideoElement,
    videoContainer: HTMLElement | null,
    settings: ShakaPlayerSettings,
    authenticationToken: string | null,
    isVod: boolean,
    contentType: ContentType,
    title: string,
  ) {
    this.authenticationToken = authenticationToken;
    this.contentType = contentType;
    this.duration = NaN;
    this.endPosition = null;
    this.hasVUDrmToken = false;
    this.initData = null;
    this.isStopped = true;
    this.isVod = isVod;
    this.retryCount = 0;
    this.retryTimer = null;
    this.selectedAudioId = null;
    this.selectedTextId = null;
    this.selectedVideoId = null;
    this.settings = settings;
    this.shakaPlayer = null;
    this.skd = '';
    this.title = title;
    this.tracks = {
      audio: [],
      text: [],
      video: [],
    };
    this.videoElement = videoElement;
    this.videoContainer = videoContainer;

    // Install built-in polyfills to patch browser incompatibilities
    shaka.polyfill.installAll();

    /*
     * To debug Shaka Player, replace the import by:
     * import shaka from 'shaka-player/dist/shaka-player.ui.debug';
     *
     * and set the desired log level (DEBUG, V1 or V2):
     * shaka.log.setLevel(shaka.log.Level.V2);
     */

    // Check to see if the browser supports the basic APIs Shaka needs
    if (!shaka.Player.isBrowserSupported()) {
      throw new Error('BrowserNotSupported');
    }
  }

  showDebug: () => void = () => {
    const { shakaPlayer } = this;

    if (shakaPlayer) {
      logInfo('---------- Shaka Player configuration');
      logInfo(shakaPlayer.getConfiguration());
      logInfo(`Presentation delay: ${shakaPlayer.getManifest().presentationTimeline.getDelay()}`);
    } else {
      logInfo('Shaka Player not initialized');
    }
  };

  updateMaxBitrate: (maxBitrate: number) => void = (maxBitrate) => {
    const { shakaPlayer } = this;

    shakaPlayer?.configure('restrictions.maxBandwidth', maxBitrate === -1 ? Infinity : maxBitrate * ONE_MILLION);
  };

  getName: () => string = () => this.name;

  getVersion: () => string = () => shaka.Player.version;

  getState: () => MediametrieState = () => {
    const { videoElement } = this;

    if (videoElement.ended) {
      return MediametrieState.Stop;
    }

    return videoElement.paused ? MediametrieState.Pause : MediametrieState.Play;
  };

  // Only used for Mediametrie
  getPosition: () => number = () => {
    const { contentType, endPosition, shakaPlayer, videoElement } = this;

    if (!shakaPlayer) {
      return 0;
    }

    // Property contentType cannot be LiveRecording here, since Mediametrie is disabled for recordings
    if (contentType === ContentType.Live || videoElement.ended) {
      return endPosition ?? 0;
    }

    return videoElement.currentTime;
  };

  initialize: (initData: VideoPlayerInitData) => Promise<void> = async (initData) => {
    const { videoContainer, videoElement } = this;
    const { fairplayCertificateUrl, laUrl } = initData;

    await this.reset();

    this.shakaPlayer = new shaka.Player();
    const { shakaPlayer } = this;

    if (!shakaPlayer) {
      throw new Error('Cannot initialize Shaka Player');
    }

    this.initData = initData;

    if (videoContainer) {
      // Provide video container (for subtitles)
      shakaPlayer.setVideoContainer(videoContainer);
    }

    // Add callbacks
    this.plugCallbacks(shakaPlayer, videoElement);

    try {
      // Attach HTML element
      await shakaPlayer.attach(videoElement);

      if (fairplayCertificateUrl && laUrl) {
        // Fairplay DRM (hence Safari)
        const certificate = await this.loadCertificate(fairplayCertificateUrl);
        if (certificate) {
          this.certificate = certificate;
        }
        return this.configureAndStart(shakaPlayer, initData);
      }

      // Widevine, Playready or no DRM
      return this.configureAndStart(shakaPlayer, initData);
    } catch (error) {
      logInfo('Error attaching videoElement');
      throw error;
    }
  };

  initializeOffline: () => Promise<void> = async () => {
    const { videoContainer, videoElement } = this;

    this.shakaPlayer = new shaka.Player();
    const { shakaPlayer } = this;

    // DEBUG
    window.player = shakaPlayer;

    if (!shakaPlayer) {
      throw new Error('Cannot initialize Shaka Player');
    }

    if (videoContainer) {
      // Provide video container (for subtitles)
      shakaPlayer.setVideoContainer(videoContainer);
    }

    // Add callbacks
    this.plugCallbacks(shakaPlayer, videoElement);

    try {
      // Attach HTML element
      await shakaPlayer.attach(videoElement);

      /*
       *  TODO:
       *  if (fairplayCertificateUrl && laUrl) {
       *    // Fairplay DRM (hence Safari)
       *    const certificate = await this.loadCertificate(fairplayCertificateUrl);
       *    if (certificate) {
       *      this.certificate = certificate;
       *    }
       *    return this.configureAndStart(shakaPlayer, initData);
       *  }
       *  Widevine, Playready or no DRM
       * return this.configureAndStart(shakaPlayer, initData);
       */
    } catch (error) {
      logInfo('Error attaching videoElement');
      throw error;
    }
  };

  startOffline: (content: ShakaOfflineContent) => Promise<boolean> = async (content) => {
    const { shakaPlayer, videoElement } = this;
    const { offlineUri } = content;

    if (!shakaPlayer) {
      return false;
    }

    await ShakaStorage.playContent(shakaPlayer, offlineUri);
    await videoElement.play();
    return true;
  };

  configureAndStart: (shakaPlayer: any, initData: VideoPlayerInitData) => Promise<any> = (shakaPlayer, initData) => {
    this.configurePlayer(shakaPlayer, initData);

    // Loads manifest and play
    return this.start(shakaPlayer, initData);
  };

  start: (shakaPlayer: any, initData: VideoPlayerInitData) => Promise<any> = (shakaPlayer, initData) => {
    const { videoElement } = this;
    const { url } = initData;

    return shakaPlayer
      .load(url)
      .then(() => {
        this.initializeAudioAndVideoTracks();
        return videoElement.play();
      })
      .catch((error) => {
        logInfo('Error in play() promise');
        throw error;
      });
  };

  loadCertificate: (fairplayCertificateUrl: string) => Promise<?ArrayBuffer> = (fairplayCertificateUrl) =>
    fetch(fairplayCertificateUrl)
      .then((response) => response.arrayBuffer())
      .catch((error) => logError(error));

  // $FlowFixMe: not Flow-typed
  plugCallbacks: (shakaPlayer: shaka.Player, videoElement: HTMLElement) => void = (shakaPlayer, videoElement) => {
    shakaPlayer.addEventListener('error', this.errorEvent, { passive: true });
    shakaPlayer.addEventListener('buffering', this.bufferingEvent, { passive: true });
    shakaPlayer.addEventListener('textchanged', this.textChangedEvent, { passive: true });
    shakaPlayer.addEventListener('trackschanged', this.tracksChangedEvent, { passive: true });
    shakaPlayer.addEventListener('adaptation', this.adaptationEvent, { passive: true });
    shakaPlayer.addEventListener('variantchanged', this.variantChangedEvent, { passive: true });

    videoElement.addEventListener('ended', this.endedEvent, { passive: true });
    videoElement.addEventListener('pause', this.pausedEvent, { passive: true });
    videoElement.addEventListener('playing', this.playingEvent, { passive: true });
    videoElement.addEventListener('timeupdate', this.timeUpdateEvent, { passive: true });
    videoElement.addEventListener('durationchange', this.durationChangeEvent, { passive: true });
    videoElement.addEventListener('volumechange', this.volumeChangedEvent, { passive: true });
  };

  configurePlayer: (shakaPlayer: any, initData: VideoPlayerInitData) => void = (shakaPlayer, initData) => {
    const {
      settings: { bufferBehind, bufferingGoal, rebufferingGoal },
    } = this;
    const { audioLanguage, maxBitrate, subtitlesLanguage } = initData;

    const configuration: ShakaConfiguration = {
      manifest: { dash: { ignoreMinBufferTime: true } },
      streaming: {
        autoLowLatencyMode: true,
        bufferBehind,
        bufferingGoal,
        ignoreTextStreamFailures: true,
        rebufferingGoal,
        retryParameters: { maxAttempts: DEFAULT_MAX_ATTEMPTS },
      },
    };

    // DRM
    this.addDrmConfiguration(initData, configuration);

    // Auto-select audio track
    if (audioLanguage) {
      configuration.preferredAudioLanguage = audioLanguage;
    }

    // Auto-select subtitles track
    if (subtitlesLanguage) {
      configuration.preferredTextLanguage = subtitlesLanguage;
    }

    // Green streaming
    if (typeof maxBitrate === 'number') {
      configuration.restrictions = { maxBandwidth: maxBitrate === -1 ? Infinity : maxBitrate * ONE_MILLION };
    }

    shakaPlayer.configure(configuration);
  };

  resetRetryTimer: () => void = () => {
    const { retryTimer } = this;

    if (retryTimer) {
      clearTimeout(retryTimer);
      this.retryTimer = null;
    }
  };

  reset: () => Promise<void> = () => {
    const { videoElement, shakaPlayer } = this;

    this.resetRetryTimer();

    this.endPosition = null;
    this.initData = null;
    this.retryCount = 0;

    videoElement.removeEventListener('ended', this.endedEvent, { passive: true });
    videoElement.removeEventListener('pause', this.pausedEvent, { passive: true });
    videoElement.removeEventListener('playing', this.playingEvent, { passive: true });
    videoElement.removeEventListener('timeupdate', this.timeUpdateEvent, { passive: true });
    videoElement.removeEventListener('durationchange', this.durationChangeEvent, { passive: true });
    videoElement.removeEventListener('volumechange', this.volumeChangedEvent, { passive: true });

    if (!shakaPlayer) {
      return Promise.resolve();
    }

    shakaPlayer.removeEventListener('error', this.errorEvent, { passive: true });
    shakaPlayer.removeEventListener('buffering', this.bufferingEvent, { passive: true });
    shakaPlayer.removeEventListener('textchanged', this.textChangedEvent, { passive: true });
    shakaPlayer.removeEventListener('trackschanged', this.tracksChangedEvent, { passive: true });
    shakaPlayer.removeEventListener('adaptation', this.adaptationEvent, { passive: true });
    shakaPlayer.removeEventListener('variantchanged', this.variantChangedEvent, { passive: true });

    return shakaPlayer
      .detach()
      .then(() => shakaPlayer.destroy())
      .then(() => delete this.shakaPlayer)
      .catch((error) => {
        logError('Error detaching videoElement or destroying player');
        logError(error);
      });
  };

  // $FlowFixMe: not Flow-typed
  configureRequestFilter: (shakaPlayer: shaka.Player, initData: VideoPlayerInitData) => void = (shakaPlayer, initData) => {
    const { customHeaders, drm, vuDrmToken } = initData;

    if (!customHeaders && !vuDrmToken && drm !== Drm.Fairplay) {
      return;
    }

    shakaPlayer.getNetworkingEngine().registerRequestFilter((type, request) => {
      if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
        return;
      }

      if (customHeaders) {
        request.headers = customHeaders;
      }

      if (vuDrmToken) {
        // FTV special Dash/Widevine case
        this.hasVUDrmToken = true;
        request.headers[HeaderName.VuDrmToken] = vuDrmToken;
      }

      // Our entitlement server needs a JSON body, plus authorization header
      if (drm === Drm.Fairplay) {
        // Add auth token in headers and build payload
        request.headers[HeaderName.ContentType] = HeaderValue.ApplicationJson;
        request.headers[HeaderName.Authorization] = `Bearer ${this.authenticationToken ?? ''}`;
        const spc = shaka.util.Uint8ArrayUtils.toStandardBase64(request.body);
        const { skd } = this;

        request.body = shaka.util.StringUtils.toUTF8(
          JSON.stringify({
            skd,
            spc,
          }),
        );
      }
    });
  };

  // $FlowFixMe: not Flow-typed
  configureResponseFilter: (shakaPlayer: shaka.Player, initData: VideoPlayerInitData) => void = (shakaPlayer, initData) => {
    const { drm } = initData;

    if (drm !== Drm.Fairplay) {
      return;
    }

    // Our entitlement server sends back the ckc inside a JSON object
    shakaPlayer.getNetworkingEngine().registerResponseFilter((type, response) => {
      if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
        return;
      }

      const { ckc } = JSON.parse(shaka.util.StringUtils.fromUTF8(response.data));
      response.data = shaka.util.Uint8ArrayUtils.fromBase64(ckc).buffer;
    });
  };

  configureServers: (configuration: any, drm: Drm, serverUrl: string) => void = (configuration, drm, serverUrl) => {
    const { certificate } = this;

    switch (drm) {
      case Drm.Fairplay:
        configuration.drm = {
          advanced: { [DRM_KEY_SYSTEM.Fairplay]: { serverCertificate: new Uint8Array(certificate) } },
          // eslint-disable-next-line no-unused-vars
          initDataTransform: (initData, initDataType, drmInfo) => {
            if (initDataType !== 'skd') {
              return initData;
            }

            // Store skd to be used later, when building the entitlement request (to retrieve the ckc)
            this.skd = shaka.util.StringUtils.fromBytesAutoDetect(initData);
            return initData;
          },
          servers: { [DRM_KEY_SYSTEM.Fairplay]: serverUrl },
        };
        break;

      case Drm.Playready:
        configuration.drm = { servers: { [(DRM_KEY_SYSTEM.Playready: string)]: serverUrl } };
        break;

      case Drm.Widevine:
        configuration.drm = {
          advanced: {
            [(DRM_KEY_SYSTEM.Widevine: string)]: {
              audioRobustness: 'SW_SECURE_CRYPTO',
              videoRobustness: 'SW_SECURE_CRYPTO',
            },
          },
          servers: { [(DRM_KEY_SYSTEM.Widevine: string)]: serverUrl },
        };

      // No default
    }
  };

  addDrmConfiguration: (initData: VideoPlayerInitData, configuration: ShakaConfiguration) => void =
    // eslint-disable-next-line consistent-return
    (initData, configuration) => {
      const { drm, laUrl } = initData;
      const { shakaPlayer } = this;

      if (!shakaPlayer || typeof drm === 'undefined') {
        return;
      }

      if (!laUrl) {
        logError('DRM without laUrl');
        return;
      }

      const serverUrl = laUrl.replace(/^http:/iu, 'https:');

      this.configureRequestFilter(shakaPlayer, initData);
      this.configureResponseFilter(shakaPlayer, initData);
      this.configureServers(configuration, drm, serverUrl);
    };

  compare: (a: ?number, b: ?number) => number | null = (a, b) => {
    if (typeof a !== 'number' && typeof b !== 'number') {
      return null;
    }

    if (typeof a !== 'number') {
      return 1;
    }

    if (typeof b !== 'number') {
      return -1;
    }

    return a - b;
  };

  sortAudioTrack: (a: AudioTrack, b: AudioTrack) => number = (a, b) => {
    const result = this.compare(a.id, b.id);
    if (typeof result === 'number') {
      return result;
    }

    return 0;
  };

  sortVideoTrack: (a: VideoTrack, b: VideoTrack) => number = (a, b) => {
    let result = this.compare(a.height, b.height);
    if (typeof result === 'number') {
      return result;
    }

    result = this.compare(a.videoBandwidth, b.videoBandwidth);
    if (typeof result === 'number') {
      return result;
    }

    result = this.compare(a.id, b.id);
    if (typeof result === 'number') {
      return result;
    }

    return 0;
  };

  initializeAudioAndVideoTracks: () => void = () => {
    const { shakaPlayer } = this;
    const audioTracks: {| [number]: AudioTrack |} = {};
    const videoTracks: {| [number]: VideoTrack |} = {};

    shakaPlayer?.getVariantTracks().forEach((track) => {
      const {
        active,
        audioBandwidth,
        audioCodec,
        audioId,
        audioMimeType,
        bandwidth,
        frameRate,
        height,
        id,
        label,
        language,
        mimeType,
        originalAudioId,
        originalVideoId,
        roles,
        type,
        videoBandwidth,
        videoCodec,
        videoId,
        videoMimeType,
        width,
      } = track;

      if (type === 'variant') {
        // Audio/video track
        if (active) {
          this.selectedAudioId = audioId;
          this.selectedVideoId = videoId;
        }

        if (audioId && !(audioId in audioTracks)) {
          audioTracks[audioId] = {
            audioBandwidth,
            audioCodec,
            audioId,
            audioMimeType,
            bandwidth,
            id,
            label,
            language,
            mimeType,
            originalAudioId,
            roles,
          };
        }

        if (videoId && !(videoId in videoTracks)) {
          videoTracks[videoId] = {
            bandwidth,
            frameRate,
            height,
            id,
            label,
            language,
            mimeType,
            originalVideoId,
            roles,
            videoBandwidth,
            videoCodec,
            videoId,
            videoMimeType,
            width,
          };
        }
      }
    });

    this.tracks.audio = Object.values(audioTracks).sort(this.sortAudioTrack);
    this.tracks.video = Object.values(videoTracks).sort(this.sortVideoTrack);
  };

  // Adds external subtitles if and only if the same subtitles have not already been loaded from manifest
  addSubtitles: (subtitles: Array<VideoPlayerExternalSubtitles>) => void = (subtitles) => {
    const { shakaPlayer } = this;

    if (!shakaPlayer) {
      return;
    }

    // Build a lookup map of subtitles found in manifest
    const subtitlesMap: KeyValuePair<Set<string>> = {};
    shakaPlayer.getTextTracks().forEach((subs) => {
      const { kind, language, roles } = subs;

      let { language: subsForLang } = subtitlesMap;
      if (!subsForLang) {
        subsForLang = new Set<string>();
        subtitlesMap[language] = subsForLang;
      }

      const isCaptions = kind === 'captions' || roles.some((el) => el.toLowerCase().indexOf('caption') > -1);
      subsForLang.add(isCaptions ? 'captions' : 'subtitles');
    });

    subtitles.forEach((subs) => {
      const { captions, language, mimeType, url } = subs;

      if (!subtitlesMap[language]?.has(captions ? 'captions' : 'subtitles')) {
        // External subtitles track not present in manifest
        shakaPlayer.addTextTrackAsync(url, language, captions ? 'captions' : 'subtitles', mimeType).catch((error) => {
          SentryWrapper.error({
            breadcrumbs: ['Shaka Player', 'addSubtitles'],
            context: {
              language,
              mimeType,
              url,
            },
            error,
            tagName: SentryTagName.Component,
            tagValue: SentryTagValue.Player,
          });
        });
      }
    });
  };

  /*
   * Fired when a playback error occurs
   */
  errorEvent: (error: ShakaError) => void = (error) => {
    const { errorCallback, isVod, retryCount, safariVodWorkaroundCallback } = this;
    const {
      detail,
      detail: {
        category,
        code,
        data: [untypedMediaErrorCode, httpStatus, untypedErrorMsg],
      },
    } = error;

    const mediaErrorCode = typeof untypedMediaErrorCode === 'number' ? untypedMediaErrorCode : 0;

    logError(detail);

    if (
      // Shaka error category and code
      category === shaka.util.Error.Category.MEDIA &&
      code === shaka.util.Error.Code.VIDEO_ERROR &&
      // HTML MEDIA_ERR_DECODE
      mediaErrorCode === (HtmlMediaError.Decode: number) &&
      // Fairplay DRM
      this.skd !== '' &&
      // Not too many retries
      retryCount < MAX_RETRIES
    ) {
      // MEDIA_ERR_DECODE while decoding Fairplay content on Safari: hide error and retry

      if (isVod) {
        // VOD case: exit player, wait a bit then retry
        safariVodWorkaroundCallback();
      } else {
        // Live/catchup case: retry a few times without exiting player
        this.resetRetryTimer();
        this.retryCount += 1;
        const timeout = RETRY_DELAY * this.retryCount;
        logInfo(`Fairplay decode error: retry ${this.retryCount}/${MAX_RETRIES} in ${timeout / MILLISECONDS_PER_SECOND} seconds...`);
        this.retryTimer = setTimeout(() => {
          const { initData, shakaPlayer } = this;

          if (initData && shakaPlayer) {
            logInfo(`Retrying now (${this.retryCount}/${MAX_RETRIES})`);
            this.start(shakaPlayer, initData);
          }
        }, timeout);
      }

      // Hide error
      return;
    }

    errorCallback(category, code, mediaErrorCode, untypedErrorMsg?.toString(), httpStatus === HttpStatus.Forbidden);
  };

  durationChangeEvent: () => void = () => {
    const {
      contentType,
      shakaPlayer,
      streamInitializedCallback,
      videoElement: { duration },
    } = this;

    let value = 0;

    if (shakaPlayer) {
      // Live and LiveRecording contents returns Infinity
      value = contentType === ContentType.Static && duration < INFINITY_LIKE ? duration : Infinity;
    }

    streamInitializedCallback(value);
  };

  /*
   * Return playhead position
   *  - catchup: duration from beginning in seconds
   *  - live:    timestamp in seconds
   */
  timeUpdateEvent: () => void = () => {
    const {
      contentType,
      liveBufferLengthUpdatedCallback,
      playbackTimeUpdatedCallback,
      shakaPlayer,
      videoElement: { currentTime },
    } = this;

    if (!shakaPlayer) {
      return;
    }

    /*
     * It takes a small amount of time to Shaka Player before isLive() actually returns "true".
     * So when we play live content but isLive() returns false, we skip this callback
     */
    if (contentType !== ContentType.Static && !shakaPlayer.isLive()) {
      return;
    }

    if (contentType === ContentType.Static) {
      // Catchup, finished recording, VOD
      playbackTimeUpdatedCallback(currentTime);
    } else {
      // Live (including live recording)
      const { end, start } = shakaPlayer.seekRange();

      // Current time is sometimes (mostly in Safari) greater than the seek range's end, so buffer length is capped to 0
      const liveBufferLength = Math.max(0, end - start);

      if (liveBufferLength >= LIVE_TIMESHIFT_BUFFER_DEPTH_THRESHOLD) {
        liveBufferLengthUpdatedCallback(liveBufferLength);
      }

      // In some rare cases, Shaka cannot tell the playhead position
      let position = (shakaPlayer.getPlayheadTimeAsDate()?.getTime() ?? 0) / MILLISECONDS_PER_SECOND;
      const timeshift = end - currentTime;

      if (contentType === ContentType.LiveRecording) {
        position = currentTime - start;
        if (position < 0) {
          position = 0;
        }
      }

      playbackTimeUpdatedCallback(position, timeshift);
    }
  };

  /*
   * Fired when the player's buffering state changes
   */
  bufferingEvent: (buffering: any) => void = (buffering) => {
    const { bufferingCallback, bufferLoadedCallback } = this;
    const { buffering: isBuffering } = buffering;

    if (isBuffering) {
      bufferingCallback();
    } else {
      bufferLoadedCallback();
    }
  };

  /*
   * Fired when a call from the application caused a text stream change
   * Can be triggered by calls to selectTextTrack() or selectTextLanguage()
   */
  textChangedEvent: () => void = () => {
    const { shakaPlayer, streamInfoUpdatedCallback } = this;

    if (!shakaPlayer) {
      return;
    }

    this.selectedTextId = this.findSelectedTrackId(shakaPlayer.getTextTracks());
    streamInfoUpdatedCallback();
  };

  /*
   * Fired when the list of tracks changes
   * For example, this will happen when new tracks are added/removed or when track restrictions change
   */
  tracksChangedEvent: () => void = () => {
    const { shakaPlayer } = this;

    if (!shakaPlayer) {
      return;
    }

    const textTracks = shakaPlayer.getTextTracks();
    this.tracks.text = textTracks.map((track) => {
      const { forced, id, kind, label, language, mimeType, roles } = track;

      return {
        forced,
        id,
        kind,
        label,
        language,
        mimeType,
        roles,
      };
    });

    this.selectedTextId = this.findSelectedTrackId(textTracks);
  };

  findSelectedTrackId: (tracks: ShakaTrackList) => number | null = (tracks) => tracks.find((track) => track.active)?.id ?? null;

  /*
   * Fired when an automatic adaptation (i.e. ABR manager) causes the active tracks to change
   * Does not fire when the application calls selectVariantTrack(), selectTextTrack(), selectAudioLanguage(), or selectTextLanguage()
   */
  adaptationEvent: () => void = () => {
    this.initializeAudioAndVideoTracks();
  };

  /*
   * Fired when a call from the application (i.e. user action) caused a variant change
   * Can be triggered by calls to selectVariantTrack() or selectAudioLanguage()
   * Does not fire when an automatic adaptation causes a variant change
   */
  variantChangedEvent: () => void = () => {
    this.initializeAudioAndVideoTracks();
  };

  endedEvent: () => void = () => {
    const {
      playbackEndedCallback,
      shakaPlayer,
      videoElement: { currentTime },
    } = this;

    if (!shakaPlayer) {
      return;
    }

    // Catchup, recording or VOD
    this.endPosition = currentTime;

    playbackEndedCallback();
  };

  pausedEvent: () => void = () => {
    const { playbackPausedCallback } = this;

    playbackPausedCallback();
  };

  playingEvent: () => void = () => {
    const { playbackPlayingCallback, streamInfoUpdatedCallback } = this;

    this.retryCount = 0;
    this.resetRetryTimer();

    streamInfoUpdatedCallback();
    playbackPlayingCallback();
  };

  volumeChangedEvent: () => void = () => {
    const { volumeChangedCallback, videoElement } = this;

    volumeChangedCallback(videoElement.volume);
  };

  /* eslint-disable no-unused-vars, no-empty-function, arrow-body-style */
  getBitrateInfoListFor: (mediaType: VideoPlayerMediaType) => Array<VideoPlayerBitrateInfo> = (mediaType) => {
    return [];
  };

  getQualityFor: (mediaType: VideoPlayerMediaType) => number = (mediaType) => {
    return -1;
  };

  getAutoSwitchQualityFor: (mediaType: VideoPlayerMediaType) => boolean = (mediaType) => {
    return false;
  };

  setQualityFor: (mediaType: VideoPlayerMediaType, index: number) => void = (mediaType, index) => {};

  setAutoSwitchQualityFor: (mediaType: VideoPlayerMediaType, value: boolean) => void = (mediaType, value) => {};
  /* eslint-enable no-unused-vars, no-empty-function, arrow-body-style */

  pause: () => void = () => {
    const { videoElement } = this;

    videoElement.pause();
  };

  play: () => void = () => {
    const { videoElement } = this;

    videoElement.play();
  };

  isPaused: () => boolean = () => {
    const { videoElement } = this;

    return videoElement.paused ?? false;
  };

  setVolume: (volume: number) => void = (volume) => {
    const { videoElement } = this;

    videoElement.volume = volume;
  };

  isMuted: () => boolean = () => {
    const { videoElement } = this;

    return videoElement.muted ?? false;
  };

  setMute: (mute: boolean) => void = (mute) => {
    const { videoElement } = this;

    videoElement.muted = mute;
  };

  /*
   * Seek in a live stream
   *   value: positive or negative number (in seconds), representing the delta between current time and target time
   */
  liveSeek: (value: number) => void = (value) => {
    const {
      contentType,
      shakaPlayer,
      videoElement,
      videoElement: { currentTime },
    } = this;

    if (!shakaPlayer || contentType !== ContentType.Live) {
      return;
    }

    const { end, start } = shakaPlayer.seekRange();
    videoElement.currentTime = getBoundedValue(currentTime + value, start, end);
  };

  goBackToLive: () => void = () => {
    const { contentType, shakaPlayer } = this;

    if (!shakaPlayer || contentType !== ContentType.Live) {
      return;
    }

    shakaPlayer.goToLive();
  };

  /*
   * Seek in a static stream (or a live stream in the case of an in-progress recording)
   *   value (in-progress recording): positive number (in seconds), representing the delta between start time and target time
   *   value (catchup, recording or VOD): positive number (in seconds), representing the target time
   */
  seek: (value: number) => void = (value) => {
    const { contentType, shakaPlayer, videoElement } = this;

    if (!shakaPlayer) {
      return;
    }

    if (contentType === ContentType.Static) {
      // Static content: catchup, finished recording, VOD
      videoElement.currentTime = value;
    } else if (contentType === ContentType.LiveRecording) {
      // Live recording: only used for recordings still in progress
      const { start } = shakaPlayer.seekRange();
      videoElement.currentTime = start + value;
    }
  };

  download: () => void = () => {
    const { initData, title } = this;

    if (!initData) {
      return;
    }

    const toastId: string = crypto.randomUUID();
    const updateDownloadProgress = generateUpdateDownloadProgress(toastId, title);

    ShakaStorage.downloadContent(initData.url, title, selectDownloadTracks, updateDownloadProgress)
      .then(() => updateDownloadProgress(null, 1))
      .catch((error) => {
        logError(error);
        updateDownloadProgress(null, -1);
      });
  };

  setCurrentTrack: (mediaInfo: VideoPlayerMediaInfo) => void = (mediaInfo) => {
    const { shakaPlayer, streamInfoUpdatedCallback } = this;
    const { index, type } = mediaInfo;

    if (!shakaPlayer) {
      return;
    }

    const typeEnum = VideoPlayerMediaType.cast(type.toLowerCase());

    if (typeEnum === VideoPlayerMediaType.Audio) {
      // Audio track
      const { lang, roles } = mediaInfo;
      shakaPlayer.selectAudioLanguage(lang, roles && roles.length > 0 ? roles[0] : '');
    } else if (typeEnum === VideoPlayerMediaType.Subtitles) {
      // Subtitles track
      const textTracks = shakaPlayer.getTextTracks();
      shakaPlayer.setTextTrackVisibility(true);
      shakaPlayer.selectTextTrack(textTracks[index]);
    }

    streamInfoUpdatedCallback();
  };

  unsetCurrentTrack: (mediaType: VideoPlayerMediaType) => void = (mediaType) => {
    const { shakaPlayer, streamInfoUpdatedCallback } = this;

    if (!shakaPlayer) {
      return;
    }

    switch (mediaType) {
      case VideoPlayerMediaType.Audio:
        // Audio track
        shakaPlayer.audioTrack = -1;
        this.selectedAudioId = null;
        break;

      case VideoPlayerMediaType.Subtitles:
        // Subtitles track
        this.selectedTextId = null;
        shakaPlayer.setTextTrackVisibility(false);
        streamInfoUpdatedCallback();
        break;

      default:
    }
  };

  // Current audio track is found amongst all variants via getVariantTracks() but the selection is done through getAudioLanguagesAndRoles(), which returns a smaller list
  getAudioTrackIndex: (shakaPlayer: any, audioTrack: IndexedShakaTrack) => number = (shakaPlayer, audioTrack) => {
    const {
      track: { label, language, roles },
    } = audioTrack;
    const role = roles && roles.length > 0 ? roles[0] : '';
    const languageAndRoles = shakaPlayer.getAudioLanguagesAndRoles();

    for (let i = 0; i < languageAndRoles.length; ++i) {
      const {
        [i]: { language: currLanguage, role: currRole, label: currLabel },
      } = languageAndRoles;
      if (((typeof label !== 'string' && typeof currLabel !== 'string') || (typeof label === 'string' && currLabel === label)) && currLanguage === language && currRole === role) {
        return i;
      }
    }

    return -1;
  };

  getActiveTrack: (tracks: ShakaTrackList) => IndexedShakaTrack | null = (tracks) => {
    if (!tracks) {
      return null;
    }

    for (let index = 0; index < tracks.length; index += 1) {
      const track = tracks[index];
      const { active, language } = track;

      if (active && language !== '') {
        return {
          index,
          track,
        };
      }
    }

    return null;
  };

  getActiveAudioTrack: (shakaPlayer: any) => IndexedShakaTrack | null = (shakaPlayer) => this.getActiveTrack(shakaPlayer.getVariantTracks());

  getActiveTextTrack: (shakaPlayer: any) => IndexedShakaTrack | null = (shakaPlayer) => {
    const { selectedTextId } = this;

    if (selectedTextId === null) {
      // No text track is currently selected (either none is active or one is active but subtitles are hidden)
      return null;
    }

    return this.getActiveTrack(shakaPlayer.getTextTracks());
  };

  getCurrentTrackFor: (mediaType: VideoPlayerMediaType) => VideoPlayerMediaInfo | null =
    // eslint-disable-next-line consistent-return
    (mediaType) => {
      const { shakaPlayer } = this;

      if (!shakaPlayer) {
        return null;
      }

      switch (mediaType) {
        case VideoPlayerMediaType.Audio: {
          const indexedTrack = this.getActiveAudioTrack(shakaPlayer);
          if (indexedTrack) {
            // Index returned by getActiveAudioTrack() is the index among all variants, but we want the index in the list of tracks as seen by user
            const index = this.getAudioTrackIndex(shakaPlayer, indexedTrack);
            const { track } = indexedTrack;
            return this.mapAudioShakaTrack(track, index);
          }
          return null;
        }

        case VideoPlayerMediaType.Subtitles: {
          const indexedTrack = this.getActiveTextTrack(shakaPlayer);
          if (indexedTrack) {
            const { index, track } = indexedTrack;
            return this.mapTextTrack(track, index);
          }
          return null;
        }

        case VideoPlayerMediaType.Video:
          return null;

        // No default
      }
    };

  mapAudioShakaTrack: (track: ShakaTrack, index: number) => VideoPlayerMediaInfo | null = (track, index) => {
    const { label, kind, language, roles } = track;

    if (!language) {
      return null;
    }

    const role = roles && roles.length > 0 ? roles[0] : '';

    return {
      index,
      kind: kind ?? role,
      lang: language,
      name: label ?? undefined,
      type: (VideoPlayerMediaType.Audio: string),
    };
  };

  mapAudioShakaLanguageRole: (track: ShakaLanguageRole, index: number) => VideoPlayerMediaInfo | null = (track, index) => {
    const { label, language, role } = track;

    if (!language) {
      return null;
    }

    return {
      index,
      kind: role,
      lang: language,
      name: label ?? undefined,
      type: (VideoPlayerMediaType.Audio: string),
    };
  };

  mapTextTrack: (track: ShakaTrack, index: number) => VideoPlayerMediaInfo = (track, index) => {
    const { kind, label, language, roles } = track;

    const isCaptions = roles.some((el) => el.toLowerCase().indexOf('caption') > -1);

    return {
      index,
      kind: isCaptions ? 'captions' : (kind ?? undefined),
      lang: language,
      name: label ?? undefined,
      type: (VideoPlayerMediaType.Subtitles: string),
    };
  };

  mapAudioTracks: (tracks: Array<ShakaLanguageRole>) => Array<VideoPlayerMediaInfo> = (tracks) => {
    if (!tracks) {
      return [];
    }

    const result = [];
    for (let i = 0; i < tracks.length; i += 1) {
      const track = this.mapAudioShakaLanguageRole(tracks[i], i);

      if (track) {
        result.push(track);
      }
    }
    return result;
  };

  mapTextTracks: (textTracks: Array<ShakaTrack>) => Array<VideoPlayerMediaInfo> = (textTracks) => {
    const result = [];
    for (let i = 0; i < textTracks.length; i += 1) {
      const { [i]: track } = textTracks;
      const { language } = track;

      if (language !== '') {
        result.push(this.mapTextTrack(track, i));
      }
    }
    return result;
  };

  getTracksFor: (mediaType: VideoPlayerMediaType) => Array<VideoPlayerMediaInfo> =
    // eslint-disable-next-line consistent-return
    (mediaType) => {
      const { shakaPlayer } = this;

      if (!shakaPlayer) {
        return [];
      }

      switch (mediaType) {
        case VideoPlayerMediaType.Audio:
          return this.mapAudioTracks(shakaPlayer.getAudioLanguagesAndRoles());

        case VideoPlayerMediaType.Subtitles:
          return this.mapTextTracks(shakaPlayer.getTextTracks());

        case VideoPlayerMediaType.Video:
          return [];

        // No default
      }
    };

  getMetrics: () => VideoPlayerLiveMetrics | null = () => {
    const { hasVUDrmToken, selectedAudioId, selectedTextId, selectedVideoId, shakaPlayer, tracks } = this;

    if (!shakaPlayer) {
      return null;
    }

    return {
      shakaMetrics: {
        bufferFullness: shakaPlayer.getBufferFullness(),
        bufferedInfo: shakaPlayer.getBufferedInfo(),
        drmInfo: {
          ...shakaPlayer.drmInfo(),
          hasVUDrmToken,
        },
        selectedTrackIds: {
          audio: selectedAudioId,
          text: selectedTextId,
          video: selectedVideoId,
        },
        stats: shakaPlayer.getStats(),
        tracks: cloneAllTracks(tracks),
      },
    };
  };
}

export default PlayerShaka;
