/* @flow */

import './circleLoader.css';
import * as React from 'react';
import { useEffect, useState } from 'react';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';

const MAX_PERCENTAGE = 100;
const TWO = 2;
const RADIUS = 40;

// Time to wait before calling the animation complete callback (in ms)
const ANIMATION_COMPLETE_TIMEOUT = 50;

// Circumference is ~251.3
const CIRCUMFERENCE = Math.PI * RADIUS * TWO;

type CircleLoaderPropType = {|
  +hidePercentage?: boolean, // eslint-disable-line react/require-default-props
  +onAnimationCompleted: BasicCallbackFunction,
  +progress: number,
|};

const renderPercentage = (hidePercentage: boolean, progress: number): React.Node => {
  if (hidePercentage) {
    return null;
  }

  return <div className='percentage'>{Math.min(progress, MAX_PERCENTAGE)}%</div>;
};

const CircleLoader = ({ hidePercentage = false, onAnimationCompleted, progress }: CircleLoaderPropType): React.Node => {
  const [timer, setTimer] = useState<TimeoutID | null>(null);
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    setPercentage(Math.min(((MAX_PERCENTAGE - progress) / MAX_PERCENTAGE) * CIRCUMFERENCE, CIRCUMFERENCE));
  }, [progress]);

  // Initialization
  useEffect(() => {
    const resetTimer = () => {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
    };

    return () => {
      resetTimer();
    };
  }, []);

  useEffect(() => {
    if (onAnimationCompleted && progress >= MAX_PERCENTAGE) {
      // Following time ensures that the animation is shown (even briefly), otherwise, we get some ugly blinking visual glitch
      setTimer(setTimeout(onAnimationCompleted, ANIMATION_COMPLETE_TIMEOUT));
    }
  }, [onAnimationCompleted, progress, setTimer]);

  return (
    <div className='circleLoader'>
      <svg viewBox='0 0 100 100'>
        <circle className='background' cx='50' cy='50' r={RADIUS} />
        <circle cx='50' cy='50' r={RADIUS} style={{ strokeDashoffset: percentage }} />
      </svg>
      {renderPercentage(hidePercentage, progress)}
    </div>
  );
};

export default CircleLoader;
