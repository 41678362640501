/* @flow */

import './VodEpisode.css';
import * as React from 'react';
import { type BASE_VOD_PURCHASE_DATA_TYPE, PendingOperationKind, PendingOperationReason, type VOD_PURCHASE_DATA_TYPE, storePendingOperation } from '../../../helpers/rights/pendingOperations';
import { BOVodAssetStatus, getTimeLeftFromExpirationTime, getVodLocationFromVtiId, getVodStatusFromLocations } from '../../../helpers/videofutur/metadata';
import type { BasicCallbackFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { ItemContent, type NETGEM_API_V8_FEED_ITEM, type NETGEM_API_V8_ITEM_LOCATION } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { PROGRAM_INFO, type VOD_STATUS_TYPE } from '../../../helpers/ui/metadata/Types';
import PricingVod, { ContextKind } from '../../pricingVod/PricingVod';
import { getSynopsis, renderProgramDetails } from '../../../helpers/ui/metadata/Format';
import { hideModal, showVodPurchaseModal } from '../../../redux/modal/actions';
import { logError, showDebug } from '../../../helpers/debug/debug';
import type { BO_PURCHASE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/purchase';
import type { CARD_DATA_MODAL_TYPE } from '../../modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { CombinedReducers } from '../../../redux/reducers';
import type { Dispatch } from '../../../redux/types/types';
import { ExtendedItemType } from '../../../helpers/ui/item/types';
import { FEATURE_SUBSCRIPTION } from '../../../redux/appConf/constants';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../../libs/netgemLibrary/v8/types/PurchaseInfo';
import type { NETGEM_API_V8_RIGHTS } from '../../../libs/netgemLibrary/v8/types/Rights';
import type { NETGEM_API_VIEWINGHISTORY } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import { PictoArrowDown } from '@ntg/components/dist/pictos/Element';
import { RegistrationType } from '../../../redux/appRegistration/types/types';
import WatchingStatus from '../../watchingStatus/WatchingStatus';
import { arePurchaseListsDifferent } from '../../../helpers/ui/section/comparisons';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { formatAvailabilityTimestamp } from '../../../helpers/dateTime/Format';
import { getDistributorId } from '../../../helpers/ui/item/distributor';
import { getImageUrl } from '../../../redux/netgemApi/actions/v8/metadataImage';
import { getProgress } from '../../../helpers/viewingHistory/ViewingHistory';
import { getPurchaseInfoPerAsset } from '../../../redux/netgemApi/actions/v8/purchaseInfo';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';
import { getVodLocations } from '../../../redux/netgemApi/actions/v8/metadataLocation';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isPlaybackGranted } from '../../../helpers/rights/playback';

const IMAGE_WIDTH = 108;
const IMAGE_HEIGHT = 160;

type ReduxVodEpisodeDispatchToPropsType = {|
  +localGetImageUrl: (assetId: string, width: number, height: number, signal?: AbortSignal) => Promise<any>,
  +localGetPurchaseInfoPerAsset: (id: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +localGetVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal) => Promise<any>,
  +localHideModal: BasicCallbackFunction,
  +showVodPurchaseDialog: (vodPurchaseData: VOD_PURCHASE_DATA_TYPE) => void,
|};

type ReduxVodEpisodeReducerStateType = {|
  +channels: ChannelMap,
  +commercialOffers: KeyValuePair<Array<string>> | null,
  +defaultRights: NETGEM_API_V8_RIGHTS | null,
  +isDebugModeEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +isSubscriptionFeatureEnabled: boolean,
  +purchaseList: BO_PURCHASE_LIST_TYPE | null,
  +usePackPurchaseApi: boolean,
  +userRights: Array<string> | null,
  +viewingHistory: NETGEM_API_VIEWINGHISTORY,
  +viewingHistoryStatus: LoadableStatus,
|};

type VodEpisodePropType = {|
  +cardData: CARD_DATA_MODAL_TYPE,
  +isExpanded: boolean,
  +index: number,
  +item: NETGEM_API_V8_FEED_ITEM,
  +onToggleExpandedState: (index: number) => void,
  +programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  +seriesMetadata: NETGEM_API_V8_METADATA_SERIES,
|};

type CompleteVodEpisodePropType = {|
  ...VodEpisodePropType,
  ...ReduxVodEpisodeReducerStateType,
  ...ReduxVodEpisodeDispatchToPropsType,
|};

type VodEpisodeStateType = {|
  imageUrl: string | null,
  purchaseInfo: NETGEM_API_V8_PURCHASE_INFO | null,
  vodLocations: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null,
  vodStatus: VOD_STATUS_TYPE | null,
|};

const InitialState = Object.freeze({
  imageUrl: null,
  purchaseInfo: null,
  vodLocations: null,
  vodStatus: null,
});

class VodEpisode extends React.PureComponent<CompleteVodEpisodePropType, VodEpisodeStateType> {
  abortController: AbortController;

  synopsis: ?string;

  // Location Id of purchased location
  viewingHistoryId: ?string;

  // VtiId of purchased location
  vtiId: ?number;

  constructor(props: CompleteVodEpisodePropType) {
    super(props);

    this.abortController = new AbortController();
    this.synopsis = null;
    this.viewingHistoryId = null;
    this.vtiId = null;

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps: CompleteVodEpisodePropType) {
    const { purchaseList } = this.props;
    const { purchaseList: prevPurchaseList } = prevProps;

    if (arePurchaseListsDifferent(purchaseList, prevPurchaseList)) {
      this.loadVodLocations();
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component VodEpisode will unmount');
  }

  showDebugInfo = () => {
    const { props, state } = this;

    showDebug('SeriesSection VodEpisode', {
      instance: this,
      instanceFields: ['synopsis', 'viewingHistoryId', 'vtiId'],
      misc: { watchingStatus: this.getWatchingStatus() },
      props,
      propsFields: ['episodesMetadata', 'index', 'isExpanded', 'item', 'programMetadata', 'seriesMetadata'],
      state,
      stateFields: ['imageUrl', 'purchaseInfo', 'vodLocations', 'vodStatus'],
    });
  };

  update = () => {
    const { programMetadata, usePackPurchaseApi } = this.props;

    this.viewingHistoryId = null;
    this.vtiId = null;
    this.synopsis = getSynopsis(programMetadata, Localizer.language);

    this.setState({ ...InitialState });

    this.loadImage();
    this.loadVodLocations();

    // TODO: clean up code when BO API v2 is fully supported
    if (usePackPurchaseApi) {
      this.loadPurchaseInfo();
    }
  };

  loadImage = () => {
    const {
      item: { selectedProgramId },
      localGetImageUrl,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    localGetImageUrl(selectedProgramId, IMAGE_WIDTH, IMAGE_HEIGHT, signal)
      .then((imageUrl: string) => {
        signal.throwIfAborted();

        this.setState({ imageUrl });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadVodLocations = () => {
    const {
      item: { locations },
      localGetVodLocations,
      purchaseList,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (!locations) {
      return;
    }

    localGetVodLocations(locations, signal)
      .then((vodLocations) => {
        signal.throwIfAborted();

        const vodStatus = getVodStatusFromLocations(vodLocations, purchaseList);
        const { viewingHistoryId, vtiId } = vodStatus;
        this.viewingHistoryId = viewingHistoryId;
        this.vtiId = vtiId;

        this.setState({
          vodLocations,
          vodStatus,
        });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadPurchaseInfo = () => {
    const {
      item: {
        id,
        purchasable,
        selectedLocation: { channelId },
      },
      localGetPurchaseInfoPerAsset,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (!purchasable) {
      return;
    }

    if (typeof channelId === 'undefined') {
      logError(`Channel Id is undefined for item ${id}`);
      return;
    }

    localGetPurchaseInfoPerAsset(id, channelId, signal)
      .then((purchaseInfo: NETGEM_API_V8_PURCHASE_INFO) => {
        signal.throwIfAborted();
        this.setState({ purchaseInfo });
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ purchaseInfo: null })));
  };

  handlePlayButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { cardData, channels, commercialOffers, defaultRights, isRegisteredAsGuest, isSubscriptionFeatureEnabled, item, localHideModal, programMetadata, seriesMetadata, userRights } = this.props;
    const { purchaseInfo, vodLocations, vodStatus } = this.state;
    const { viewingHistoryId, vtiId } = this;

    if (!programMetadata) {
      return;
    }

    const { authority } = programMetadata;
    const locationMetadata = getVodLocationFromVtiId(vodLocations, vtiId);
    const distributorId = getDistributorId(vodLocations, purchaseInfo);

    const localCardData: CARD_DATA_MODAL_TYPE = {
      ...cardData,
      distributorId,
      programMetadata,
      seriesMetadata,
      viewingHistoryId,
      vtiId,
    };

    if (
      !isPlaybackGranted(
        isRegisteredAsGuest,
        isSubscriptionFeatureEnabled,
        localCardData,
        authority,
        vodStatus?.status === BOVodAssetStatus.Free ? ItemContent.NetgemSVOD : ItemContent.VODOrDeeplink,
        locationMetadata?.location,
        channels,
        commercialOffers,
        defaultRights,
        userRights,
      )
    ) {
      // Playback denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    Messenger.emit(MessengerEvents.OPEN_PLAYER, {
      authority,
      distributorId,
      item,
      locationId: locationMetadata?.location.id,
      openFromCard: true,
      programMetadata,
      seriesMetadata,
      type: ExtendedItemType.VOD,
      viewingHistoryId,
      vtiId,
    });
  };

  handleOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { index, isDebugModeEnabled, onToggleExpandedState } = this.props;
    const { altKey, ctrlKey } = event;

    if (isDebugModeEnabled && (ctrlKey || altKey)) {
      this.showDebugInfo();
    } else {
      onToggleExpandedState(index);
    }
  };

  vodPurchaseModalClosedCallback = (data: any): void => {
    const { item, programMetadata, seriesMetadata } = this.props;
    const { purchaseInfo, vodLocations } = this.state;

    if (!data) {
      // Purchase cancelled
      return;
    }

    const { locationId, playNow, viewingHistoryId, vtiId } = data;

    if (playNow && vtiId) {
      Messenger.emit(MessengerEvents.OPEN_PLAYER, {
        distributorId: getDistributorId(vodLocations, purchaseInfo),
        item,
        locationId,
        openFromCard: true,
        programMetadata,
        seriesMetadata,
        type: ExtendedItemType.VOD,
        viewingHistoryId,
        vtiId,
      });
    }
  };

  handlePurchaseButtonOnClick = (data: BASE_VOD_PURCHASE_DATA_TYPE) => {
    const { cardData, isRegisteredAsGuest, item, localHideModal, programMetadata, seriesMetadata, showVodPurchaseDialog } = this.props;
    const { vodPurchaseModalClosedCallback: closedCallback } = this;

    const { definition, displayPrice, distributorId, itemCount, licenseDuration, locationId, otherVtiId, purchaseType, vodLocationMetadata, vtiId } = data;

    if (!programMetadata) {
      return;
    }

    const purchaseData: VOD_PURCHASE_DATA_TYPE = {
      definition,
      displayPrice,
      distributorId,
      item,
      itemCount,
      licenseDuration,
      locationId,
      otherVtiId,
      programMetadata,
      purchaseType,
      seriesMetadata,
      vodLocationMetadata,
      vtiId,
    };

    if (isRegisteredAsGuest) {
      // Guest mode: let's open the sign-in page
      storePendingOperation({
        ...cardData,
        pendingOperation: {
          operationType: PendingOperationKind.Purchase,
          purchaseData,
          reason: PendingOperationReason.RequireAccount,
        },
      });
      localHideModal();
      Messenger.emit(MessengerEvents.SHOW_SIGN_IN, PendingOperationKind.Purchase);
    } else {
      Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, closedCallback);
      showVodPurchaseDialog(purchaseData);
    }
  };

  getWatchingStatus = (): number | null => {
    const { item, programMetadata, seriesMetadata, viewingHistory, viewingHistoryStatus } = this.props;
    const { vodStatus } = this.state;

    const status = vodStatus?.status ?? BOVodAssetStatus.Unknown;
    if (status === BOVodAssetStatus.Locked) {
      // VodEpisode has been watched (at least partially) but its rent has expired
      return null;
    }

    if (viewingHistoryStatus !== LoadableStatus.Loaded) {
      return null;
    }

    return getProgress(viewingHistory, item, programMetadata, seriesMetadata, false, vodStatus);
  };

  renderWatchingStatus = (): React.Node => {
    const progress = this.getWatchingStatus();

    return (
      <>
        <div />
        <WatchingStatus allowZeroProgress onClick={this.handlePlayButtonOnClick} progress={progress ?? 0} />
      </>
    );
  };

  renderVodStatus = (): React.Node => {
    const { purchaseInfo, vodLocations, vodStatus } = this.state;

    if (!vodStatus) {
      return null;
    }

    const { expirationTime, status } = vodStatus;

    if (status === BOVodAssetStatus.Unknown) {
      // Purchase list not loaded yet
      return null;
    }

    if (status === BOVodAssetStatus.Bought || status === BOVodAssetStatus.Free || status === BOVodAssetStatus.Rented) {
      return this.renderWatchingStatus();
    }

    return (
      <PricingVod
        context={ContextKind.VodEpisode}
        expirationTime={expirationTime}
        locationsMetadata={vodLocations}
        onClick={this.handlePurchaseButtonOnClick}
        purchaseInfo={purchaseInfo}
        status={status}
      />
    );
  };

  getTitle = (): string => {
    const { programMetadata } = this.props;

    if (!programMetadata) {
      return '';
    }

    const { titles } = programMetadata;
    return getTranslatedText(titles, Localizer.language);
  };

  renderPurchaseStatus = (isHeader: boolean): React.Node => {
    const { vodStatus } = this.state;

    if (!vodStatus) {
      return null;
    }

    const { availabilityTime, expirationTime, status } = vodStatus;
    let text: ?string = null;

    if (status === BOVodAssetStatus.Free) {
      // SVOD: asset is part of the subscription > don't repeat the same message for each episode
      return null;
    }

    if (status === BOVodAssetStatus.FreeButAvailableInFuture && typeof availabilityTime === 'number') {
      // SVOD: not available yet
      text = Localizer.localize('vod.pricing.availability.svod', { date: formatAvailabilityTimestamp(availabilityTime) });
    }

    if (status === BOVodAssetStatus.Rented) {
      // TVOD: asset already rented
      text = Localizer.localize('vod.pricing.tvod.rented');
      if (typeof expirationTime === 'number' && expirationTime > 0) {
        text = `${text} - ${Localizer.localize('vod.pricing.tvod.expire', { expiration: getTimeLeftFromExpirationTime(expirationTime) })}`;
      }
    } else if (!isHeader && status === BOVodAssetStatus.Bought) {
      // EST: asset already bought
      text = Localizer.localize('vod.pricing.est.bought');
    }

    if (text === null) {
      return null;
    }

    return <div className='purchaseStatus'>{text}</div>;
  };

  renderHeader = (): React.Node => {
    const { programMetadata } = this.props;

    const episodeIndex = programMetadata?.episodeOf?.episodeNumber;
    const episodeIndexStr = episodeIndex !== undefined ? `${episodeIndex} - ` : null;

    return (
      <div className='header' onClick={this.handleOnClick}>
        <div className='title'>
          {episodeIndexStr}
          {this.getTitle()}
        </div>
        <div className='actions'>
          {this.renderPurchaseStatus(true)}
          {this.renderVodStatus()}
          <PictoArrowDown className='arrow' />
        </div>
      </div>
    );
  };

  renderContent = (): React.Node => {
    const { item, programMetadata } = this.props;
    const { imageUrl, vodLocations } = this.state;
    const { synopsis } = this;

    const imageStyle = imageUrl ? { backgroundImage: `url(${imageUrl})` } : {};

    return (
      <div className='content'>
        <div className='cover' style={imageStyle} />
        <div className='details'>
          <div className='programInfo'>
            {/* eslint-disable-next-line no-bitwise */}
            {renderProgramDetails(item, programMetadata, vodLocations, PROGRAM_INFO.Duration | PROGRAM_INFO.ParentalGuidance)}
          </div>
          <div className='synopsis'>{synopsis}</div>
          {this.renderPurchaseStatus(false)}
        </div>
      </div>
    );
  };

  render(): React.Node {
    const { isExpanded } = this.props;

    return (
      <div className={clsx('episode', 'vod', isExpanded && 'expanded')}>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxVodEpisodeReducerStateType => {
  return {
    channels: state.appConfiguration.deviceChannels,
    commercialOffers: state.appConfiguration.rightsCommercialOffers,
    defaultRights: state.appConfiguration.rightsDefault,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    isSubscriptionFeatureEnabled: state.appConfiguration.features[FEATURE_SUBSCRIPTION],
    purchaseList: state.appRegistration.registration === RegistrationType.RegisteredAsGuest ? {} : state.ui.purchaseList,
    usePackPurchaseApi: state.appConfiguration.usePackPurchaseApi,
    userRights: state.appConfiguration.rightsUser,
    viewingHistory: state.ui.viewingHistory,
    viewingHistoryStatus: state.ui.viewingHistoryStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxVodEpisodeDispatchToPropsType => {
  return {
    localGetImageUrl: (assetId: string, width: number, height: number, signal?: AbortSignal): Promise<any> =>
      dispatch(
        getImageUrl(
          {
            assetId,
            height,
            width,
          },
          signal,
        ),
      ),

    localGetPurchaseInfoPerAsset: (id: string, channelId: string, signal?: AbortSignal): Promise<any> => dispatch(getPurchaseInfoPerAsset(id, channelId, signal)),

    localGetVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal): Promise<any> => dispatch(getVodLocations(locations, signal)),

    localHideModal: () => dispatch(hideModal()),

    showVodPurchaseDialog: (vodPurchaseData: VOD_PURCHASE_DATA_TYPE) => dispatch(showVodPurchaseModal(vodPurchaseData)),
  };
};

const VodEpisodeView: React.ComponentType<VodEpisodePropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(VodEpisode);

export default VodEpisodeView;
