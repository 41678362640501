/* @flow */

import './modal.css';
import * as React from 'react';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { PictoArrowLeft, PictoCart, PictoInfo, PictoRecord, PictoUser, PictoX } from '@ntg/components/dist/pictos/Element';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';
import type { CombinedReducers } from '../../redux/reducers';
import type { Dispatch } from '../../redux/types/types';
import HotKeys from '../../helpers/hotKeys/hotKeys';
import { ModalIcon } from './modalTypes';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { hideModal } from '../../redux/modal/actions';

type ReduxModalReducerStateType = {|
  +openModalCount: number,
|};

type DefaultProps = {|
  +backCallback?: (data: any) => void,
  +canCloseOnEnter?: boolean,
  +canCloseOnEscape?: boolean,
  +canCloseOnOverlayClick?: boolean,
  +className?: string | null,
  +hasSeparator?: boolean,
  +header?: React.Node,
  +icon?: ModalIcon,
  +index?: number,
  +isCloseButtonDisplayed?: boolean,
  +theme?: ThemeType,
  +title?: string | null,
|};

type ModalPropType = {|
  ...DefaultProps,
  +children: React.Node,
|};

type ReduxModalDispatchToPropsType = {|
  +localHideModal: BasicCallbackFunction,
|};

type ModalStateType = {|
  closingData: any,
  isClosing: boolean,
  isClosingEnabled: boolean,
|};

const InitialState = Object.freeze({
  closingData: null,
  isClosing: false,
  isClosingEnabled: true,
});

type CompleteModalPropType = {|
  ...ModalPropType,
  ...ReduxModalReducerStateType,
  ...ReduxModalDispatchToPropsType,
|};

// Used to stack modals on top of each others
const Z_INDEX = Object.freeze({
  ContentBase: 10100,
  OverlayBase: 10000,
  Shift: 200,
});

class ModalView extends React.PureComponent<CompleteModalPropType, ModalStateType> {
  isMouseDown: boolean;

  static defaultProps: DefaultProps = {
    backCallback: undefined,
    canCloseOnEnter: true,
    canCloseOnEscape: true,
    canCloseOnOverlayClick: true,
    className: null,
    hasSeparator: false,
    header: null,
    icon: ModalIcon.None,
    index: 0,
    isCloseButtonDisplayed: true,
    theme: Theme.Dark,
    title: '',
  };

  constructor(props: CompleteModalPropType) {
    super(props);

    this.isMouseDown = false;

    this.state = { ...InitialState };
  }

  componentDidMount() {
    const { canCloseOnEnter, canCloseOnEscape } = this.props;

    if (canCloseOnEnter) {
      HotKeys.register('enter', this.handleCloseHotKey, {
        disableOthers: true,
        name: 'Modal.enterClose',
      });
    }
    if (canCloseOnEscape) {
      HotKeys.register('escape', this.handleCloseHotKey, {
        disableOthers: true,
        name: 'Modal.escapeClose',
      });
    }
  }

  componentWillUnmount() {
    const { canCloseOnEnter, canCloseOnEscape } = this.props;

    if (canCloseOnEnter) {
      HotKeys.unregister('enter', this.handleCloseHotKey);
    }
    if (canCloseOnEscape) {
      HotKeys.unregister('escape', this.handleCloseHotKey);
    }
  }

  handleCloseHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { isClosingEnabled } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (isClosingEnabled) {
      this.cancel();
    }
  };

  handleOverlayOnMouseDown: () => void = () => {
    this.isMouseDown = true;
  };

  handleOverlayOnMouseUp: () => void = () => {
    const { isMouseDown } = this;

    if (isMouseDown) {
      this.cancel();
      this.isMouseDown = false;
    }
  };

  close = (data: any) => {
    const { index, openModalCount } = this.props;

    if (typeof index !== 'number' || index + 1 < openModalCount) {
      // A keyboard event was captured by multiple modals but only the top-most should be closed
      return;
    }

    this.setState({
      closingData: data,
      isClosing: true,
    });
  };

  cancel = () => {
    this.close();
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  enableClosing = () => {
    this.setState({ isClosingEnabled: true });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  disableClosing = () => {
    this.setState({ isClosingEnabled: false });
  };

  handleCloseButtonOnClick: () => void = this.cancel;

  handleDialogOnMouseDown = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  handleBackButtonOnClick = () => {
    const { backCallback } = this.props;

    if (backCallback) {
      backCallback();
    }
  };

  handleContentOnAnimationEnd = () => {
    const { localHideModal } = this.props;
    const { closingData, isClosing } = this.state;

    if (isClosing) {
      localHideModal();

      Messenger.emit(MessengerEvents.MODAL_CONFIRMATION_CLOSED, closingData);
    }
  };

  // eslint-disable-next-line consistent-return
  renderPicto = (icon: ModalIcon): React.Node => {
    switch (icon) {
      case ModalIcon.None:
        return null;
      case ModalIcon.Cart:
        return <PictoCart hasHoverEffect={false} />;
      case ModalIcon.Info:
        return <PictoInfo hasHoverEffect={false} />;
      case ModalIcon.Record:
        return <PictoRecord className='recording' hasHoverEffect={false} />;
      case ModalIcon.User:
        return <PictoUser hasHoverEffect={false} />;

      // No default
    }
  };

  renderIcon = (): React.Node => {
    const { icon = ModalIcon.None } = this.props;
    const { isClosing } = this.state;

    if (icon === ModalIcon.None) {
      return null;
    }

    const pictoElt = this.renderPicto(icon);

    return (
      <div className={clsx('iconContainer', isClosing ? 'slideUp' : 'slideDown')}>
        <svg viewBox='0 0 200 200'>
          <path d='M67.21,166.48A86.48,86.48,0,1,1,108,172.55c-18.19,18.75-51.8,34.74-86.16,24Q55.33,196.53,67.21,166.48Z' />
        </svg>
        {pictoElt}
      </div>
    );
  };

  render(): React.Node {
    const { backCallback, canCloseOnOverlayClick, children, className, hasSeparator, header, icon, isCloseButtonDisplayed, index = 0, theme, title } = this.props;
    const { isClosing, isClosingEnabled } = this.state;

    const handleOverlayOnMouseDown = canCloseOnOverlayClick && isClosingEnabled ? this.handleOverlayOnMouseDown : undefined;
    const handleOverlayOnMouseUp = canCloseOnOverlayClick && isClosingEnabled ? this.handleOverlayOnMouseUp : undefined;

    const closeButton = isCloseButtonDisplayed ? <PictoX onClick={this.handleCloseButtonOnClick} /> : null;
    const backButton = backCallback ? <PictoArrowLeft onClick={this.handleBackButtonOnClick} /> : null;

    const titleElement = (
      <div className={clsx('title', backCallback && 'clickable')} onClick={backCallback ? this.handleBackButtonOnClick : undefined}>
        {title}
      </div>
    );

    const shift = index * Z_INDEX.Shift;
    const overlayStyle = { zIndex: Z_INDEX.OverlayBase + shift };
    const contentStyle = { zIndex: Z_INDEX.ContentBase + shift };

    const iconElement = this.renderIcon();

    const headerElement = header !== null ? <div className={clsx('header', hasSeparator && 'separator')}>{header}</div> : null;

    return (
      <div>
        <div className='modalOverlayDiv' style={overlayStyle} />
        <div className={clsx('modalContentDiv', icon !== ModalIcon.None && 'hasIcon')} onMouseDown={handleOverlayOnMouseDown} onMouseUp={handleOverlayOnMouseUp} style={contentStyle}>
          <div className={clsx('modalDialogDiv', className, theme)} onMouseDown={this.handleDialogOnMouseDown}>
            <div className={clsx('content', isClosing ? 'slideUp' : 'slideDown')} onAnimationEnd={this.handleContentOnAnimationEnd}>
              <div className={clsx('modalHeader', theme, icon === ModalIcon.None && 'shadow')}>
                {backButton}
                {titleElement}
                {closeButton}
              </div>
              {headerElement}
              {children}
            </div>
            {iconElement}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers) => {
  return {
    openModalCount: state.modal.openModals.length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxModalDispatchToPropsType => {
  return {
    localHideModal: () => dispatch(hideModal(false, true)),
  };
};

const Modal: React.ComponentType<ModalPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalView);

export default Modal;
