/* @flow */

import type { KeyValuePair } from '@ntg/utils/dist/types';

// Copies of Shaka types since they're not Flow-typed

export type ShakaBaseTrack = {|
  bandwidth: number,
  id: number,
  label: string | null,
  language: string,
  mimeType: string | null,
  roles: Array<string>,
|};

type ShakaAudioTrack = {|
  audioBandwidth: number | null,
  audioCodec: string | null,
  audioId: number | null,
  audioMimeType: string | null,
  originalAudioId: string | null,
|};

type ShakaTextTrack = {|
  forced: boolean,
  kind: string | null,
|};

type ShakaVideoTrack = {|
  frameRate: number | null,
  height: number | null,
  originalVideoId: string | null,
  videoBandwidth: number | null,
  videoCodec: string | null,
  videoId: number | null,
  videoMimeType: string | null,
  width: number | null,
|};

export type AudioTrack = {|
  ...ShakaBaseTrack,
  ...ShakaAudioTrack,
|};

export type TextTrack = {|
  ...ShakaBaseTrack,
  ...ShakaTextTrack,
|};

export type VideoTrack = {|
  ...ShakaBaseTrack,
  ...ShakaVideoTrack,
|};

export type ShakaTrack = {|
  ...ShakaBaseTrack,
  ...AudioTrack,
  ...TextTrack,
  ...VideoTrack,
  active: boolean,
  type: 'variant' | 'text' | 'image',
|};

export type ShakaTrackList = Array<ShakaTrack>;

export type IndexedShakaTrack = {|
  index: number,
  track: ShakaTrack,
|};

export type ShakaLanguageRole = {|
  label: string | null,
  language: string,
  role: string,
|};

type ShakaStateChange = {|
  duration: number,
  state: string,
  timestamp: number,
|};

export type ShakaOfflineTrackSelection = (tracks: Array<ShakaTrack>) => Array<ShakaTrack>;

export type ShakaOfflineProgressUpdate = (content: any, progress: number, message?: string) => void;

export type ShakaOfflineContent = {|
  appMetadata: {|
    downloaded: Date,
    title: string,
  |},
  duration: number,
  expiration: number,
  isIncomplete: boolean,
  offlineUri: string,
  originalManifestUri: string,
  size: number,
  tracks: ShakaTrackList,
|};

type ShakaTrackChoice = {|
  bandwidth: number | null,
  fromAdaptation: boolean,
  id: number,
  timestamp: number,
  type: string,
|};

export type ShakaBufferedRange = {|
  end: number,
  start: number,
|};

type ShakaBufferedInfo = {|
  audio: Array<ShakaBufferedRange>,
  text: Array<ShakaBufferedRange>,
  total: Array<ShakaBufferedRange>,
  video: Array<ShakaBufferedRange>,
|};

export type ShakaStats = {|
  bufferingTime: number,
  completionPercent: number,
  corruptedFrames: number,
  decodedFrames: number,
  drmTimeSeconds: number,
  droppedFrames: number,
  estimatedBandwidth: number,
  gapsJumped: number,
  height: number,
  licenseTime: number,
  liveLatency: number,
  loadLatency: number,
  manifestTimeSeconds: number,
  maxSegmentDuration: number,
  pauseTime: number,
  playTime: number,
  stallsDetected: number,
  stateHistory: Array<ShakaStateChange>,
  streamBandwidth: number,
  switchHistory: Array<ShakaTrackChoice>,
  width: number,
|};

export type ShakaInitData = {|
  initData: Uint8Array,
  initDataType: string,
  keyId: string | null,
|};

type ShakaDrmInfo = {|
  audioRobustness: string,
  distinctiveIdentifierRequired: boolean,
  initData: Array<ShakaInitData>,
  hasVUDrmToken: boolean,
  keyIds: Set<string>,
  keySystem: string,
  licenseServerUri: string,
  persistentStateRequired: boolean,
  serverCertificate: Uint8Array,
  serverCertificateUri: string,
  sessionType: string,
  videoRobustness: string,
|};

export type TrackIds = {|
  audio: number | null,
  text: number | null,
  video: number | null,
|};

export type AllTracks = {|
  audio: Array<AudioTrack>,
  text: Array<TextTrack>,
  video: Array<VideoTrack>,
|};

export type ShakaMetrics = {|
  bufferedInfo: ShakaBufferedInfo,
  bufferFullness: number,
  drmInfo: ShakaDrmInfo,
  selectedTrackIds: TrackIds,
  stats: ShakaStats,
  tracks: AllTracks,
|};

export type ShakaPlayerSettings = {|
  bufferBehind: number,
  bufferingGoal: number,
  rebufferingGoal: number,
|};

export type ShakaConfiguration = {|
  drm?: {|
    advanced: KeyValuePair<any>,
    initDataTransform: (Uint8Array, string, null) => Uint8Array,
    servers: KeyValuePair<string>,
  |},
  manifest?: {|
    dash?: { ignoreMinBufferTime?: boolean },
    defaultPresentationDelay?: number,
  |},
  preferredAudioLanguage?: string,
  preferredTextLanguage?: string,
  restrictions?: {| maxBandwidth: number |},
  streaming: {
    ...ShakaPlayerSettings,
    autoLowLatencyMode: boolean,
    ignoreTextStreamFailures: boolean,
    retryParameters?: {| maxAttempts: number |},
  },
|};

export type ShakaError = {|
  detail: {|
    category: number,
    code: number,
    data: Array<string | number>,
    severity: number,
  |},
|};

/* eslint-disable no-magic-numbers */
export const getShakaErrorCategoryAsText = (category: number): string => {
  switch (category) {
    case 1:
      return 'Network';
    case 2:
      return 'Text';
    case 3:
      return 'Media';
    case 4:
      return 'Manifest';
    case 5:
      return 'Streaming';
    case 6:
      return 'DRM';
    case 7:
      return 'Player';
    case 8:
      return 'Cast';
    case 9:
      return 'Storage';
    case 10:
      return 'Ads';
    default:
      return `Unknown category (${category})`;
  }
};
/* eslint-enable no-magic-numbers */

/* eslint-disable no-magic-numbers, max-lines-per-function, complexity */
export const getShakaErrorCodeAsText = (code: number): string => {
  switch (code) {
    case 8002:
      return 'ALREADY_CASTING';
    case 1010:
      return 'ATTEMPTS_EXHAUSTED';
    case 2004:
      return 'BAD_ENCODING';
    case 1001:
      return 'BAD_HTTP_STATUS';
    case 3000:
      return 'BUFFER_READ_OUT_OF_BOUNDS';
    case 4033:
      return 'CANNOT_ADD_EXTERNAL_TEXT_TO_LIVE_STREAM';
    case 2012:
      return 'CANNOT_ADD_EXTERNAL_TEXT_TO_SRC_EQUALS';
    case 9005:
      return 'CANNOT_STORE_LIVE_OFFLINE';
    case 8000:
      return 'CAST_API_UNAVAILABLE';
    case 8004:
      return 'CAST_CANCELED_BY_USER';
    case 8005:
      return 'CAST_CONNECTION_TIMED_OUT';
    case 8006:
      return 'CAST_RECEIVER_APP_UNAVAILABLE';
    case 2015:
      return 'CHAPTERS_TRACK_FAILED';
    case 7004:
      return 'CONTENT_NOT_LOADED';
    case 3019:
      return 'CONTENT_TRANSFORMATION_FAILED';
    case 4032:
      return 'CONTENT_UNSUPPORTED_BY_BROWSER';
    case 10001:
      return 'CS_AD_MANAGER_NOT_INITIALIZED';
    case 10000:
      return 'CS_IMA_SDK_MISSING';
    case 10004:
      return 'CURRENT_DAI_REQUEST_NOT_FINISHED';
    case 4010:
      return 'DASH_CONFLICTING_KEY_IDS';
    case 4018:
      return 'DASH_DUPLICATE_REPRESENTATION_ID';
    case 4003:
      return 'DASH_EMPTY_ADAPTATION_SET';
    case 4004:
      return 'DASH_EMPTY_PERIOD';
    case 4001:
      return 'DASH_INVALID_XML';
    case 4009:
      return 'DASH_MULTIPLE_KEY_IDS_NOT_SUPPORTED';
    case 4008:
      return 'DASH_NO_COMMON_KEY_SYSTEM';
    case 4002:
      return 'DASH_NO_SEGMENT_INFO';
    case 4007:
      return 'DASH_PSSH_BAD_ENCODING';
    case 4006:
      return 'DASH_UNSUPPORTED_CONTAINER';
    case 4027:
      return 'DASH_UNSUPPORTED_XLINK_ACTUATE';
    case 4005:
      return 'DASH_WEBM_MISSING_INIT';
    case 4028:
      return 'DASH_XLINK_DEPTH_LIMIT';
    case 9002:
      return 'DEPRECATED_OPERATION_ABORTED';
    case 9015:
      return 'DOWNLOAD_SIZE_CALLBACK_ERROR';
    case 3003:
      return 'EBML_BAD_FLOATING_POINT_SIZE';
    case 3002:
      return 'EBML_OVERFLOW';
    case 6010:
      return 'ENCRYPTED_CONTENT_WITHOUT_DRM_INFO';
    case 6014:
      return 'EXPIRED';
    case 6003:
      return 'FAILED_TO_ATTACH_TO_VIDEO';
    case 6002:
      return 'FAILED_TO_CREATE_CDM';
    case 6005:
      return 'FAILED_TO_CREATE_SESSION';
    case 6006:
      return 'FAILED_TO_GENERATE_LICENSE_REQUEST';
    case 4043:
      return 'HLS_AES_128_INVALID_IV_LENGTH';
    case 4044:
      return 'HLS_AES_128_INVALID_KEY_LENGTH';
    case 4025:
      return 'HLS_COULD_NOT_GUESS_CODECS';
    case 4017:
      return 'HLS_INVALID_PLAYLIST_HIERARCHY';
    case 4026:
      return 'HLS_KEYFORMATS_NOT_SUPPORTED';
    case 4041:
      return 'HLS_MSE_ENCRYPTED_LEGACY_APPLE_MEDIA_KEYS_NOT_SUPPORTED';
    case 4040:
      return 'HLS_MSE_ENCRYPTED_MP2T_NOT_SUPPORTED';
    case 4020:
      return 'HLS_MULTIPLE_MEDIA_INIT_SECTIONS_FOUND';
    case 4015:
      return 'HLS_PLAYLIST_HEADER_MISSING';
    case 4023:
      return 'HLS_REQUIRED_ATTRIBUTE_MISSING';
    case 4024:
      return 'HLS_REQUIRED_TAG_MISSING';
    case 4039:
      return 'HLS_VARIABLE_NOT_FOUND';
    case 1002:
      return 'HTTP_ERROR';
    case 4038:
      return 'INCONSISTENT_DRM_ACROSS_PERIODS';
    case 9001:
      return 'INDEXED_DB_ERROR';
    case 6016:
      return 'INIT_DATA_TRANSFORM_ERROR';
    case 4016:
      return 'INVALID_HLS_TAG';
    case 2010:
      return 'INVALID_MP4_CEA';
    case 2007:
      return 'INVALID_MP4_TTML';
    case 2008:
      return 'INVALID_MP4_VTT';
    case 6004:
      return 'INVALID_SERVER_CERTIFICATE';
    case 2001:
      return 'INVALID_TEXT_CUE';
    case 2000:
      return 'INVALID_TEXT_HEADER';
    case 2005:
      return 'INVALID_XML';
    case 3001:
      return 'JS_INTEGER_OVERFLOW';
    case 9012:
      return 'KEY_NOT_FOUND';
    case 6007:
      return 'LICENSE_REQUEST_FAILED';
    case 6008:
      return 'LICENSE_RESPONSE_REJECTED';
    case 7000:
      return 'LOAD_INTERRUPTED';
    case 9008:
      return 'LOCAL_PLAYER_INSTANCE_REQUIRED';
    case 1004:
      return 'MALFORMED_DATA_URI';
    case 9004:
      return 'MALFORMED_OFFLINE_URI';
    case 1008:
      return 'MALFORMED_TEST_URI';
    case 3014:
      return 'MEDIA_SOURCE_OPERATION_FAILED';
    case 3015:
      return 'MEDIA_SOURCE_OPERATION_THREW';
    case 9013:
      return 'MISSING_STORAGE_CELL';
    case 2014:
      return 'MISSING_TEXT_PLUGIN';
    case 9016:
      return 'MODIFY_OPERATION_NOT_SUPPORTED';
    case 3005:
      return 'MP4_SIDX_INVALID_TIMESCALE';
    case 3006:
      return 'MP4_SIDX_TYPE_NOT_SUPPORTED';
    case 3004:
      return 'MP4_SIDX_WRONG_BOX_TYPE';
    case 9011:
      return 'NEW_KEY_OPERATION_NOT_SUPPORTED';
    case 8001:
      return 'NO_CAST_RECEIVERS';
    case 9007:
      return 'NO_INIT_DATA_FOR_OFFLINE';
    case 6012:
      return 'NO_LICENSE_SERVER_GIVEN';
    case 6000:
      return 'NO_RECOGNIZED_KEY_SYSTEMS';
    case 4036:
      return 'NO_VARIANTS';
    case 7002:
      return 'NO_VIDEO_ELEMENT';
    case 4042:
      return 'NO_WEB_CRYPTO_API';
    case 7003:
      return 'OBJECT_DESTROYED';
    case 6013:
      return 'OFFLINE_SESSION_REMOVED';
    case 7001:
      return 'OPERATION_ABORTED';
    case 4037:
      return 'PERIOD_FLATTENING_FAILED';
    case 3017:
      return 'QUOTA_EXCEEDED_ERROR';
    case 9003:
      return 'REQUESTED_ITEM_NOT_FOUND';
    case 6001:
      return 'REQUESTED_KEY_SYSTEM_CONFIG_UNAVAILABLE';
    case 1006:
      return 'REQUEST_FILTER_ERROR';
    case 1007:
      return 'RESPONSE_FILTER_ERROR';
    case 4012:
      return 'RESTRICTIONS_CANNOT_BE_MET';
    case 1011:
      return 'SEGMENT_MISSING';
    case 6017:
      return 'SERVER_CERTIFICATE_REQUEST_FAILED';
    case 6015:
      return 'SERVER_CERTIFICATE_REQUIRED';
    case 10003:
      return 'SS_AD_MANAGER_NOT_INITIALIZED';
    case 10002:
      return 'SS_IMA_SDK_MISSING';
    case 9014:
      return 'STORAGE_LIMIT_REACHED';
    case 9000:
      return 'STORAGE_NOT_SUPPORTED';
    case 5006:
      return 'STREAMING_ENGINE_STARTUP_INVALID_STATE';
    case 2011:
      return 'TEXT_COULD_NOT_GUESS_MIME_TYPE';
    case 2013:
      return 'TEXT_ONLY_WEBVTT_SRC_EQUALS';
    case 1003:
      return 'TIMEOUT';
    case 3018:
      return 'TRANSMUXING_FAILED';
    case 2003:
      return 'UNABLE_TO_DETECT_ENCODING';
    case 2009:
      return 'UNABLE_TO_EXTRACT_CUE_START_TIME';
    case 4000:
      return 'UNABLE_TO_GUESS_MANIFEST_TYPE';
    case 8003:
      return 'UNEXPECTED_CAST_ERROR';
    case 1009:
      return 'UNEXPECTED_TEST_REQUEST';
    case 1000:
      return 'UNSUPPORTED_SCHEME';
    case 3016:
      return 'VIDEO_ERROR';
    case 3007:
      return 'WEBM_CUES_ELEMENT_MISSING';
    case 3013:
      return 'WEBM_CUE_TIME_ELEMENT_MISSING';
    case 3012:
      return 'WEBM_CUE_TRACK_POSITIONS_ELEMENT_MISSING';
    case 3011:
      return 'WEBM_DURATION_ELEMENT_MISSING';
    case 3008:
      return 'WEBM_EBML_HEADER_ELEMENT_MISSING';
    case 3010:
      return 'WEBM_INFO_ELEMENT_MISSING';
    case 3009:
      return 'WEBM_SEGMENT_ELEMENT_MISSING';

    default:
      return `Unknown code (${code})`;
  }
};
/* eslint-enable no-magic-numbers, max-lines-per-function, complexity */

const shouldErrorBeIgnored = (code: string | number): boolean => ['7000', '7002'].includes(code.toString());

export { shouldErrorBeIgnored };
