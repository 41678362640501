/* @flow */

import './SortAndFilter.css';
import * as React from 'react';
import { PictoArrowDown, PictoArrowUp } from '@ntg/components/dist/pictos/Element';
import { type SortAndFilterType, SortDirection, SortKind } from './type';
import { Localizer } from '@ntg/utils/dist/localization';
import clsx from 'clsx';

const availableSorts = [SortKind.Default, SortKind.Chronological];

// eslint-disable-next-line consistent-return
const getSortName = (kind: SortKind): string => {
  switch (kind) {
    case SortKind.Default:
      return Localizer.localize('grid.sort_and_filter.kind.default');
    case SortKind.Chronological:
      return Localizer.localize('grid.sort_and_filter.kind.date');

    // No default
  }
};

type SortAndFilterPropType = {|
  +updateSortAndFilter: (sortAndFilter: SortAndFilterType) => void,
|};

const SortAndFilter = ({ updateSortAndFilter }: SortAndFilterPropType): React.Node => {
  const [sortKind, setSortKind] = React.useState(SortKind.Default);
  const [sortDirection, setSortDirection] = React.useState(SortDirection.Ascendant);
  const [filter, setFilter] = React.useState('');

  React.useEffect(() => {
    updateSortAndFilter({
      direction: sortDirection,
      filter,
      kind: sortKind,
    });
  }, [filter, sortDirection, sortKind, updateSortAndFilter]);

  const handleFilterOnChange = React.useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  }, []);

  const handleSortOnClick = React.useCallback(
    (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
      const kind = SortKind.cast(event.currentTarget.getAttribute('data-kind'));
      if (!kind) {
        // Invalid value (shouldn't occur
        return;
      }

      if (kind === sortKind && kind !== SortKind.Default) {
        // Kind already selected: revert direction
        setSortDirection(sortDirection === SortDirection.Ascendant ? SortDirection.Descendant : SortDirection.Ascendant);
      } else {
        // New sort kind
        setSortKind(kind);
      }
    },
    [sortDirection, sortKind],
  );

  const renderDirection = (kind: SortKind): React.Node => {
    if (kind !== sortKind || kind === SortKind.Default) {
      return null;
    }

    return sortDirection === SortDirection.Ascendant ? <PictoArrowUp /> : <PictoArrowDown />;
  };

  return (
    <div className='sortAndFilter'>
      <input onChange={handleFilterOnChange} placeholder={Localizer.localize('grid.sort_and_filter.placeholder')} type='text' value={filter} />
      {availableSorts.map((kind) => (
        <button className={clsx(sortKind === kind && 'selected')} data-kind={kind} key={(kind: string)} onClick={handleSortOnClick} type='button'>
          {getSortName(kind)} {renderDirection(kind)}
        </button>
      ))}
    </div>
  );
};

export default SortAndFilter;
